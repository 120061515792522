
import React from 'react'

const Intial = () => {
    return (
        <div>
            <div>
                <h1 className={`font-semibold text-white  text-lg`}>{`Wir begrüßen Dich zum sechswöchigen Programm`}<br /> {`„My Simurg Guide“ – eine Reise, um Deine`}<br />{`Stärken zu erkennen!`}</h1>
                <p className={`font-bold italic  text-base mt-4 text-white`}>{`Jeder von uns durchlebt von Zeit zu Zeit Stresssituationen, die`}<br /> {`sehr belastend sein können.`}<br />{`Diese Woche werden wir uns genauer mit Deiner Fähigkeit`}<br />{`befassen, um den Stress besser zu bewältigen!`}</p>
                <p className={`font-bold italic  text-base mt-4 text-white`}>{`Das Ziel des ersten Levels ist es, dass Du durch die Anwendung`}<br />{`von verschiedenen Skills, besser mit Stresssituationen`}<br />{`umgehen kannst.`}</p>
            </div>

        </div>
    )
}

export default Intial
