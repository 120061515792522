import React, { useEffect, useRef, useState } from 'react';
import OnlineGuide from './OnlineGuide';
import FirstForm from './FirstForm';
import Goals from './Goals';
import SimrugGuide from './SimrugGuide';
import SelectForm from './SelectForm';
import InputForm from './InputForm';
import RadioForm, { questionsDetailsOne } from './RadioForm';
import RadioFormSecond, { questionsDetailsSecond } from './RadioFormSecond';
import MultiOption from './MultiOption';
import PolicyForm from './PolicyForm';
import NeinAccept from './NeinAccept';
import JaAccept from './JaAccept';
import PresentationStart from './PresentationStart';
import PresentationEnd from './PresentationEnd';
import CharacterSelect from './CharacterSelect';
import FinalScreen from './FinalScreen';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MultiOptions } from '../../constants/data';
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../../redux/Started';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Description from './Description';

const Starting = () => {
  const [renderform, setRenderForm] = useState(0);
  const [radioIndex, setRadioIndex] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Define dynamic schema based on renderForm
  const getValidationSchema = () => {
    if (renderform === 5) {
      return Yup.object().shape({
        age: Yup.string().required('Alter ist erforderlich'),
        country: Yup.string().required('Land ist erforderlich'),
        gender: Yup.string().required('Geschlecht ist erforderlich'),
        email: Yup.string()
          .email('Ungültige E-Mail-Adresse') // Validates that the input is a valid email
          .required('E-Mail ist erforderlich')
        // phone: Yup.string().required('Telefonnummer ist erforderlich'),
      });
    } else if (renderform === 6) {
      return Yup.object().shape({
        fatherPlaceOfBirth: Yup.string(),
        motherPlaceOfBirth: Yup.string(),
        placeOfBirth: Yup.string()
      });
    } else if (renderform === 7) {
      return Yup.object(
        questionsDetailsOne.reduce((schema, question) => {
          schema[question.id] = Yup.string();
          return schema;
        }, {})
      );
    } else if (renderform === 8) {
      return Yup.object(
        questionsDetailsSecond.reduce((schema, question) => {
          schema[question.id] = Yup.string();
          return schema;
        }, {})
      );
    } else if (renderform === 9) {
      return Yup.object({
        MultiOptions: Yup.array().of(Yup.string()),
        // .min(2, 'Bitte wähle mindestens eine Option aus')
        // .test('is-array', 'MultiOptions muss ein Array sein', value =>
        //     Array.isArray(value),
        // ),
        andereText: Yup.string().test(
          'andere-condition',
          'Bitte geben Sie eine Beschreibung ein',
          function (value) {
            const { MultiOptions } = this.parent; // Access `MultiOptions` from the parent object
            if (Array.isArray(MultiOptions) && MultiOptions.includes('Other')) {
              return !!value; // Ensure value is non-empty if "Andere" is selected
            }
            return true; // Pass validation otherwise
          }
        )
      });
    } else if (renderform === 10) {
      return Yup.object().shape({
        medication: Yup.string(),

        details: Yup.string().test(
          'details-condition',
          'Bitte geben Sie Details ein, die mindestens 2 Zeichen lang sind',
          function (value) {
            const { medication } = this.parent; // Access sibling field `medication`
            if (medication === 'Yes') {
              return !!value && value.length >= 2; // Validate non-empty and length >= 2
            }
            return true; // If `medication` is not 'Yes', skip validation
          }
        ),
        agreement: Yup.boolean()
          .oneOf(
            [true],
            'Bitte akzeptieren Sie die Vereinbarung, um fortzufahren'
          )
          .required()
      });
    } else if (renderform === 11) {
      return Yup.object().shape({
        termsAndCondition: Yup.boolean()
          .oneOf(
            [true],
            'Bitte akzeptieren Sie die Allgemeinen Geschäftsbedingungen, um fortzufahren'
          )
          .required()
      });
    }
    // Add other cases as needed
    return Yup.object(); // Default empty schema
  };

  const [validationSchema, setValidationSchema] = useState(getValidationSchema);
  const isObjEmpty = (obj) => Object.keys(obj).length === 0;
  function containsJaForQuestions(values, renderform) {
    // Extract relevant keys from questionsDetailsOne
    // const combine_array =  [...questionsDetailsOne, ...questionsDetailsSecond];
    const combine_array =
      renderform === 6 ? questionsDetailsOne : questionsDetailsSecond;

    const relevantValues = combine_array.reduce((acc, question) => {
      acc[question.id] = values[question.id];
      return acc;
    }, {});

    // Check if any value in the relevant subset is 'Ja'
    return Object.values(relevantValues).includes('Ja');
  }
  useEffect(() => {
    setValidationSchema(getValidationSchema()); // Update schema when renderform changes
  }, [renderform]);
  const formik = useFormik({
    initialValues: {
      age: '',
      country: '',
      gender: '',
      email: '',
      fatherPlaceOfBirth: '',
      motherPlaceOfBirth: '',
      placeOfBirth: '',
      ...questionsDetailsOne.reduce((values, question) => {
        values[question.id] = ''; // Initialize as empty
        return values;
      }, {}),
      ...questionsDetailsSecond.reduce((values, question) => {
        values[question.id] = ''; // Initialize as empty
        return values;
      }, {}),
      MultiOptions: [], // Store selected options in an array
      andereText: '', // Additional input field for "Andere"
      medication: '', // 'Yes' or 'No'
      details: '', // User input when 'Yes' is selected
      agreement: false, // Checkbox state
      termsAndCondition: false
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      if (renderform === 13) {
        dispatch(setUserInfo(values));
        console.log('data in 13', values);
        setRenderForm(13);
      } else {
        if (renderform == 7 || renderform == 8 || renderform == 10) {
          const notPermission = containsJaForQuestions(values, renderform);
          if (values.medication === 'Yes') {
            setRadioIndex(renderform);
            setRenderForm(17);
            return;
          }
          if (!notPermission) {
            dispatch(setUserInfo(values));
            setRenderForm(renderform + 1);
          } else {
            setRadioIndex(renderform);
            setRenderForm(17);
          }
        } else {
          formik.setTouched({});
          setRenderForm(renderform + 1);
        }
        // formik.setTouched({});
        // setRenderForm(renderform + 1);
      }
      // if (isObjEmpty(formik.errors)) {

      //     dispatch(setUserInfo(values));
      //     alert(`data is here`)
      // } else {
      //     // if (scrollViewRef.current) {
      //     //     scrollViewRef.current.scrollTo({ y: 0, animated: true });
      //     // }
      //     formik.setTouched({});
      //     setRenderForm(renderform + 1);
      // }
    }
  });
  const onNextClick = () => {
    formik.handleSubmit();
  };
  const onBackClick = () => {
    if (renderform === 0) {
      navigate('/');
    } else {
      setRenderForm(renderform - 1);
    }
  };
  // console.log('formik Errors:', formik.errors);
  console.log('formik Values:', formik.values);

  return (
    <div>
      {renderform == 0 && (
        <OnlineGuide nextClick={onNextClick} backClick={onBackClick} />
      )}
      {renderform == 1 && (
        <Description nextClick={onNextClick} />
      )}
      {renderform == 2 && (
        <FirstForm nextClick={onNextClick} backClick={onBackClick} />
      )}
      {renderform == 3 && (
        <Goals nextClick={onNextClick} backClick={onBackClick} />
      )}
      {renderform == 4 && (
        <SimrugGuide nextClick={onNextClick} backClick={onBackClick} />
      )}
      {renderform == 5 && (
        <SelectForm
          nextClick={onNextClick}
          backClick={onBackClick}
          formik={formik}
        />
      )}
      {renderform == 6 && (
        <InputForm
          nextClick={onNextClick}
          backClick={onBackClick}
          formik={formik}
        />
      )}
      {renderform == 7 && (
        <RadioForm
          nextClick={onNextClick}
          backClick={onBackClick}
          formik={formik}
        />
      )}
      {renderform == 8 && (
        <RadioFormSecond
          nextClick={onNextClick}
          backClick={onBackClick}
          formik={formik}
        />
      )}
      {renderform == 9 && (
        <MultiOption
          nextClick={onNextClick}
          backClick={onBackClick}
          formik={formik}
        />
      )}
      {renderform == 10 && (
        <PolicyForm
          nextClick={onNextClick}
          backClick={onBackClick}
          formik={formik}
        />
      )}
      {renderform == 17 && (
        <NeinAccept setRenderForm={setRenderForm} index={radioIndex} />
      )}
      {renderform == 11 && (
        <JaAccept
          nextClick={onNextClick}
          backClick={onBackClick}
          formik={formik}
        />
      )}
      {renderform == 12 && (
        <PresentationStart nextClick={onNextClick} backClick={onBackClick} />
      )}
      {renderform == 13 && (
        <PresentationEnd nextClick={onNextClick} backClick={onBackClick} />
      )}
      {renderform == 14 && <CharacterSelect setRenderForm={setRenderForm} />}
      {renderform == 15 && <FinalScreen setRenderForm={setRenderForm} />}
    </div>
  );
};

export default Starting;
