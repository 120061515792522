import React, { useEffect, useRef, useState } from 'react';
import ellipseBG from '../../assets/EllipseBG.png'
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { getValidationSchema, List } from './constant';
import { useSelector, useDispatch } from 'react-redux';
import ScalarForm, { sliderConfig } from '../common/forms/ScalarForm';
import LevelRadioSelectionlastSeven from '../common/forms/LevelRadioSelectionlastSeven';
import { firstSevenQuestions, lastSevenQuestions } from '../common/constant/data';
import LevelRadioSelection from '../common/forms/LevelRadioSelection';
import Intial from './forms/initial';
import techVideo from '../../assets/tech.mov'
import { LevelTwoRed, StrengthData } from '../../constants/data';
import { setUserLevelTwoInfo } from '../../redux/Started'
import StressForm from '../common/forms/StressForm';
import StrengthForm from '../common/forms/StrengthForm';
import { handleAddedPersonalInfoAction } from '../../redux/Started/apisActions';
import Spiner from '../common/constant/spiner';
import IchForm from './forms/ichForm';

const LevelTwo = () => {
    const isSmallScreen = window.innerHeight <= 600;
    const navigate = useNavigate();
    const [renderForm, setRenderForm] = useState(0);
    const dispatch = useDispatch()
    const [validationSchema, setValidationSchema] = useState(
        getValidationSchema(renderForm),
    );

    useEffect(() => {
        setValidationSchema(getValidationSchema(renderForm - 1)); // Update schema when renderForm changes
    }, [renderForm]);

    const { levelOneForm, loading } = useSelector(state => state.initialUserInfo);
    const { userID } = useSelector(state => state.user);
    const formik = useFormik({
        initialValues: {
            ...sliderConfig.reduce(
                (values, slider) => ({
                    ...values,
                    [slider.name]: 0,
                }),
                {},
            ),
            ...firstSevenQuestions.reduce(
                (values, question) => ({
                    ...values,
                    [question.keyName]: '', // Default value for each question
                }),
                {},
            ),
            ...lastSevenQuestions.reduce(
                (values, question) => ({
                    ...values,
                    [question.keyName]: '', // Default value for each question
                }),
                {},
            ),
            sociogram: ["", "", "", "", "", "", ""],
            sentences: [],
            sourcesOfStrength: [], // Array of selected options
            otherSourcesOfStrength: '',
        },
        validationSchema,
        onSubmit: values => {
            const data = {
                userId: userID || '',
                LevelTwo: JSON.stringify(values),
            };
            if (renderForm === 8) {
                dispatch(setUserLevelTwoInfo(values));
                dispatch(
                    handleAddedPersonalInfoAction({
                        data,
                        callback: res => navigate('/level-three'),
                    }),
                );

            } else {
                formik.setTouched({});
                setRenderForm(renderForm + 1);

            }
            console.log('Form Values:', values);
        },
    });


    console.log("content in render form", renderForm)

    return (
        <>
            <div className='self-center pt-5 text-center '>
                {renderForm === 0 && (<>
                    <h1 className='text-[16px] text-mainBlue font-bold italic' >Level 2</h1>
                </>)}
                {renderForm === 5 && (<>
                    <h1 className='text-[16px] text-mainBlue font-bold italic' >Soziogramm</h1>
                </>)}
                {renderForm === 7 && (<>
                    <h1 className='text-[16px] text-mainBlue font-bold italic' >Grübelzeit</h1>
                </>)}
                {renderForm === 8 && (<>
                    <h1 className='text-[16px] text-mainBlue font-bold italic' >Meine Kraftquellen</h1>
                </>)}
                {
                    renderForm === 0 && (
                        <>
                            <h1 className='text-[16px] text-mainBlue font-bold italic ' >
                                Umgang mit negativen Emotionen und Stress </h1>
                            <h1 className='text-[16px] text-mainBlue font-semibold' >Willkommen im Level 2!</h1>
                        </>
                    )
                }

            </div>
            <div style={{ background: `url(${ellipseBG})` }} className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'>
                <div className={`relative pt-20 w-full max-w-[36rem] flex flex-col justify-between h-full justify-self-center text-center py-2`}>
                    {renderForm === 0 && <Intial />}
                    {renderForm === 1 && <ScalarForm formik={formik} />}
                    <div className="max-w-[35rem] mx-auto">

                        {renderForm === 2 && <LevelRadioSelection formik={formik} />}
                        {renderForm === 3 && <LevelRadioSelectionlastSeven formik={formik} />}
                    </div>
                    {renderForm === 4 && (
                        <div className="flex flex-col gap-5 justify-start items-center">
                            <p className={`text-xl font-bold text-white`}>
                                {`Im Level 1 hast Du bereits einige Skills kennengelernt, hier noch weitere, die Du ausprobieren kannst: wenn Du magst, kannst Du`}
                            </p>
                            {List?.map((item, index) => {
                                return (
                                    <a href={item?.url} target='_blank' className={` font-bold text-white italic underline mb-1  text-center`}>{item?.title}</a>
                                )
                            })}


                        </div>
                    )}

                    {renderForm === 5 && (
                        <IchForm formik={formik} isSmallScreen={isSmallScreen} />
                    )}

                    {renderForm === 6 && (
                        <div className="flex flex-col gap-1 justify-start items-center">
                            <StressForm formik={formik} />
                        </div>
                    )}

                    {renderForm === 7 && (
                        <div className="flex flex-col gap-0 justify-start items-center">
                            <p className={`${isSmallScreen ? 'text-xs' : 'text-sm'} font-bold italic text-white`}>{`Anhaltende „Grübelschleifen“ können sehr anstrengend sein. Die Gedanken können sich immer wieder um vermeintliche Schwächen, oder Misserfolge etc. drehen.`}</p>
                            <p className={`${isSmallScreen ? 'text-xs' : 'text-sm'} font-bold italic text-white ${isSmallScreen ? 'mt-3' : 'mt-5'}`}>{`Wie kann man „Grübeln“ unter Kontrolle bekommen?`}</p>
                            <div className='mt-4'>
                                <video
                                    className={`w-full ${isSmallScreen ? 'h-[100px]' : 'h-auto'} max-w-[300px] mx-auto`}

                                    controls
                                >
                                    <source src={techVideo} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    )}

                    {renderForm === 8 && (
                        <StrengthForm formik={formik} />
                    )}


                    <div className={`${isSmallScreen ? 'pt-2' : 'pt-4'} flex gap-2`}>
                        <button className='w-full button_primary' onClick={() => {
                            if (renderForm === 0) {
                                navigate('/level-one');
                            } else {
                                setRenderForm(renderForm - 1);
                            }
                            // navigation.goBack();
                        }} >Zurück</button>
                        <button className='w-full button_secodry relative' onClick={() => {
                            formik.handleSubmit();
                        }}
                            disabled={loading}
                        >

                            {loading && (
                                <Spiner />
                            )}
                            <span className={loading ? "opacity-0" : ""}>Weiter gehts</span>

                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LevelTwo
