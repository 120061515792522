export const appLottie = {
  update: require('./lottie/update.json'),
  loading: require('./lottie/loading-spinner.json'),
  dataNotFound: require('./lottie/no-data-found.json'),
  noInternet: require('./lottie/no-internet-connection.json'),
};

export const appImages = {
  Logo: require('./images/logoIcon.png'),
  noUser: require('./images/no-user.jpg'),
  welcome: require('./images/welcome.jpg'),
  welcomeBG: require('./images/simurg.png'),
  otp_image: require('./images/OTP_image.png'),
  sandwiches: require('./images/sandwiches.jpg'),
  password_updated: require('./images/password_updated.png'),
  mobileGif: require('./images/mobile.gif'),
  wallpaper: require('./images/wallpaper.png'),
  wallpaperDark: require('./images/rounded.png'),
  video: require('./images/Rectangle.png'),
  upperShadow: require('./images/upperShadow.png'),
  wallpaperSmallDark: require('./images/wallpaperSmall.png'),
  selected: require('./images/avatarSelected.png'),
  ballons: require('./images/ballons.png'),
  scalarValue: require('./images/scalarValue.png'),
  rectangleRight: require('./images/rectangleRight.png'),
  trophy: require('./images/trophy.png'),
  battery: require('./images/battery.png'),
  batteryPercentage: require('./images/batterypercentage.png'),
  tree: require('./images/tree.png'),
  treeTypes: require('./images/treeTypes.png'),
  exeOne: require('./images/exeOne.png'),
  exeTwo: require('./images/exeTwo.png'),
  exeThree: require('./images/exeThree.png'),
  exeFour: require('./images/exeFour.png'),
  exeFive: require('./images/exeFive.png'),
  relax: require('./images/relax.png'),
  reduceOne: require('./images/reduceOne.png'),
  reduceTwo: require('./images/reduceTwo.png'),
  reduceThree: require('./images/reduceThree.png'),
  reduceFour: require('./images/reduceFour.png'),
  reduceFive: require('./images/reduceFive.png'),
  glass: require('./images/glass.png'),

  ballonPink: require('./images/ballonPink.png'),
  ballonRed: require('./images/ballonRed.png'),
  ballonSky: require('./images/ballonSky.png'),
  ballonYellow: require('./images/ballonYellow.png'),
  ballonGreen: require('./images/ballonGreen.png'),
  ballonOrange: require('./images/ballonOrange.png'),
  partyPaper: require('./images/partyPapers.png'),
  arms: require('./images/arms.jpg'),
  depressed: require('./images/depressed.jpg'),
  emotional: require('./images/emotional.jpg'),
  happy: require('./images/happy.jpg'),
  scared: require('./images/scared.jpg'),
  sick: require('./images/sick.jpg'),
};

export const appIcons = {
  lock: require('./icons/lock.png'),
  mail: require('./icons/sms.png'),
  user: require('./icons/user.png'),
  search: require('./icons/search.png'),
  creditCard: require('./icons/credit-card.png'),
};
