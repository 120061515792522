import * as Yup from 'yup';
import { sliderConfig } from '../../common/forms/ScalarForm';
import { firstSevenQuestions } from '../../common/constant/data';
import { lastSevenQuestions } from '../../common/constant/data';
export const getValidationSchema = renderForm => {
    if (renderForm === 0) {
        return Yup.object(
            sliderConfig.reduce((schema, slider) => {
                schema[slider.name] = Yup.number()
                    .required('Dieses Feld ist erforderlich.')
                    .min(slider.minValue, `Der Mindestwert beträgt ${slider.minValue}.`)
                    .max(slider.maxValue, `Der Höchstwert beträgt ${slider.maxValue}.`);
                return schema;
            }, {}),
        );
    } else if (renderForm === 1) {
        return Yup.object(
            firstSevenQuestions.reduce((schema, question) => {
                schema[question.keyName] = Yup.string().required(
                    'Bitte wähle eine Option.',
                );
                return schema;
            }, {}),
        );
    } else if (renderForm === 2) {
        return Yup.object(
            lastSevenQuestions.reduce((schema, question) => {
                schema[question.keyName] = Yup.string().required(
                    'Bitte wähle eine Option.',
                );
                return schema;
            }, {}),
        );
    }
    // Add other cases as needed
    return Yup.object(); // Default empty schema
};
