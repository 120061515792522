import React from 'react';
import { lastSevenQuestions } from '../../common/constant/data';
import * as Yup from 'yup';
import mainBG from '../../../assets/EinführungMainBG.png';
import ellipseBG from '../../../assets/EllipseBG.png';
import { useFormik } from 'formik';
import LevelRadioSelectionlastSeven from '../../common/forms/LevelRadioSelectionlastSeven';

const RadioSelectionLast = ({ setRenderForm }) => {
  const isSmallScreen = window.innerHeight <= 600;

  const validationSchema = Yup.object(
    lastSevenQuestions.reduce((schema, question) => {
      schema[question.keyName] = Yup.string().required('Bitte wähle eine Option aus.');
      return schema;
    }, {})
  );

  const formik = useFormik({
    initialValues: lastSevenQuestions.reduce(
      (values, question) => ({
        ...values,
        [question.keyName]: '',
      }),
      {}
    ),
    validationSchema,
    onSubmit: (values) => {
      console.log('Form Submitted:', values);
      setRenderForm(4);
    },
  });

  return (
    <div
      style={{ background: `url(${mainBG})` }}
      className="h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between"
    >
      <div></div>
      <div
        style={{ background: `url(${ellipseBG})` }}
        className="min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative"
      >
        <div className="relative h-full mx-auto w-full max-w-[40%] text-center pb-4">
          <form className='flex flex-col justify-between h-full pt-28 items-center' onSubmit={formik.handleSubmit}>
            <LevelRadioSelectionlastSeven formik={formik} />
            <div className={`${isSmallScreen ? 'pt-2' : 'pt-4'} flex gap-6 w-full`}>
              <button
                type="button"
                className="w-full button_primary"
                onClick={() => setRenderForm(2)}
              >
                Zurück
              </button>
              <button type="submit" className="w-full button_secodry">
                Weiter gehts
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RadioSelectionLast;