import React, { useEffect, useRef, useState } from 'react';
import ellipseBG from '../../assets/EllipseBG.png';
import BatteryPer from '../../assets/batterypercentage.png';
import Battery from '../../assets/battery.png';
import Tree from '../../assets/tree.png';
import TreeTypes from '../../assets/treeTypes.png';
import Intial from './forms/intial';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { getValidationSchema } from '../LevelThree/constant';
import { useSelector, useDispatch } from 'react-redux';
import ScalarForm, { sliderConfig } from '../common/forms/ScalarForm';
import LevelRadioSelectionlastSeven from '../common/forms/LevelRadioSelectionlastSeven';
import {
  firstSevenQuestions,
  lastSevenQuestions
} from '../common/constant/data';
import LevelRadioSelection from '../common/forms/LevelRadioSelection';
import techVideo from '../../assets/tech.mov';
import { handleAddedPersonalInfoAction } from '../../redux/Started/apisActions';
import { appImages } from '../../assets';
import {
  exercise_list,
  exercise_reduce_list,
  exercise_save_list
} from './constant';
import Spiner from '../common/constant/spiner';
// import MoodSelector from './forms/MoodSelector';
// import EmojiDragDrop from './forms/EmojiDragDrop';

const LevelSix = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [renderForm, setRenderForm] = useState(0);
  const [validationSchema, setValidationSchema] = useState(
    getValidationSchema(renderForm)
  );

  useEffect(() => {
    setValidationSchema(getValidationSchema(renderForm - 1)); // Update schema when renderForm changes
  }, [renderForm]);
  const { levelOneForm, loading } = useSelector(
    (state) => state.initialUserInfo
  );
  const { userID } = useSelector((state) => state.user);
  console.log({ userID });
  const formik = useFormik({
    initialValues: {
      ...sliderConfig.reduce(
        (values, slider) => ({
          ...values,
          [slider.name]: 0
        }),
        {}
      ),
      ...firstSevenQuestions.reduce(
        (values, question) => ({
          ...values,
          [question.keyName]: '' // Default value for each question
        }),
        {}
      ),
      ...lastSevenQuestions.reduce(
        (values, question) => ({
          ...values,
          [question.keyName]: '' // Default value for each question
        }),
        {}
      ),
      distractionMethods: Array.from({ length: 8 }, () => ({
        person: '',
        text: ''
      })),
      stressfulSituations: '',
      pickleJar: 'bx,mnkjbcmn njc'
    },
    validationSchema,
    onSubmit: (values) => {
      const data = {
        userId: userID || '',
        LevelSix: JSON.stringify(values)
      };
      if (renderForm === 12) {
        dispatch(
          handleAddedPersonalInfoAction({
            data,
            popup: true,
            callback: (res) => setIsVisible(true)
          })
        );
      } else {
        formik.setTouched({});
        setRenderForm(renderForm + 1);
      }
      console.log('Form Values:', values);
    }
  });

  console.log('content in render form', renderForm);

  return (
    <>
      <div className='self-center pt-4 text-center '>
        {renderForm === 2 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic'>
              Befindlichkeits-Einschätzung
            </h1>
            <h1 className='text-[14px] text-mainBlue font-bold italic '>
              Wie ist es Dir in den letzten 7 Tagen gegangen?
            </h1>
            <h1 className='text-[14px] text-mainBlue font-semibold'>
              {' '}
              Bitte kreuze in jedem Kästchen an, was für Dich zutrifft - achte
              dabei, dass Du alle Zeilen genau durchliest.
            </h1>
          </>
        )}
        {renderForm === 0 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic'>
              Level 6
            </h1>
            <h1 className='text-[16px] text-mainBlue font-bold italic '>
              Zusammenfassung und Umgang mit zukünftigen <br />{' '}
              Stresssituationen{' '}
            </h1>
            <h1 className='text-[16px] text-mainBlue font-semibold'>
              Willkommen im Level 6!
            </h1>
          </>
        )}
        {renderForm === 4 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic'>
              {' '}
              Bauchatmung Zusammenfassung von wichtigen <br /> Methoden zur
              Entspannung{' '}
            </h1>
          </>
        )}
        {renderForm === 5 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic'>
              {' '}
              Reframing (Umdeutung){' '}
            </h1>
          </>
        )}
        {renderForm === 6 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic'>
              {' '}
              Methoden zur Ablenkung{' '}
            </h1>
          </>
        )}
        {renderForm === 7 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic'>
              {' '}
              Methoden zur Ablenkung{' '}
            </h1>
          </>
        )}
        {renderForm === 8 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic'>
              {' '}
              Sorgen ausatmen!{' '}
            </h1>
          </>
        )}
        {renderForm === 9 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic'>
              {' '}
              Übungen, um Spannungen abzubauen{' '}
            </h1>
          </>
        )}
        {renderForm === 10 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic'>
              {' '}
              „Save der Gefühle“{' '}
            </h1>
          </>
        )}
        {renderForm === 11 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic'>
              {' '}
              Umgang mit Stresssituationen in der Zukunft{' '}
            </h1>
          </>
        )}
        {renderForm === 12 && (
          <>
            <h1 className='text-[16px] text-mainBlue font-bold italic'>
              {' '}
              ENDE DES LEVELS 6! <br />
              ENDE DES PROGRAMMS!
            </h1>
          </>
        )}
      </div>
      <div
        style={{ background: `url(${ellipseBG})` }}
        className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'
      >
        <div className='relative pt-20 w-full text-white max-w-[36rem] flex flex-col justify-between h-full justify-self-center text-center py-2'>
          {/* <Intial /> */}
          <div className='max-w-[32rem] mx-auto'>
            {renderForm === 0 && <Intial />}
            {renderForm === 1 && <ScalarForm formik={formik} />}
          </div>

          {renderForm === 2 && <LevelRadioSelection formik={formik} />}

          {renderForm === 3 && <LevelRadioSelectionlastSeven formik={formik} />}

          {renderForm === 4 && (
            <div className='flex flex-col gap-5 justify-start items-start'>
              <p className='text-[16px] font-bold italic'>
                Wir wollen jetzt einen Blick auf das werfen, was Du in den
                bisherigen Leveln gelernt hast, und Dich in diesem letzten Level
                auf mögliche Stresssituationen in der Zukunft vorbereiten.
              </p>
              <div className='mt-2 max-w-[270px] mx-auto'>
                <video className='w-full h-auto ' controls>
                  <source src={techVideo} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          )}

          {renderForm === 5 && (
            <div className='flex flex-col gap-5 justify-start items-start'>
              <p className='text-[16px] font-bold italic'>
                Reframing heißt, Situationen aus einer anderen Sicht zu sehen
                und dadurch auch anders (in einem positiven Sinn) zu bewerten.
                Es darf auch kreativ und humorvoll sein.
              </p>
              <div className='mt-2 max-w-[270px] mx-auto'>
                <video className='w-full h-auto ' controls>
                  <source src={techVideo} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          )}

          {renderForm === 6 && (
            <div className='flex flex-col gap-2 justify-center items-center'>
              <p className='text-[14px] font-bold italic'>
                “Buchstabiere bitte deinen Namen und schreibe jeweils eine
                positive Eigenschaft mit jedem Buchstaben deines Namens”
              </p>
              <div className='grid md:grid-cols-1 grid-cols-1 gap-2'>
                {formik?.values?.distractionMethods?.map((method, index) => (
                  <div key={index} className='flex gap-4 px-6'>
                    <input
                      type='text'
                      className='w-1/3 p-2 border bg-[#D9D9D9] text-black'
                      value={formik.values.distractionMethods[index].person}
                      onChange={(e) =>
                        formik.setFieldValue(
                          `distractionMethods[${index}].person`,
                          e.target.value
                        )
                      }
                      onBlur={() =>
                        formik.setFieldTouched(
                          `distractionMethods[${index}].person`,
                          true
                        )
                      }
                    />
                    <input
                      type='text'
                      className='w-2/3 p-2 border bg-[#D9D9D9] text-black'
                      value={formik.values.distractionMethods[index].text}
                      onChange={(e) =>
                        formik.setFieldValue(
                          `distractionMethods[${index}].text`,
                          e.target.value
                        )
                      }
                      onBlur={() =>
                        formik.setFieldTouched(
                          `distractionMethods[${index}].text`,
                          true
                        )
                      }
                    />
                  </div>
                ))}
              </div>
              {/* <p className="text-[14px] font-bold italic">· Ich bin sprachlich kreativ</p> */}

              {/* <p className="text-[14px] font-bold italic">· Ich kenne meine Fähigkeiten</p>
                            <p className="text-[14px] font-bold italic">
                                Schreibe in die Kästchen den Namen einer Person, die Du sehr magst. Schreibe dann neben jedem Buchstaben eine Fähigkeit oder eine positive Eigenschaft von Dir selbst!
                            </p> */}
            </div>
          )}

          {renderForm === 7 && (
            <div className='flex flex-col gap-5 justify-start items-center '>
              <p className='text-[11px] font-bold italic'>
                · Mein Körper drückt meine Gefühle aus
              </p>
              <p className='text-[11px] font-bold italic'>
                Mache die unten aufgelisteten Übungen, um Deinen Stress zu
                reduzieren, die Anspannung abzubauen bzw. Dich zu lockern!
                Wiederhole alle Übung mindestens 5 Mal!
              </p>
              <div className='grid md:grid-cols-2 grid-cols-1 gap-2'>
                {exercise_list.map((item, index) => (
                  <div key={index} className='w-full'>
                    <div className={`flex items-center flex-row gap-2`}>
                      <p className='text-[11px] italic font-medium text-left w-3/5'>
                        {item.title}
                      </p>
                      <img
                        src={item.img}
                        alt={item.title}
                        className='w-32 h-24 object-contain'
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {renderForm === 8 && (
            <div className='flex flex-col gap-5'>
              <p className='text-[14px] font-bold italic'>
                Setze Dich bitte auf einen bequemen Stuhl. Dabei sollen Deine
                Füße fest den Boden berühren. Deine Arme liegen entspannt auf
                Deinem Schoss. Versuche nun auch Deine Schultern zu entspannen.
              </p>
              <div className='flex gap-5 items-center'>
                <p className='text-[13px] font-bold italic w-3/5 text-left'>
                  Atme durch die Nase <br />
                  langsam <br />
                  ein und halte Deinen <br />
                  Atem 5 Sekunden lang an, <br />
                  zähle dabei langsam bis 5. <br />
                  {`--- ->1-2-3-4-5`} <br />
                  Jetzt stelle Dir bitte vor, <br />
                  dass Du mit jedem Atemzug <br />
                  langsam aber kräftig Deine <br />
                  Sorgen aus Deinem Mund <br />
                  weit weg ausatmest.
                  <br />
                  <br />
                  <span className='text-[13px]'>
                    Diese Übung kannst Du zukünftig auch immer dann machen, wenn
                    Du Dich unruhig, angespannt, besorgt, ängstlich oder traurig
                    fühlst.
                  </span>
                </p>
                <img
                  src={appImages.relax}
                  alt='Relax'
                  className='w-32 h-80 object-contain'
                />
              </div>
            </div>
          )}

          {renderForm === 9 && (
            <div className='flex flex-col gap-5'>
              <p className='text-[14px] font-bold italic'>
                Mache die unten aufgelisteten Übungen, um Spannung abzubauen,
                wiederhole jede Übung mindestens 3 Mal!
              </p>
              <div className='grid md:grid-cols-2 grid-cols-1 gap-2'>
                {exercise_reduce_list.map((item, index) => (
                  <div key={index} className='w-full'>
                    <div className={`flex items-center justify-between  gap-2`}>
                      <a
                        target='_blank'
                        className='text-[11px] italic font-medium text-left underline cursor-pointer'
                        href={item.link}
                      >
                        {item.title}
                      </a>
                      <img
                        src={item.img}
                        alt={item.title}
                        className='w-32 h-20 object-contain'
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {renderForm === 10 && (
            <div className='flex flex-col gap-5'>
              <p className='text-[14px] font-bold italic'>
                Niemand auf der Welt schafft es, immer nur glücklich und
                zufrieden zu sein!
              </p>

              <div className='flex gap-5 items-center justify-between'>
                <ul className='w-3/5'>
                  {exercise_save_list.map((item) => (
                    <li
                      key={item.title}
                      className='text-[12px] text-left font-bold italic mt-2'
                    >
                      {item.title}
                    </li>
                  ))}
                  <li className='text-[12px] text-left font-bold italic mt-2'>
                    · Nun gib die angenehmen Gefühle in das Glas - die
                    unangenehmen Gefühle kannst Du „ganz klein zerreißen“, bevor
                    Du diese auch in das Glas gibst!
                  </li>
                </ul>
                <div className='relative'>
                  <img
                    src={appImages.glass}
                    alt='Glass'
                    className='w-44 h-56 object-contain'
                  />
                  <p className='text-[14px] text-black font-[500] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] '>
                    Mein Save <br />
                    der Gefühle
                  </p>
                </div>
              </div>
            </div>
          )}

          {renderForm === 11 && (
            <div className='flex flex-col gap-5 text-center'>
              <p className='text-[16px] font-bold italic'>
                Wie kannst Du zukünftige Stresssituationen noch besser
                bewältigen?
              </p>
              <p className='text-[15px] font-bold italic'>
                Zu Beginn dieses Levels haben wir verschiedene Methoden, die Du
                bei Stresssituationen einsetzen kannst, zusammengefasst.
              </p>
              <p className='text-[15px] font-bold italic'>
                Du wirst beim Abschluss dieses Programms die Möglichkeit haben,
                mit Deiner Beraterin zu besprechen, wie Du die eingeübten
                Methoden zur Bewältigung zukünftiger Stresssituationen einsetzen
                kannst.
              </p>
              <p className='text-[15px] font-bold italic'>
                Deine Antworten auf folgende{' '}
                <a
                  target='_blank'
                  className='underline cursor-pointer'
                  href={
                    'https://drive.google.com/file/d/1Vcn-zvE2XKbZBF_VYq7Ajzg85BBMp02_/view'
                  }
                >
                  8 Fragen
                </a>{' '}
                sind für uns wichtig, damit Du zukünftige Stresssituationen noch
                besser bewältigen kannst!
              </p>
            </div>
          )}

          {renderForm === 12 && (
            <div className='space-y-6 text-center'>
              <p className='text-[16px] font-bold italic'>
                Wir hoffen, dass Dir die erlernten Methoden auch zukünftig
                helfen, mit Stresssituationen und Problemen besser umgehen zu
                können!
              </p>
              <p className='text-[16px] font-bold italic'>
                Bitte vergiss nicht, Dir einen Folgetermin in 3 Monaten mit
                Deiner Beraterin auszumachen!
              </p>
              <p className='text-[16px] font-bold italic'>
                Wenn Du uns Feedback geben möchtest, hast Du hier Platz dafür!
                Wir freuen uns über jede Rückmeldung von Dir zu unserem
                Programm.
              </p>
              <textarea
                className='w-full p-3 border rounded-md text-black border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none'
                rows='5'
                value={formik.values.stressfulSituations}
                onChange={(e) =>
                  formik.setFieldValue('stressfulSituations', e.target.value)
                }
                onBlur={() =>
                  formik.setFieldTouched('stressfulSituations', true)
                }
                placeholder='Gib dein Feedback hier ein...'
              ></textarea>
              {formik.touched.stressfulSituations &&
                formik.errors?.stressfulSituations && (
                  <p className='text-sm text-red-500'>
                    {formik.errors.stressfulSituations}
                  </p>
                )}
            </div>
          )}

          <div className='pt-4 flex gap-2 mb-2'>
            <button
              className='w-full button_primary'
              onClick={() => {
                if (renderForm === 0) {
                  navigate('/level-four');
                } else {
                  setRenderForm(renderForm - 1);
                }
              }}
            >
              Zurück
            </button>
            <button
              className='w-full button_secodry relative'
              onClick={() => {
                formik.handleSubmit();
              }}
              disabled={loading}
            >
              {loading && <Spiner />}
              <span className={loading ? 'opacity-0' : ''}>Weiter gehts</span>
            </button>
          </div>
        </div>
      </div>

      {isVisible && (
        <div
          className='fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center z-50'
        // onClick={handleClosePopup}
        >
          <div
            className='bg-[#2C2954] p-8 rounded-lg shadow-lg max-w-md w-full text-center'
            onClick={(e) => e.stopPropagation()} // Prevent close when clicking inside the popup
          >
            <p className='text-[24px] text-white font-bold mb-4'>
              Thank you for your response
            </p>
            <button onClick={() => navigate('/')} className='button_primary'>
              Back to Home
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default LevelSix;
