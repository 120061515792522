import React from 'react'

const IchForm = ({ formik, isSmallScreen }) => {
    const positions = [
        { top: "10%", left: "10%", transform: "translateX(-50%)", index: 1 }, // Top
        { top: "60%", left: "10%", transform: "translateX(-50%)", index: 2 }, // Right
        { top: "10%", right: "10%", transform: "translateX(50%)", index: 3 }, // Bottom
        { top: "60%", right: "10%", transform: "translateX(50%)", index: 4 }, // Left
        { top: "10%", right: "50%", transform: "translateX(50%)", index: 5 }, // Left

    ];
    const handleChange = (index, value) => {
        const updatedValues = [...formik.values.sociogram];
        updatedValues[index] = value;
        formik.setFieldValue("sociogram", updatedValues);
    };

    return (
        <div className={`flex flex-col ${isSmallScreen ? 'gap-1' : 'gap-5'} justify-start items-center min-h-[60vh] overflow-y-auto`}>
            <p className={`${isSmallScreen ? 'text-xs' : 'text-sm'} font-medium italic text-white`}>{`Ein Soziogramm ist eine graphische Darstellung, die die Beziehungen zwischen Personen veranschaulicht. Dadurch kann man sich über seine Position innerhalb einer Gruppe klarer werden.`}</p>
            <p className={`${isSmallScreen ? 'text-xs' : 'text-sm'} italic font-bold text-white`}>{`Wie erstellt man ein Soziogramm?`}</p>
            <p className={`${isSmallScreen ? 'text-[10px]' : 'text-xs'} italic font-medium text-white`}>{`Um Dein eigenes Soziogramm zu zeichnen, schreibe bitte Deinen Nicknamen zum Wort „ICH“ in die Mitte. Anschließend schreibe bitte die Namen der Personen auf, die in Deinem Leben eine wichtige Rolle spielen. Hierbei gibt die Position der von Dir notierten Bezugsperson an, wie Du zu dieser Person stehst, und zeigt somit die Nähe bzw. Distanz zu Deiner Bezugsperson an. Überdies kannst Du durch Farbunterschiede, durch kräftige oder schwache Verbindungslinien oder auch durch die Verwendung bestimmte Symbole die Beziehungen beschreiben.`}</p>
            <h1 className={`text-white italic ${isSmallScreen ? 'text-[13px]' : 'text-[15px]'} font-bold`}><a target='_blank' className='underline' href="https://drive.google.com/file/d/1rw_3jrhpJS2NVtCMEGjI46Oxuo7W9CH7/view"> {`Beispiele für Symoble `} </a></h1>

            {/* <div className="h-[200px] flex justify-center items-center w-full no-scrollbar "> */}
            <div
                className="relative border border-white border-dashed  rounded-md w-[360px] p-3 !pb-8   "
            >
                <div className="pb-8"></div>
                {/* Central Input */}
                <input
                    type="text"
                    className="w-[100px] h-[30px] mt-4 text-center text-black bg-[#D9D9D9] rounded-full text-3xl p-2 outline-none"
                    placeholder="ICH"
                    value={formik.values.sociogram[0]}
                    // onBlur={formik?.handleBlur}
                    onChange={(e) => handleChange(0, e.target.value)}
                />

                {/* Outer Inputs */}
                {positions.map((pos) => (
                    <div
                        key={pos.index}
                        style={{
                            position: "absolute",
                            top: pos.top,
                            left: pos.left,
                            right: pos.right,
                            transform: pos.transform,
                        }}
                    >
                        <input
                            type="text"
                            placeholder={``}
                            value={formik.values.sociogram[pos.index]}
                            // onBlur={formik?.handleBlur}
                            onChange={(e) => handleChange(pos.index, e.target.value)}
                            className="bg-[#D9D9D9] rounded-full w-[50px] h-[20px] text-center text-black outline-none"
                        />
                    </div>
                ))}


            </div>
            {formik.touched.sociogram && formik.errors.sociogram && (
                <p className="text-[11px] text-left text-red-500 mt-1">
                    {formik.errors.sociogram}
                </p>
            )}
            {/* </div> */}

            {/* <div className='border border-white border-dashed p-3 rounded-md w-[300px]'>
                {/* <h1 className={`text-white italic ${isSmallScreen ? 'text-[13px]' : 'text-[15px]'} font-bold`}> Beispiele für Symoble </h1> 
                <input
                    type="text"
                    className="w-[100px] text-center text-black bg-[#D9D9D9] rounded-full text-3xl p-2 outline-none"
                    placeholder="ICH"
                    value={formik.values.sociogram}
                    onChange={(e) => formik.setFieldValue("sociogram", e.target.value)}
                    onBlur={() => formik.setFieldTouched("sociogram", true)}
                />
            </div> */}
            {/* <p className={`italic text-white font-normal ${isSmallScreen ? 'text-xs' : 'text-sm'} underline`}>  </p> */}
        </div>
    )
}

export default IchForm
