export const gender = [
    { label: 'Männlich', value: 'MALE' },
    { label: 'Weiblich', value: 'FEMALE' },
    { label: 'Divers', value: 'OTHER' },
];

export const country = [
    { label: 'Burgenland', value: 'BURGENLAND' },
    { label: 'Kärnten', value: 'CARINTHIA' },
    { label: 'Niederösterreich', value: 'LOWER_AUSTRIA' },
    { label: 'Oberösterreich', value: 'UPPER_AUSTRIA' },
    { label: 'Salzburg', value: 'SALZBURG' },
    { label: 'Steiermark', value: 'STYRIA' },
    { label: 'Tirol', value: 'TYROL' },
    { label: 'Vorarlberg', value: 'VORARLBERG' },
    { label: 'Wien', value: 'VIENNA' },
];

export const questionsDetailsOne = [
    {
        id: 'hospitalCare',
        text: 'Bist Du derzeit in einem Krankenhaus in regelmäßiger psychiatrischer/ psychologischer/ psychotherapeutischer Betreuung?',
    },
    {
        id: 'psychiatricCare',
        text: 'Bist Du derzeit in fachärztlicher psychiatrischer Behandlung?',
    },
    {
        id: 'psychologicalCare',
        text: 'Bist Du derzeit in psychologischer Behandlung?',
    },
];