import * as Yup from 'yup';
import { sliderConfig } from '../../common/forms/ScalarForm';

export const questions = [
  {
    question: 'Ich habe mich wohl gefühlt',
    option: [
      { label: 'immer', value: 'immer' },
      { label: 'manchmal', value: 'manchmal' },
      { label: 'nie', value: 'nie' },
    ],
    keyName: 'comfortable',
  },
  {
    question: 'Ich habe mich entspannt gefühlt',
    option: [
      { label: 'immer', value: 'immer' },
      { label: 'manchmal', value: 'manchmal' },
      { label: 'nie', value: 'nie' },
    ],
    keyName: 'relaxed',
  },
  {
    question: 'Ich habe ausreichend schlafen  können',
    option: [
      { label: 'immer', value: 'immer' },
      { label: 'manchmal', value: 'manchmal' },
      { label: 'nie', value: 'nie' },
    ],
    keyName: 'sleepEnough',
  },
  {
    question: 'Ich habe ausreichend trinken  und essen können',
    option: [
      { label: 'immer', value: 'immer' },
      { label: 'manchmal', value: 'manchmal' },
      { label: 'nie', value: 'nie' },
    ],
    keyName: 'enoughToDrinkAndEat',
  },
  {
    question: 'Ich habe mich gut konzentrieren können',
    option: [
      { label: 'immer', value: 'immer' },
      { label: 'manchmal', value: 'manchmal' },
      { label: 'nie', value: 'nie' },
    ],
    keyName: 'concentrateWell',
  },
  {
    question: 'Ich habe ausreichend lernen können',
    option: [
      { label: 'immer', value: 'immer' },
      { label: 'manchmal', value: 'manchmal' },
      { label: 'nie', value: 'nie' },
    ],
    keyName: 'learnEnough',
  },
  {
    question: 'Ich   habe auf Auszeiten und Pausen achten können',
    option: [
      { label: 'immer', value: 'immer' },
      { label: 'manchmal', value: 'manchmal' },
      { label: 'nie', value: 'nie' },
    ],
    keyName: 'tookBreak',
  },
  {
    question: 'Ich war ruhig und gelassen',
    option: [
      { label: 'immer', value: 'immer' },
      { label: 'manchmal', value: 'manchmal' },
      { label: 'nie', value: 'nie' },
    ],
    keyName: 'calmAndComposed',
  },
  {
    question: 'Ich   hatte Freude meinen Hobbies nachzugehen',
    option: [
      { label: 'immer', value: 'immer' },
      { label: 'manchmal', value: 'manchmal' },
      { label: 'nie', value: 'nie' },
    ],
    keyName: 'enjoyedHobbies',
  },
  {
    question: ' Ich konnte rechtzeitig spüren, wenn mir etwas zu viel wurde',
    option: [
      { label: 'immer', value: 'immer' },
      { label: 'manchmal', value: 'manchmal' },
      { label: 'nie', value: 'nie' },
    ],
    keyName: 'sensedOverload',
  },
  {
    question: 'Ich habe nicht mehr als sonst gegrübelt',
    option: [
      { label: 'immer', value: 'immer' },
      { label: 'manchmal', value: 'manchmal' },
      { label: 'nie', value: 'nie' },
    ],
    keyName: 'didntBrood',
  },
  {
    question: 'Mir hat vieles Spaß gemacht',
    option: [
      { label: 'immer', value: 'immer' },
      { label: 'manchmal', value: 'manchmal' },
      { label: 'nie', value: 'nie' },
    ],
    keyName: 'enjoyedLife',
  },
  {
    question:
      'Ich   habe es genossen, etwas mit   Freunden/Verwandten zu unternehmen',
    option: [
      { label: 'immer', value: 'immer' },
      { label: 'manchmal', value: 'manchmal' },
      { label: 'nie', value: 'nie' },
    ],
    keyName: 'enjoyedSocializing',
  },
  {
    question:
      'Ich war zuversichtlich, dass ich meine Vorhaben gut schaffen kann',
    option: [
      { label: 'immer', value: 'immer' },
      { label: 'manchmal', value: 'manchmal' },
      { label: 'nie', value: 'nie' },
    ],
    keyName: 'confidentAboutProject',
  },
];


export const firstSevenQuestions = questions.slice(0, 7);
export const lastSevenQuestions = Array.isArray(questions) ? questions.slice(7) : [];
export const inputQuestion = [
  {
    question:
      'Welche positiven Verhaltensweisen zeichnen Dich aus (z.B. Sport, gesunde Ernährung etc.)?',
    keyName: 'positiveBehaviors',
  },
  {
    question:
      'Welche schlechten Gewohnheiten hast Du (z.B. Rauchen, Schlafmangel etc.)?',
    keyName: 'badHabits',
  },
  {
    question: 'Was sind Deine Stärken?',
    keyName: 'strengths',
  },
  {
    question: 'Was sind Deine Schwächen?',
    keyName: 'weaknesses',
  },
  {
    question: 'Was sind Deine Hobbys?',
    keyName: 'hobbies',
  },

  {
    question: 'Worauf bist Du besonders stolz?',
    keyName: 'enjoyBeingWith',
  },
  {
    question: `Mit welchen Menschen bist Du gerne
    zusammen?`,
    keyName: 'proudOf',
  },
  {
    question: `Was gibt Dir Kraft?`,
    keyName: 'strength',
  },
  {
    question: `Was macht Dich glücklich?`,
    keyName: 'happiness',
  },
  {
    question: `Was möchtest Du in 5 Jahren erreicht
    haben?`,
    keyName: 'futureGoals',
  },
];

export const firstFiveInputQuestions = inputQuestion.slice(0, 5)
export const lastFiveInputQuestions = inputQuestion.slice(5)

export const levelOneMcqs = [
  {
    question: 'Familie',
    option: [{ value: 'Ja', label: 'Ja' }],
  },
  {
    question: 'Sportliche Aktivitäten',
    option: [{ value: 'Ja', label: 'Ja' }],
  },
  {
    question: 'Freunde',
    option: [{ value: 'Ja', label: 'Ja' }],
  },
  {
    question: 'Haustiere',
    option: [{ value: 'Ja', label: 'Ja' }],
  },
  {
    question: 'Religion',
    option: [{ value: 'Ja', label: 'Ja' }],
  },
  {
    question: 'Anderes',
    option: [{ value: 'Ja', label: 'Ja' }],
  },
];

export const DATA_Playlist = [
  {
    title: 'Willst Du eine Playlist hochladen?',
    data: [
      {
        question: '· Mein Lied, wenn ich traurig bin!',
        keyName: 'sadSong',
      },
      {
        question: '· Mein Lied, wenn ich verärgert bin!',
        keyName: 'upsetSong',
      },
      {
        question: '· Mein Lied, wenn ich ängstlich bin!',
        keyName: 'anxiousSong',
      },
      {
        question: '· Mein Lied, wenn ich glücklich bin!',
        keyName: 'happySong',
      },
    ],
  },
];

export const getValidationSchema = renderForm => {
  // console.log('renderForm=========>>>>>>>>', {renderForm});
  if (renderForm === 0) {
    return Yup.object(
      sliderConfig.reduce((schema, slider) => {
        schema[slider.name] = Yup.number()
          .required('This field is required')
          .min(slider.minValue, `Minimum value is ${slider.minValue}`)
          .max(slider.maxValue, `Maximum value is ${slider.maxValue}`);
        return schema;
      }, {}),
    );
  } else if (renderForm === 1) {
    return Yup.object(
      firstSevenQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          'Bitte wähle eine Option.',
        );
        return schema;
      }, {}),
    );
  } else if (renderForm === 2) {
    return Yup.object(
      lastSevenQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          'Bitte wähle eine Option.',
        );
        return schema;
      }, {}),
    );
  } else if (renderForm === 3) {
    return Yup.object(
      firstFiveInputQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          'Dieses Feld ist erforderlich.',
        );
        return schema;
      }, {}),
    );
  } else if (renderForm === 4) {
    return Yup.object(
      lastFiveInputQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          'Dieses Feld ist erforderlich.',
        );
        return schema;
      }, {}),
    );
  } else if (renderForm === 5) {
    return Yup.object().shape({
      answers: Yup.array().of(
        Yup.object().shape({
          checked: Yup.boolean(),
          input: Yup.string().test(
            'is-checked-input-required',
            'Bitte fülle dieses Feld aus.', // This is what should be returned if invalid
            function (value) {
              const { checked } = this.parent;
              // console.log('Checked:', checked, 'Value:', value); // Debugging output
              return !checked || (value && value.trim() !== '');
            },
          ),
        }),
      ),
    });
  } else if (renderForm === 6) {
    return Yup.object(
      DATA_Playlist[0].data.reduce((schema, item) => {
        schema[item.keyName] = Yup.string().required(
          'Dieses Feld ist erforderlich.',
        );
        return schema;
      }, {}),
    );
  }

  // Add other cases as needed
  return Yup.object(); // Default empty schema
};
