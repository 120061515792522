import React, { useState, useRef, useEffect } from "react";
import { appImages } from "../../../assets";
import { toast } from "react-toastify";

const MoodSelector = ({ formik }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [textInput, setTextInput] = useState("");
    const canvasRef = useRef(null);

    const images = [
        appImages?.happy,
        appImages?.sick,
        appImages?.emotional,
        appImages?.arms,
        appImages?.depressed,
        appImages?.scared,
    ];

    const mood_cards = [
        { img: appImages.happy, id: 1 },
        { img: appImages.sick, id: 2 },
        { img: appImages.emotional, id: 3 },
        { img: appImages.arms, id: 4 },
        { img: appImages.depressed, id: 5 },
        { img: appImages.scared, id: 6 },
    ];

    useEffect(() => {
        if (formik.values.moodCard) {
            setSelectedImage(mood_cards.find((card) => card.id === formik.values.moodCard)?.id || null);
        }
        if (formik.values.drawing) {
            const canvas = canvasRef.current;
            const context = canvas.getContext("2d");
            const img = new Image();
            img.onload = () => {
                context.drawImage(img, 0, 0);
            };
            img.src = formik.values.drawing;
        }
    }, [formik.values.moodCard, formik.values.drawing]);

    const handleImageSelect = (index) => {
        setSelectedImage(index);
        formik.setFieldValue('moodCard', mood_cards[index].id);
    };

    const handleClearCanvas = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);
        toast.success('Zeichnung gelöscht');
    };

    const handleCanvasDraw = (event) => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        const rect = canvas.getBoundingClientRect();

        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        context.fillStyle = "black";
        context.beginPath();
        context.arc(x, y, 5, 0, Math.PI * 2, true);
        context.fill();
    };

    const handleSaveCanvas = () => {
        const canvas = canvasRef.current;
        const imageData = canvas.toDataURL("image/png");
        formik.setFieldValue("drawing", imageData);
        toast.success('Zeichnung gespeichert');
    };

    return (
        <div className="flex flex-col items-center p-4">
            {/* Image Selection */}
            <h2 className="text-[13px] text-white font-[500] mb-2">
                Welches Bild passt am besten zu Deiner heutigen Stimmung?
            </h2>
            <div className="grid grid-cols-3 gap-4 mb-8">
                {mood_cards.map((src, index) => (
                    <img
                        key={index}
                        src={src?.img}
                        alt={`Mood ${index + 1}`}
                        className={`w-24 h-12 object-cover cursor-pointer rounded-lg ${selectedImage === index
                            ? "ring-4 ring-[#F9DCD4]"
                            : "hover:ring-4 hover:ring-gray-300"
                            }`}
                        onClick={() => handleImageSelect(index)}
                    />
                ))}
            </div>

            {formik.errors.moodCard && (
                <p className="text-red-500 text-xs mb-1 w-full text-left">
                    {formik.errors.moodCard}
                </p>
            )}

            {/* Drawing and Writing Section */}
            <h3 className="text-[13px] text-white font-[500] mb-2">
                Du kannst Deine heutige Stimmung selbst zeichnen, hier hast Du Platz dafür!
            </h3>
            <div className="flex flex-col md:flex-row gap-4 w-full max-w-4xl">
                {/* Drawing Canvas */}
                <div className="bg-[#D9D9D9] p-4 rounded-lg w-full relative">
                    <h4 className="text-md font-medium mb-2">Platz zum Zeichnen</h4>
                    <button className="w-5 absolute top-2 right-32" onClick={handleSaveCanvas}> Speichern </button>
                    <button className="w-5 absolute top-2 right-10" onClick={handleClearCanvas}> Klar </button>
                    <canvas
                        ref={canvasRef}
                        className="border border-gray-400 rounded-lg cursor-crosshair"
                        width="510"
                        height="100"
                        onMouseDown={(e) => e.buttons === 1 && handleCanvasDraw(e)}
                        onMouseMove={(e) => e.buttons === 1 && handleCanvasDraw(e)}
                    ></canvas>
                </div>
            </div>
            {formik.errors.drawing && (
                <p className="text-red-500 text-xs mb-1 w-full text-left">
                    {formik.errors.drawing}
                </p>
            )}
        </div>
    );
};

export default MoodSelector;
