import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// ** Reducers
import UserSlice from './Started';
import UserAuthSlice from './auth/authSlice';

const rootReducer = combineReducers({
  user: UserAuthSlice,
  initialUserInfo: UserSlice,
});

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['user'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === 'development',

  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
