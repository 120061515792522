import React from 'react'
import mainBG from '../../../assets/EinführungMainBG.png'
import ellipseBG from '../../../assets/EllipseBG.png'
import techVideo from '../../../assets/tech.mov'

const PresentationStart = ({ nextClick, backClick }) => {
    const isSmallScreen = window.innerHeight <= 600;

    return (
        <>
            <div style={{ background: `url(${mainBG})` }} className='h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between'>
                <div></div>
                <div style={{ background: `url(${ellipseBG})` }} className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'>
                    <div className={`relative ${isSmallScreen ? 'pt-12' : 'pt-20'} w-full ${isSmallScreen ? 'max-w-[38rem]' : 'max-w-[36rem]'} flex flex-col justify-between h-full justify-self-center text-center pb-4`}>
                        <div>
                            <h1 className='font-semibold text-white text-[15px]'>{`Willkommen bei ist`}</h1>
                            <h1 className='font-semibold text-white text-[15px]'>{`„My Simurg Guide“`}</h1>
                            <h1 className='font-semibold text-white text-[15px] mt-4'>{`Willkommen bei „My Simurg Guide“ Wir möchten Dir erklären, warum das Online-Programm „My Simurg Guide“ heißt, und wie es Dir in Stresssituationen helfen kann! `}</h1>

                            <div className='mt-4'>
                                <video
                                    className='w-full h-auto max-w-[300px] mx-auto'

                                    controls
                                >
                                    <source src={techVideo} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>

                            <h1 className='font-semibold text-white text-sm mt-6'>{`Fazit:`}<br /> {`Wenn man seine Ziele nicht aufgibt, erreicht man ganz bestimmt seine Ziele!`}</h1>

                        </div>

                        <div className={`${isSmallScreen ? 'pt-2' : 'pt-4'} flex gap-6`}>
                            <button
                                type="button"
                                className="w-full button_primary"
                                onClick={backClick}
                            >
                                Zurück
                            </button>
                            <button type="submit" className="w-full button_secodry" onClick={nextClick} >
                                Weiter gehts
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default PresentationStart