import React from 'react'
import techVideo from '../../../assets/tech.mov'
import { useNavigate } from 'react-router-dom';

const VideoScreen = ({ setRenderForm }) => {
    const navigate = useNavigate();
    const isSmallScreen = window.innerHeight <= 600;

    return (
        <>
            <div>
                <h1 className={`font-medium text-white ${isSmallScreen ? 'text-xs' : 'text-sm'} `}>{`In diesem Video möchten wir Dir mitteilen, wie Du mit Stresssituationen besser umgehen und Deine Selbstfürsorge verbessern kannst – dies gelingt durch Fertigkeiten (auch Skills genannt), die Du auch im Alltag anwenden kannst.`}</h1>

                {/* Video section */}
                <div className='mt-4'>
                    <video
                        className={`w-full ${isSmallScreen ? 'h-[100px]' : 'h-auto'} max-w-[300px] mx-auto`}
                        controls

                    >
                        <source src={techVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <h1 className={`font-bold italic ${isSmallScreen ? 'text-[10px]' : 'text-xs'} mt-6 text-white`}>Hiert findest Du eine <a href="https://drive.google.com/file/d/1bH-vwbnBQqhW2WxBGcUPZk9DPFFV2Zyo/view" target='_blank' className=' underline' >Liste</a>  von verschiedenen Skills, die schon anderen Jugendlichen in Stresssituationen gut geholfen haben!</h1>
                <h1 className={`font-bold text-white ${isSmallScreen ? 'text-[10px]' : 'text-xs'}  mt-4`}>{`Gratuliere!`}<br /> {`Du  bist mit  Level 1 fertig! Du kannst jetzt mit Level 2 fortsetzen!`}</h1>

            </div>
        </>
    )
}

export default VideoScreen