import React, { useEffect, useRef, useState } from 'react';
import ellipseBG from '../../assets/EllipseBG.png'
import Intial from './forms/intial'
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { getValidationSchema } from './constant';
import { useSelector, useDispatch } from 'react-redux';
import ScalarForm, { sliderConfig } from '../common/forms/ScalarForm';
import LevelRadioSelectionlastSeven from '../common/forms/LevelRadioSelectionlastSeven';
import { firstSevenQuestions, lastSevenQuestions } from '../common/constant/data';
import LevelRadioSelection from '../common/forms/LevelRadioSelection';
import techVideo from '../../assets/tech.mov'
import { setUserLevelThreeInfo } from '../../redux/Started';
import { handleAddedPersonalInfoAction } from '../../redux/Started/apisActions';
import Spiner from '../common/constant/spiner';


const LevelThree = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [renderForm, setRenderForm] = useState(0);
    const [validationSchema, setValidationSchema] = useState(
        getValidationSchema(renderForm),
    );

    useEffect(() => {
        setValidationSchema(getValidationSchema(renderForm - 1)); // Update schema when renderForm changes
    }, [renderForm]);

    const { levelOneForm, loading } = useSelector(state => state.initialUserInfo);
    const { userID } = useSelector(state => state.user);
    const formik = useFormik({
        initialValues: {
            ...sliderConfig.reduce(
                (values, slider) => ({
                    ...values,
                    [slider.name]: 0,
                }),
                {},
            ),
            ...firstSevenQuestions.reduce(
                (values, question) => ({
                    ...values,
                    [question.keyName]: '', // Default value for each question
                }),
                {},
            ),
            ...lastSevenQuestions.reduce(
                (values, question) => ({
                    ...values,
                    [question.keyName]: '', // Default value for each question
                }),
                {},
            ),
        },
        validationSchema,
        onSubmit: values => {
            const data = {
                userId: userID || '',
                LevelThree: JSON.stringify(values),
            };
            if (renderForm === 8) {
                dispatch(setUserLevelThreeInfo(values));
                dispatch(
                    handleAddedPersonalInfoAction({
                        data,
                        callback: res => navigate('/level-four'),
                    }),
                );
                // navigate('/level-four');
            } else {
                formik.setTouched({});
                setRenderForm(renderForm + 1);
            }
            console.log('Form Values:', values);
        },
    });


    return (
        <>
            <div className='self-center pt-4 text-center '>
                {renderForm === 2 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >
                            Befindlichkeits-Einschätzung
                        </h1>
                        <h1 className='text-[14px] text-mainBlue font-bold italic ' >
                            Wie ist es Dir in den letzten 7 Tagen gegangen?

                        </h1>
                        <h1 className='text-[14px] text-mainBlue font-semibold' >   Bitte kreuze in jedem Kästchen an, was für Dich zutrifft - achte dabei, dass Du alle Zeilen genau durchliest.</h1>
                    </>
                )}
                {renderForm === 0 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >Level 3</h1>
                        <h1 className='text-[16px] text-mainBlue font-bold italic ' >
                            Methoden zur Stressbewältigung </h1>
                        <h1 className='text-[16px] text-mainBlue font-semibold' >Willkommen im Level 3!</h1>
                    </>
                )}
                {renderForm === 4 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Bauchatmung zur Entspannung </h1>
                    </>
                )}
                {renderForm === 5 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Distanzierungsmethode - 5-4-3-2-1 <br /> (nach Yvonne Dolan) </h1>
                    </>
                )}
                {renderForm === 6 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Achtsamkeit  </h1>
                    </>
                )}
                {renderForm === 7 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Let´s smile!   </h1>
                    </>
                )}
                {renderForm === 8 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Progressive Muskelentspannung   </h1>
                    </>
                )}

            </div>
            <div style={{ background: `url(${ellipseBG})` }} className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'>
                <div className='relative pt-20 w-full max-w-[36rem] flex flex-col justify-between h-full justify-self-center text-center py-2'>
                    {/* <Intial /> */}
                    <div className="max-w-[32rem] mx-auto">
                        {renderForm === 0 && <Intial />}
                        {renderForm === 1 && <ScalarForm formik={formik} />}
                    </div>


                    {renderForm === 2 && <LevelRadioSelection formik={formik} />}
                    {renderForm === 3 && <LevelRadioSelectionlastSeven formik={formik} />}

                    {renderForm === 4 && (
                        <div className="flex flex-col gap-5 justify-start items-start">
                            <p className="text-[14px] text-white italic font-medium">
                                Die Atmung ist das wichtigste Werkzeug als Entspannungstechnik für
                                die Stressbewältigung. Wenn wir ängstlich, gestresst oder belastet
                                sind, atmen wir oft zu schnell und flach in die Brust. Sind wir
                                hingegen entspannt, atmen wir tief in den Bauch. Umgekehrt gilt:
                                Die Atmung ist eine der wenigen Funktionen, die wir bewusst
                                beeinflussen können- die Bauchatmung ist daher geeignet, um
                                Anspannung zu reduzieren.
                            </p>
                            <div className='mt-2 max-w-[270px] mx-auto'>
                                <video
                                    className='w-full h-auto '

                                    controls
                                >
                                    <source src={techVideo} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    )}

                    {renderForm === 5 && (
                        <div className="flex flex-col gap-5 justify-start items-start">
                            <p className="text-[14px] text-white italic font-bold">
                                Ziel: dieser Methode ist es, einen Ausweg von „Gedankenkreisen im
                                Kopf“ zu bekommen und die Aufmerksamkeit auf das Hier und Jetzt zu
                                lenken.
                            </p>
                            <p className="text-[14px] text-white italic font-medium">
                                Was es braucht: Für die Übung ist es wichtig, dass Du Dir ein paar
                                Minuten Zeit nimmst, sonst gibt es keine wichtigen
                                Voraussetzungen.
                            </p>
                            <div className='mt-2 max-w-[270px] mx-auto'>
                                <video
                                    className='w-full h-auto '

                                    controls
                                >
                                    <source src={techVideo} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    )}

                    {renderForm === 6 && (
                        <div className="flex flex-col gap-1 justify-start items-start">
                            <p className="text-[14px] text-white italic font-medium">
                                Im Alltag ist man oft gedanklich entweder in der Vergangenheit
                                oder in der Zukunft. Häufig ärgert man sich über Dinge, die wir im
                                Nachhinein ohnehin nicht mehr ändern können, oder machen uns
                                Sorgen über die Zukunft. Daher bringt die Achtsamkeit die Gedanken
                                zurück ins Hier und Jetzt und hilft Dir auf diese Weise, Dich
                                wieder auf das zu fokussieren, was im Moment wesentlich ist. Wenn
                                Du trainierst achtsamer zu werden, lernst Du auch wieder, Deinen
                                Körper bewusster wahrzunehmen.
                            </p>
                            <div className='mt-2 max-w-[270px] mx-auto'>
                                <video
                                    className='w-full h-auto '

                                    controls
                                >
                                    <source src={techVideo} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    )}

                    {renderForm === 7 && (
                        <div className="flex flex-col gap-0 justify-start items-center">
                            <p className="text-[13px] text-white italic font-bold">
                                Dein Körper und Dein Verhalten haben Einfluss auf Deine Gefühle
                                und auf Deine Gedanken. Wenn Du auch nur leicht lächelst, kann es
                                Dir danach bessergehen. täuschen.
                            </p>
                            <p className="text-[13px] text-white italic font-bold">
                                Beim Lachen werden Glückshormone freigesetzt, dadurch wird der
                                Stress reduziert und das Immunsystem gestärkt. Der Lachmuskel
                                drückt auf einen Nerv, der mit dem Gehirn verbunden ist, deshalb
                                wird wenn die Mundwinkel oben sind, dem Gehirn signalisiert, dass
                                es Dir gut geht. Somit kannst Du Dein Gehirn ganz einfach
                                täuschen.
                            </p>
                            <div className='mt-2 max-w-[270px] mx-auto'>
                                <video
                                    className='w-full h-auto '

                                    controls
                                >
                                    <source src={techVideo} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    )}

                    {renderForm === 8 && (
                        <div className="flex flex-col gap-2">
                            <p className="text-[12px] text-white italic font-bold">
                                Die progressive Muskelentspannung ist eine geeignete Methode zur
                                Entspannung – sie wirkt umso besser, je öfter sie angewandt wird.
                                Am besten sitzt Du dabei auf einem bequemen Sessel, oder Du liegst
                                auf einer bequemen Decke am Boden oder in Deinem Bett. Falls Du
                                dabei einschläfst, macht es nichts – das bedeutet nur, dass die
                                Entspannung bei Dir gut wirkt!
                            </p>
                            <div className='mt-2 max-w-[270px] mx-auto'>
                                <video
                                    className='w-full h-auto '

                                    controls
                                >
                                    <source src={techVideo} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div className="text-center">
                                <p className="text-[14px] text-white italic font-bold">Gratuliere!</p>
                                <p className="text-[14px] text-white italic font-bold">Du bist mit Level 3 fertigt!</p>
                                <p className="text-[14px] text-white italic font-bold">Du kannst jetzt mit Level 4 fortsetzen!</p>
                            </div>
                        </div>
                    )}


                    <div className='pt-4 mb-2 flex gap-2'>
                        <button className='w-full button_primary' onClick={() => {
                            if (renderForm === 0) {
                                navigate('/level-two');
                            } else {
                                setRenderForm(renderForm - 1);
                            }
                            // navigation.goBack();
                        }} >Zurück</button>
                        <button className='w-full button_secodry relative' onClick={() => {
                            formik.handleSubmit();
                        }}
                            disabled={loading}
                        >

                            {loading && (
                                <Spiner />
                            )}
                            <span className={loading ? "opacity-0" : ""}>Weiter gehts</span>

                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LevelThree
