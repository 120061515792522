import React from 'react'
import Image4 from '../../../assets/images/image 4.png'
import Image5 from '../../../assets/images/image 5.png'
import Image6 from '../../../assets/images/image 6.svg'

const Description = ({ nextClick }) => {
    return (
        <div className='bg-white w-full min-h-screen p-4 flex items-center justify-center' >
            <div className="max-w-[1000px] mx-auto ">
                <p className='text-center mb-12' >Fachliche, inhaltliche und technische Entwicklung, Layout und Design </p>

                <div className="grid grid-cols-3 gap-5">
                    <div className="text-center">
                        <img src={Image4} alt="" className='mx-auto' />
                    </div>
                    <div className="">
                        <p className='text-black text-[12px] ' >
                            <span className='italic font-bold' > Prof.in Dr.in Türkan Akkaya-Kalayci
                                Projektleiterin, Fachärztin für Kinder-u. Jugendpsychiatrie und Psychotherapeutische Medizin, Systemische Familientherapeutin,</span>
                            <br />
                            Uniklinik für Kinder- und Jugendpsychiatrie, MedUniWien
                        </p>
                    </div>
                    <div className="">
                        <p className='text-black text-[12px] ' >
                            Das Team wurde fachlich und inhaltlich von Ass. Prof.in Dr.in Susanne Ohmann, Klinische-und Gesundheitspsychologin, Psychotherapeutin(Verhaltenstherapie), Uniklinik für Kinder- und Jugendpsychiatrie, MedUniWien, unterstützt.
                        </p>
                    </div>
                </div>
                <div className="grid grid-cols-3 gap-5">
                    <div className="text-center">
                        <img src={Image5} alt="" className='mx-auto mt-20' />
                    </div>
                    <div className="col-span-2">
                        <div className="grid grid-cols-2 gap-5">
                            <p className='text-black text-[12px] mt-20 ' >
                                <span className="font-bold">Mag.a Zeliha Özlü-Erkilic, PhD</span>

                                <span className='italic font-bold' > Klinische und Gesundheitspsychologin, Systemische Familientherapeutin,
                                    Kinder- und Jugendlichenpsychotherapeutin,</span>
                                <br />
                                Projektmitarbeiterin, Uniklinik für Kinder- und Jugendpsychiatrie, MedUniWien
                            </p>
                            <div className="">
                                <p className='text-black text-[12px] mb-5' >
                                    Technische und graphische Unterstützung bei der Entwicklung der Webseite und App: Victor Croitoru und von Firma ITFC :Armin Vogt, Phillip Landro, Lavinia.Nimczewski
                                </p>
                                <p className='text-black text-[12px] mb-5' >
                                    Das Projekt „Online-Programm zur Resilienzförderung bei Jugendlichen – My Simurg Guide“ wurde gefördert aus den Mitteln des Fonds Gesundes Österreich und des Bundesministeriums für Soziales, Gesundheit, Pflege und Konsumentenschutz.
                                </p>
                            </div>
                        </div>
                        <img src={Image6} className='w-full mt-6' alt="" />
                    </div>

                </div>
                <div className="text-center mt-8">
                    <button className='w-[350px] mx-auto button_secodry' onClick={nextClick} >Weiter gehts</button>
                </div>

            </div>


        </div>
    )
}

export default Description
