import * as Yup from 'yup';
import { sliderConfig } from '../../common/forms/ScalarForm';
import { firstSevenQuestions, lastSevenQuestions } from '../../common/constant/data';


export const resources_options = [
  { label: 'Sehr gut', value: 'SehrGut' },
  { label: 'Gut', value: 'gut' },
  { label: 'Schlecht', value: 'schlecht' },
  { label: 'Sehr schlecht', value: 'SehrSchlecht' },
];

export const List = [
  {
    title: `Denke an eine Stresssituation oder an ein Problem der Vergangenheit! Nun beantworte `,
    url: 'https://drive.google.com/file/d/1mH01ljf1pZktkGs69I4bepAazlprGCZs/view',
    link: 'diese Fragen',
  },
  {
    title: `Nun kommen wir wieder zurück in die Gegenwart! Nun beantworte  `,
    url: 'https://drive.google.com/file/d/1EBcm_cV4jE7QkRRB5nY12Piw0WdMGyAr/view',
    link: 'diese Fragen',
  },
  {
    title: `Nun werfe einen Blick in die Zukunft! Nun beantworte  `,
    url: 'https://drive.google.com/file/d/1PK6AkIJf4slbU3ECqUEvQ7KiCWhjkhb1/view',
    link: 'diese Fragen',
  },
];

export const resources_list = [
  {
    question: 'Familie',

    keyName: 'family',
  },
  {
    question: 'Verwandte',
    keyName: 'related',
  },
  {
    question: 'Freund:innen ',
    keyName: 'friends',
  },
  {
    question: 'Partner:innen ',
    keyName: 'partners',
  },
  {
    question: 'Lehrer:innen',
    keyName: 'teachers',
  },
  {
    question: 'Mitschüler:innen',
    keyName: 'classmates',
  },
  {
    question: 'Soziale Medien',
    keyName: 'socialMedia',
  },

  {
    question: 'Schule',
    keyName: 'school',
  },
  {
    question: 'Lehrstelle/Arbeit',
    keyName: 'apprenticeshipWork',
  },
  {
    question: 'Gesundheit',
    keyName: 'health',
  },
  {
    question: 'Attraktivität/Aussehen',
    keyName: 'attractiveness',
  },
  {
    question: 'Charakter',
    keyName: 'character',
  },
  {
    question: 'Intelligenz',
    keyName: 'intelligence',
  },
  {
    question: 'Sprache',
    keyName: 'language',
  },
  {
    question: 'Sport',
    keyName: 'sport',
  },
  {
    question: 'Hobbies',
    keyName: 'hobbies',
  },

  {
    question: 'Ernährung',
    keyName: 'nutrition',
  },
  {
    question: 'Schlaf',
    keyName: 'sleep',
  },
  {
    question: 'Zukunftspläne',
    keyName: 'futurePlans',
  },
  {
    question: 'Positives Denken',
    keyName: 'positiveThinking',
  },
  {
    question: 'Selbstvertrauen',
    keyName: 'confidence',
  },
  {
    question: 'Selbstbestimmung',
    keyName: 'selfDetermination',
  },
  {
    question: 'Religion/Spiritualität',
    keyName: 'religionSpirituality',
  },
  {
    question: 'Finanzen (Geld)',
    keyName: 'finance',
  },
  {
    question: 'Andere',
    keyName: 'other',
  },
];


export const firstSevenResources = resources_list.slice(0, 7);
export const secondSevenResources = resources_list.slice(7, 16);
export const thirdSevenResources = resources_list.slice(16);

export const getValidationSchema = renderForm => {
  if (renderForm === 0) {
    return Yup.object(
      sliderConfig.reduce((schema, slider) => {
        schema[slider.name] = Yup.number()
          .required('Dieses Feld ist erforderlich.')
          .min(slider.minValue, `Der Mindestwert beträgt ${slider.minValue}.`)
          .max(slider.maxValue, `Der Höchstwert beträgt ${slider.maxValue}.`);
        return schema;
      }, {}),
    );
  } else if (renderForm === 1) {
    return Yup.object(
      firstSevenQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          'Bitte wähle eine Option.',
        );
        return schema;
      }, {}),
    );
  } else if (renderForm === 2) {
    return Yup.object(
      lastSevenQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          'Bitte wähle eine Option.',
        );
        return schema;
      }, {}),
    );
  } else if (renderForm === 4) {
    return Yup.object(
      firstSevenResources.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          'Bitte wähle eine Option.',
        );
        return schema;
      }, {}),
    );
  } else if (renderForm === 5) {
    return Yup.object(
      secondSevenResources.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          'Bitte wähle eine Option.',
        );
        return schema;
      }, {}),
    );
  } else if (renderForm === 6) {
    return Yup.object(
      thirdSevenResources.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          'Bitte wähle eine Option.',
        );
        return schema;
      }, {}),
    );
  }
  // Add other cases as needed
  return Yup.object(); // Default empty schema
};
