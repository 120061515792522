
import React from 'react'

const Intial = () => {
    return (
        <div className='max-w-[400px] px-4 mx-auto' >
            <h1 className='font-medium text-[17px] text-[#fff] leading-[26px]'>
                {`In diesem Level kannst Du Deine Energiequellen zum Aufladen Deiner „inneren Batterie“ erkennen!`}
            </h1>
            <h1 className='font-medium text-[17px] text-[#fff] mt-2 leading-[26px]'>
                {`Du kannst erkennen, wer/ was Dir bisher  geholfen und Dir Kraft gegeben hat, Deine „innere Batterie“ durch Deine Energiequellen aufzuladen. `}
            </h1>

        </div>
    )
}

export default Intial
