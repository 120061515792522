import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LevelThree from '../screens/LevelThree';
import Starting from '../screens/starting';
import LevelOne from '../screens/levelone';
import LevelTwo from '../screens/LevelTwo';
import LevelFour from '../screens/LevelFour';
import LevelFive from '../screens/LevelFive';
import LevelSix from '../screens/LevelSix';

const MainStack = () => {
    return (
        <Router>
            <React.Suspense fallback={''}>
                <Routes>
                    <Route path="/" exact element={<Starting />} />
                    <Route path="/level-one" exact element={<LevelOne />} />
                    <Route path="/level-two" element={<LevelTwo />} />
                    <Route path="/level-three" exact element={<LevelThree />} />
                    <Route path="/level-four" exact element={<LevelFour />} />
                    <Route path="/level-five" exact element={<LevelFive />} />
                    <Route path="/level-six" exact element={<LevelSix />} />
                </Routes>
            </React.Suspense>
        </Router>
    );
};

export default MainStack;
