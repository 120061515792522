import React from 'react'
import mainBG from '../../../assets/EinführungMainBG.png'

const OnlineGuide = ({ nextClick }) => {
    return (
        <>
            <div style={{ background: `url(${mainBG})` }} className='h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between'>
                <div class="mt-8 text-center text-mainBlue text-6xl font-medium">
                    My Simurg Guide
                </div>
                <div className='py-8 mx-auto'>
                    <div class="bordered-text">
                        Discover the power in you!
                    </div>
                    <button className='w-full button_secodry min-w-[300px] button_secondry_bg' onClick={() => nextClick()}>Lass uns anfangen</button>
                </div>
            </div>
        </>
    )
}

export default OnlineGuide