// RestaurantSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAddedPersonalInfoAction } from './apisActions';

const initialState = {
  loading: false,
  error: null,
  userInfo: {},
  levelOneForm: {},
  levelTwoForm: {},
  levelThreeForm: {},
  levelFourForm: {},
  levelFiveForm: {},
  levelSixForm: {},
};

// ** Function: Reducer
const UserSlice = createSlice({
  name: 'initialUserInfo',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    resetUserInfo: (state, action) => {
      state.userInfo = {};
    },
    setUserLevelOneInfo: (state, action) => {
      state.levelOneForm = action.payload;
    },
    setUserLevelTwoInfo: (state, action) => {
      state.levelTwoForm = action.payload;
    },
    setUserLevelThreeInfo: (state, action) => {
      state.levelThreeForm = action.payload;
    },
    setUserLevelFourInfo: (state, action) => {
      state.levelFourForm = action.payload;
    },
    setUserLevelFiveInfo: (state, action) => {
      state.levelFiveForm = action.payload;
    },
    setUserLevelSixInfo: (state, action) => {
      state.levelSixForm = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      // ** STATES: Restaurants and Restaurant Menu
      .addCase(handleAddedPersonalInfoAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(handleAddedPersonalInfoAction.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(handleAddedPersonalInfoAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setUserInfo,
  resetUserInfo,
  setUserLevelOneInfo,
  setUserLevelTwoInfo,
  setUserLevelThreeInfo,
  setUserLevelFourInfo,
  setUserLevelFiveInfo,
  setUserLevelSixInfo,
} = UserSlice.actions;

export default UserSlice.reducer;
