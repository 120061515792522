import React, { useState } from 'react'
import mainBG from '../../../assets/EinführungMainBG.png'
import ellipseBG from '../../../assets/EllipseBG.png'
import { avatars } from '../../../constants/data';
import { AvatarCheckboxIcon, AvatarCheckedIcon } from '../../../utils/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { resetUserInfo, setUserInfo } from '../../../redux/Started';
import { handleAddedPersonalInfoAction } from '../../../redux/Started/apisActions';
import { setUserID } from '../../../redux/auth/authSlice';
import Spiner from '../../common/constant/spiner';

const CharacterSelect = ({ setRenderForm }) => {
    const isSmallScreen = window.innerHeight <= 600;
    const validationSchema = Yup.object({
        // selectedAvatar: Yup.number()
        //     .required('Please select an avatar')
        //     .oneOf(
        //         avatars.map(avatar => avatar.id),
        //         'Invalid selection',
        //     ),
        // nickname: Yup.string()
        //     .test('nickname-required', 'Please enter a nickname', function (value) {
        //         const { selectedAvatar } = this.parent;
        //         if (selectedAvatar) {
        //             return !!value; // Ensure value is non-empty if `selectedAvatar` is set
        //         }
        //         return true; // Pass validation if no avatar is selected
        //     })
        //     .test(
        //         'nickname-length',
        //         'Nickname must be at least 2 characters long',
        //         function (value) {
        //             const { selectedAvatar } = this.parent; // Access `selectedAvatar` again
        //             if (selectedAvatar) {
        //                 return value && value.length >= 2; // Check minimum length only if `selectedAvatar` is set
        //             }
        //             return true; // Pass validation if no avatar is selected
        //         },
        //     ),
    });

    const dispatch = useDispatch();
    const { userInfo, loading } = useSelector(state => state.initialUserInfo);

    const formik = useFormik({
        initialValues: {
            selectedAvatar: null,
            nickname: '',
        },
        validationSchema,
        onSubmit: values => {
            const dataFormat = {
                ...userInfo,
                ...values,
            };

            const data = {
                Personal: {
                    fullName: values?.nickname,
                    age: userInfo?.age,
                    // phoneNumber: userInfo?.phone || '',
                    email: userInfo?.email || '',
                    federalState: userInfo?.country || '',
                    gender: userInfo?.gender || '',
                    birthPlace: {
                        country: userInfo?.placeOfBirth || '',
                        fatherCountry: userInfo?.fatherPlaceOfBirth || '',
                        motherCountry: userInfo?.motherPlaceOfBirth || '',
                    },
                    avatar: values?.selectedAvatar,
                    diagnoses: [...userInfo?.MultiOptions],
                    otherDiagnosis: userInfo?.andereText || '',
                    isTakingMedication: userInfo?.medication === 'No' ? false : true,
                    whichTakeMedication: userInfo?.details || '',
                    agree: userInfo?.agreement,
                    termsAndCondition: userInfo?.termsAndCondition,
                    psychiatricCare: userInfo?.psychiatricCare || '',
                    psychiatricDiagnosis: userInfo?.psychiatricDiagnosis || '',
                    psychologicalCare: userInfo?.psychologicalCare || '',
                    hospitalCare: userInfo?.hospitalCare || '',
                    psychotherapy: userInfo?.psychotherapy || '',
                },
            };

            dispatch(setUserInfo(dataFormat));
            dispatch(
                handleAddedPersonalInfoAction({
                    data,
                    callback: (res) => {
                        dispatch(setUserID(res?.id));
                        setRenderForm(14);
                    },
                }),
            );
        },
    });

    return (
        <div style={{ background: `url(${mainBG})` }} className="h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between">
            <div></div>
            <div style={{ background: `url(${ellipseBG})` }} className="min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative">
                <div className={`relative ${isSmallScreen ? 'pt-12' : 'pt-20'} w-full max-w-[36rem] flex flex-col justify-between h-full justify-self-center text-center pb-4`}>
                    <div>
                        <h1 className="font-semibold text-white text-sm">
                            {`Wie möchtest Du aussehen?`}<br />{`Du kannst unten ein Avatar für Dich`}<br />{`aussuchen!`}
                        </h1>

                        <div className="grid grid-cols-3 gap-4 mt-6">
                            {avatars.map((avatar) => (
                                <div className="flex flex-col items-center relative" key={avatar.id}>
                                    <label htmlFor={avatar.id}>
                                        <img src={avatar.src} className="w-[60px] object-cover h-[90px]" alt={avatar.id} />
                                    </label>
                                    <input
                                        type="radio"
                                        className="custom-radio mt-2"
                                        id={avatar.id}
                                        value={avatar.id}
                                        name="avatars"
                                        checked={formik.values.selectedAvatar == avatar.id}
                                        onChange={() => formik.setFieldValue('selectedAvatar', avatar.id)}
                                        style={{ display: 'none' }}
                                    />
                                    <div className="mt-2 cursor-pointer" onClick={() => formik.setFieldValue('selectedAvatar', avatar.id)}>
                                        {formik.values.selectedAvatar == avatar.id ? (
                                            <AvatarCheckedIcon />
                                        ) : (
                                            <AvatarCheckboxIcon />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                        {formik.values.selectedAvatar && (
                            <input
                                type="text"
                                id="nickname"
                                className="simurg_input mt-2 max-w-[350px] mx-auto z-10"
                                placeholder="Spitzname"
                                value={formik.values.nickname || ""}
                                onChange={(e) => formik.setFieldValue("nickname", e.target.value)}
                                onBlur={() => formik.setFieldTouched("nickname", true)} // Ensure nickname validation works on blur
                            />
                        )}
                        {/* {formik.errors.selectedAvatar && (
                            <div className="text-red-500 text-sm">
                                {formik.errors.selectedAvatar}
                            </div>
                        )} */}
                        {(!formik.values.selectedAvatar || !formik.values.nickname) && (
                            <div className="text-red-500  text-sm">
                                {`Bitte wähle auch einen Nicknamen`}
                            </div>
                        )}
                        {/* {formik.errors.nickname && (
                            <div className="text-red-500 text-sm">
                                {formik.errors.nickname}
                            </div>
                        )} */}
                    </div>

                    <div className={`${isSmallScreen ? 'pt-2' : 'pt-4'} flex gap-6`}>
                        <button className="w-full button_primary" onClick={() => setRenderForm(12)} >Zurück</button>
                        <button className="w-full button_secodry relative" onClick={() => formik.handleSubmit()} disabled={loading} >

                            {loading && (
                                <Spiner />
                            )}
                            <span className={loading ? "opacity-0" : ""}>Weiter gehts</span></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CharacterSelect;
