import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import mainBG from '../../../assets/EinführungMainBG.png';
import ellipseBG from '../../../assets/EllipseBG.png';

const TextScreenLast = ({ setRenderForm }) => {
    const isSmallScreen = window.innerHeight <= 600;

    const validationSchema = Yup.object({
        proudOf: Yup.string().required('Bitte fülle dieses Feld aus.'),
        enjoyBeingWith: Yup.string().required('Bitte fülle dieses Feld aus.'),
        sourceOfStrength: Yup.string().required('Bitte fülle dieses Feld aus.'),
        makesYouHappy: Yup.string().required('Bitte fülle dieses Feld aus.'),
        goalsInFiveYears: Yup.string().required('Bitte fülle dieses Feld aus.'),
    });

    const formik = useFormik({
        initialValues: {
            proudOf: '',
            enjoyBeingWith: '',
            sourceOfStrength: '',
            makesYouHappy: '',
            goalsInFiveYears: '',
        },
        // validationSchema,
        onSubmit: (values) => {
            console.log('Form Submitted:', values);
            setRenderForm(6);
        },
    });

    return (
        <div style={{ background: `url(${mainBG})` }} className='h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between'>
            <div></div>
            <div style={{ background: `url(${ellipseBG})` }} className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'>
                <div className={`relative pt-20 w-full ${isSmallScreen ? 'max-w-[32rem]' : 'max-w-[35rem]'} flex flex-col justify-between h-full justify-self-center text-center pb-4`}>
                    <div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className='grid grid-cols-2 gap-8 mt-2'>
                                <div>
                                    <div className='flex flex-col items-start mb-4'>
                                        <label className={`font-semibold mb-2 ${isSmallScreen ? 'text-xs' : 'text-sm'} text-white text-left`}>{`Worauf bist Du besonders stolz?`}</label>
                                        <input
                                            type='text'
                                            className='simurg_input'
                                            name="proudOf"
                                            value={formik.values.proudOf}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.proudOf && formik.errors.proudOf && (
                                            <p className="text-[11px] text-red-500 mt-1">{formik.errors.proudOf}</p>
                                        )}
                                    </div>
                                    <div className='flex flex-col items-start mb-4'>
                                        <label className={`font-semibold mb-2 ${isSmallScreen ? 'text-xs' : 'text-sm'} text-white text-left`}>{`Mit welchen Menschen bist Du gerne zusammen?`}</label>
                                        <input
                                            type='text'
                                            className='simurg_input'
                                            name="enjoyBeingWith"
                                            value={formik.values.enjoyBeingWith}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.enjoyBeingWith && formik.errors.enjoyBeingWith && (
                                            <p className="text-[11px] text-red-500 mt-1">{formik.errors.enjoyBeingWith}</p>
                                        )}
                                    </div>
                                    <div className='flex flex-col items-start mb-4'>
                                        <label className={`font-semibold mb-2 ${isSmallScreen ? 'text-xs' : 'text-sm'} text-white text-left`}>{`Was gibt Dir Kraft?`}</label>
                                        <input
                                            type='text'
                                            className='simurg_input'
                                            name="sourceOfStrength"
                                            value={formik.values.sourceOfStrength}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.sourceOfStrength && formik.errors.sourceOfStrength && (
                                            <p className="text-[11px] text-red-500 mt-1">{formik.errors.sourceOfStrength}</p>
                                        )}
                                    </div>
                                </div>

                                <div>
                                    <div className='flex flex-col items-start mb-4'>
                                        <label className={`font-semibold mb-2 ${isSmallScreen ? 'text-xs' : 'text-sm'} text-white text-left`}>{`Was macht Dich glücklich?`}</label>
                                        <input
                                            type='text'
                                            className='simurg_input'
                                            name="makesYouHappy"
                                            value={formik.values.makesYouHappy}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.makesYouHappy && formik.errors.makesYouHappy && (
                                            <p className="text-[11px] text-red-500 mt-1">{formik.errors.makesYouHappy}</p>
                                        )}
                                    </div>
                                    <div className='flex flex-col items-start mb-4'>
                                        <label className={`font-semibold mb-2 ${isSmallScreen ? 'text-xs' : 'text-sm'} text-white text-left`}>{`Was möchtest Du in 5 Jahren erreicht haben?`}</label>
                                        <input
                                            type='text'
                                            className='simurg_input'
                                            name="goalsInFiveYears"
                                            value={formik.values.goalsInFiveYears}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.goalsInFiveYears && formik.errors.goalsInFiveYears && (
                                            <p className="text-[11px] text-red-500 mt-1">{formik.errors.goalsInFiveYears}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className={`${isSmallScreen ? 'pt-2' : 'pt-4'} flex gap-6`}>
                        <button
                            type="button"
                            className='w-full button_primary'
                            onClick={() => setRenderForm(4)}
                        >
                            Zurück
                        </button>
                        <button
                            type="button"
                            className='w-full button_secodry'
                            onClick={formik.handleSubmit}
                        >
                            Weiter gehts
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TextScreenLast;