import axios from 'axios';

// const API_URL = process.env.NEXT_PUBLIC_BASE_URL;
const API_URL = 'https://simurg-backend.synceev.com/api/v1';
// const API_URL = 'http://192.168.100.133:8000/api/v1';

export const axiosApi = axios.create({
  baseURL: API_URL,
});

export async function get(url, config = {}) {
  return axiosApi.get(url, {...config}).then(response => {
    return response.data;
  });
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, {...data}, {...config})
    .then(response => response.data);
}

export async function postFormData(url, data, config = {}) {
  return axiosApi.post(url, data, {...config}).then(response => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, {...data}, {...config})
    .then(response => response.data);
}

export async function putFormData(url, data, config = {}) {
  return axiosApi.put(url, data, {...config}).then(response => response.data);
}

export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, {...data}, {...config})
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, {...config})
    .then(response => response.data);
}
