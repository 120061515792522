import React from 'react'
import mainBG from '../../../assets/EinführungMainBG.png'
import ellipseBG from '../../../assets/EllipseBG.png'
import { BookIcon, ForumIcon, VolunteerIcon } from '../../../utils/icons'

const Goals = ({ nextClick, backClick }) => {
    const isSmallScreen = window.innerHeight <= 600;

    return (
        <>
            <div style={{ background: `url(${mainBG})` }} className='h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between'>
                <div className='font-bold text-base text-[#0F32C0E5] self-center italic py-8'>Ziel unseres Online-Programms</div>
                <div style={{ background: `url(${ellipseBG})` }} className={`min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative`}>
                    <div className={`relative ${isSmallScreen ? 'pt-12' : 'pt-20'} w-full max-w-xs flex flex-col justify-between h-full justify-self-center text-center pb-4`}>
                        <div>

                            <div className="flex items-center text-center mb-8 justify-between">
                                <VolunteerIcon />
                                <p className={`text-white font-normal ${isSmallScreen ? 'text-sm' : 'text-base'} ${isSmallScreen ? 'max-w-64' : 'max-w-56'}`}>
                                    {`“My Simurg Guide” kann  Jugendliche in Österreich in Stresssituationen begleiten.`}
                                </p>
                            </div>

                            <div className="flex items-center text-center mb-8 justify-between">
                                <BookIcon />
                                <p className={`text-white font-normal ${isSmallScreen ? 'text-sm' : 'text-base'} ${isSmallScreen ? 'max-w-64' : 'max-w-56'}`}>
                                    {`Mithilfe von unterschiedlichen Methoden kann dieses Programm Dich unterstützen!`}
                                </p>
                            </div>

                            <div className="flex items-center text-center mb-8 justify-between">
                                <ForumIcon />
                                <p className={`text-white font-normal ${isSmallScreen ? 'text-sm' : 'text-base'} ${isSmallScreen ? 'max-w-64' : 'max-w-56'}`}>
                                    {`Für eine optimale Unterstützung wird Dir ein/e Berater:in zur Verfügung stehen.`}
                                </p>
                            </div>

                        </div>

                        <div className={`${isSmallScreen ? 'pt-2' : 'pt-4'} flex gap-2`}>
                            <button className='w-full button_main !border-transparent' onClick={nextClick} >Lass uns anfangen</button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Goals