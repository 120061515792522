import {createSlice} from '@reduxjs/toolkit';

const UserAuthSlice = createSlice({
  name: 'user',
  initialState: {
    userID: '',
  },
  reducers: {
    setUserID: (state, action) => {
      state.userID = action.payload;
    },
  },
});

export const {setUserID} = UserAuthSlice.actions;

export default UserAuthSlice.reducer;
