import React from 'react';
import Counting from '../../../assets/counting.svg';
import ScalarValue2 from '../../../assets/scalarValue2.svg';
import ScalarValue from '../../../assets/scalarValue.svg';

export const sliderConfig = [
  {
    name: 'stressRating',
    labelTitle: 'Stress-Ratingskala',
    labelDescription:
      'Wie sehr fühlst Du Dich heute gestresst? (0= gar nicht bis 10= sehr stark)',
    minValue: 0,
    maxValue: 10,
    scalarTypes: ScalarValue2,
  },
  {
    name: 'stressTolerance',
    labelTitle: 'Stresstoleranz-Ratingskala',
    labelDescription:
      'Wie ist heute Deine Stresstoleranz (=Fähigkeit, mit Stress umzugehen, bzw. Stress auszuhalten)',
    minValue: 0,
    maxValue: 10,
    scalarTypes: ScalarValue,
  },
];

const ScalarForm = ({ formik }) => {
  return (
    <div className="flex flex-col gap-5 items-start max-w-[370px] mx-auto -mt-6">
      <p className="text-[16px] font-[500] text-white text-center">
        Zu Beginn möchten wir Dich bitten, folgende Skalen anzukreuzen!
      </p>

      {sliderConfig.map(slider => (
        <div key={slider.name} className="flex flex-col gap-3 items-start w-full">
          <div>
            <p className="text-[16px] font-bold   text-white text-center">
              {slider.labelTitle}
            </p>
            <p className="text-[16px] font-medium text-white text-center">
              {slider.labelDescription}
            </p>
          </div>

          <div className="relative w-full">
            <div className="absolute top-0 left-5 w-full h-8">
              <img src={Counting} alt="" />
            </div>
            <input
              type="range"
              min={slider.minValue}
              max={slider.maxValue}
              step={1}
              value={formik.values[slider.name]}
              onChange={e => formik.setFieldValue(slider.name, Number(e.target.value))}
              className="absolute w-[90%] mx-auto h-1 top-[23px] left-[50%] translate-x-[-50%] bg-gray-300 focus:outline-none custom-range-slider"
            />
            <div className="mt-4">
              <img src={slider.scalarTypes} alt="" /> </div>
          </div>

          {formik.touched[slider.name] && formik.errors[slider.name] && (
            <p className="text-sm text-red-500 text-left">
              {formik.errors[slider.name]}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default ScalarForm;
