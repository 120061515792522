export const levelOneMcqs_options = [{ value: 'Ja', label: 'Ja' }];
export const levelOneMcqs = [
    {
        question: 'Familie',
        keyName: 'family',
    },
    {
        question: 'Sportliche Aktivitäten',
        keyName: 'sportsActivities',
    },
    {
        question: 'Freunde',
        keyName: 'friends',
    },
    {
        question: 'Haustiere',
        keyName: 'pets',
    },
    {
        question: 'Religion',
        keyName: 'religion',
    },
    {
        question: 'Anderes',
        keyName: 'other',
    },
];