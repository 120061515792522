import React from 'react'
import mainBG from '../../../assets/EinführungMainBG.png'
import ellipseBG from '../../../assets/EllipseBG.png'

const FirstForm = ({ nextClick, backClick }) => {

    return (
        <>
            <div style={{ background: `url(${mainBG})` }} className='h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between'>
                <div></div>
                <div style={{ background: `url(${ellipseBG})` }} className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'>
                    <div className='relative py-32 w-full max-w-[36rem] flex flex-col justify-between h-full justify-self-center text-center pb-4'>
                        <h1 className='font-normal text-lg text-[#fff]'>{`“My Simurg Guide” kann Dich in Deinem täglichen Alltag begleiten und unterstützen, mit Stresssituationen noch besser umgehen zu können. Somit muss niemand mit seinen Problemen alleine bleiben - starte noch heute Deine Reise mit “My Simurg Guide”`}</h1>
                        <div className='pt-4 flex gap-6'>
                            <button className='w-full button_primary' onClick={backClick} >Zurück</button>
                            <button className='w-full button_secodry' onClick={nextClick} >Weiter gehts</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FirstForm