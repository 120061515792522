import React from 'react';
import { useFormik } from 'formik';
import mainBG from '../../../assets/EinführungMainBG.png';
import ellipseBG from '../../../assets/EllipseBG.png';

const PlaylistScreen = ({ setRenderForm }) => {
    const isSmallScreen = window.innerHeight <= 600;

    const formik = useFormik({
        initialValues: {
            sad: '',
            angry: '',
            fearful: '',
            happy: '',
        },
        onSubmit: (values) => {
            setRenderForm(8);
        },
    });

    return (
        <div
            style={{ background: `url(${mainBG})` }}
            className="h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between"
        >
            <div></div>
            <div
                style={{ background: `url(${ellipseBG})` }}
                className="min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative"
            >
                <div className="relative h-full mx-auto w-full max-w-[35%] text-center pb-4">
                    <div className='h-full'>
                        <form className='flex flex-col justify-between h-full pt-28 items-center' onSubmit={formik.handleSubmit}>
                            <div className="w-[80%] mx-auto">
                                <h1
                                    className={`text-white text-center font-bold ${isSmallScreen ? 'text-xl' : 'text-2xl'
                                        } italic`}
                                >
                                    {`Musik für die Seele ->`}
                                    <br />
                                    {`Erstelle hier Deine Playlist`}
                                </h1>
                                <div className="mt-6">
                                    <h1
                                        className={`text-left text-white font-medium ${isSmallScreen ? 'text-sm' : 'text-base'
                                            } `}
                                    >
                                        {`Willst Du eine Playlist hochladen?`}
                                    </h1>
                                    <div>
                                        {[
                                            { label: 'Mein Lied, wenn ich traurig bin!', name: 'sad' },
                                            { label: 'Mein Lied, wenn ich verärgert bin!', name: 'angry' },
                                            { label: 'Mein Lied, wenn ich ängstlich bin!', name: 'fearful' },
                                            { label: 'Mein Lied, wenn ich glücklich bin!', name: 'happy' },
                                        ].map(({ label, name }) => (
                                            <div key={name} className="flex items-center justify-between gap-2 py-2">
                                                <label
                                                    htmlFor={name}
                                                    className={`font-medium ${isSmallScreen ? 'text-sm' : 'text-base'
                                                        } text-white text-left`}
                                                >
                                                    {label}
                                                </label>
                                                <input
                                                    type="text"
                                                    id={name}
                                                    name={name}
                                                    value={formik.values[name]}
                                                    onChange={formik.handleChange}
                                                    className="simurg_input"
                                                    style={{ width: "6rem" }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className={`${isSmallScreen ? 'pt-2' : 'pt-4'} flex gap-6 w-full`}>
                                <button
                                    type="button"
                                    className="w-full button_primary"
                                    onClick={() => setRenderForm(6)}
                                >
                                    Zurück
                                </button>
                                <button
                                    type="submit"
                                    className="w-full button_secodry"
                                >
                                    Weiter gehts
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlaylistScreen;
