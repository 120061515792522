import * as Yup from 'yup';
import { sliderConfig } from '../../common/forms/ScalarForm';
import { firstSevenQuestions, lastSevenQuestions } from '../../common/constant/data';

export const stressOptions = [
  {
    question: `Fotos von Freunden/Angehörigen`,
  },
  {
    question: `Fotos vom Urlaub, Geburtstagsparty etc.`,
  },
  {
    question: `Fotos vom Haustier`,
  },
  {
    question: `Gebete/Zitate `,
  },
  {
    question: `Lieblings CD, Video`,
  },
  {
    question: `Lieblingsbuch/Lieblingsroman`,
  },
  {
    question: `Selbst gemalte Bilder`,
  },
  {
    question: `Kopien von guten Zeugnissen`,
  },
  {
    question: `Pokale oder Auszeichnungen`,
  },
  {
    question: `andere Gründe:`,
  },
];

export const List = [
  {
    title: `etwas Nettes für Deine Freunde/Familie/Bekannte tun`,
    url: 'https://drive.google.com/file/d/1PwzYItg2cBnFlQcD0cgAV7gi7pLKKDy1/view',
  },
  {
    title: `Dich motivieren, aktiver zu werden`,
    url: 'https://drive.google.com/file/d/1of8tJg7iCUmlDoSmnYygwqZPNzW_DArm/view',
  },
  {
    title: `versuchen, Deine Gefühle zu verändern`,
    url: 'https://drive.google.com/file/d/1Xnzac5Sr0QkLFOrE48Loab1Zels1xBrK/view',
  },
  {
    title: `versuchen, Deine Gedanken zu ersetzen`,
    url: 'https://drive.google.com/file/d/1-hrcqG3miObDYmrSg3r_Z--A8FKxN6Gh/view',
  },
  {
    title: `versuchen, die schwierige Situation in Deinen Gedanken zu verlassen`,
    url: 'https://drive.google.com/file/d/1uxPXgovWGIM6zKDl1rUOqjgODoIPs38n/view',
  },
];

export const questionsList = [
  { question: 'Ich bin eigentlich jemand, der kämpft.' },
  {
    question:
      'Ich glaube, dass ich lernen kann, mit meinen Problemen umzugehen.',
  },
  {
    question:
      ' Ich glaube, dass ich für meine Probleme passende Lösungen finden kann, wenn ich sie suche.',
  },
  { question: 'Es gibt für alles mindestens eine Lösung.' },
  { question: 'Auf und Abs“ gehören zum Leben.' },
  { question: 'Auch Stresssituationen gehen vorüber!' },
  { question: 'Andere Gründe' },
];

export const getValidationSchema = renderForm => {
  // console.log('renderForm=========>>>>>>>>', {renderForm});
  if (renderForm === 0) {
    return Yup.object(
      sliderConfig.reduce((schema, slider) => {
        schema[slider.name] = Yup.number()
          .required('Dieses Feld ist erforderlich.')
          .min(slider.minValue, `Der Mindestwert beträgt ${slider.minValue}.`)
          .max(slider.maxValue, `Der Höchstwert beträgt ${slider.maxValue}.`);
        return schema;
      }, {}),
    );
  } else if (renderForm === 1) {
    return Yup.object(
      firstSevenQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          'Bitte wähle eine Option.',
        );
        return schema;
      }, {}),
    );
  } else if (renderForm === 2) {
    return Yup.object(
      lastSevenQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          'Bitte wähle eine Option.',
        );
        return schema;
      }, {}),
    );
  } else if (renderForm === 4) {
    return Yup.object().shape({
      strengths: Yup.array()
        .of(
          Yup.object().shape({
            branch: Yup.string().required('Zweig ist erforderlich'), // Ensures each branch is required
            value: Yup.string()
              .nullable()
              .transform(value =>
                value === null || value === undefined ? '' : value,
              ) // Convert null/undefined to empty string
              .notRequired(), // Field is not required individually
          }),
        )
        .test(
          'at-least-one',
          'Mindestens ein Wert für den Zweig ist erforderlich.',
          strengths => {
            // Make sure that strengths is an array and at least one value is non-empty
            return (
              Array.isArray(strengths) &&
              strengths.some(item => item.value?.trim() !== '')
            );
          },
        ),
    });
  } else if (renderForm === 5) {
    return Yup.object({
      moodCard: Yup.string().required('Bitte wähle ein Bild aus'),
      drawing: Yup.string().required('Bitte zeichne etwas und speichere es'),
    });
  } else if (renderForm === 6) {
    return Yup.object({
      expressFeelings: Yup.array()
        .of(Yup.string().required('Dieses Feld ist erforderlich'))
        .min(3, 'Alle Felder müssen ausgefüllt sein'), // Ensure all 3 inputs are filled
    });
  }

  // Add other cases as needed
  return Yup.object(); // Default empty schema
};

