import React, { useState, useEffect } from "react";

const EmojiDragDrop = ({ formik }) => {
    const initialSentences = [
        { id: "Animals", text: "Ich liebe Tiere!", emoji: null },
        { id: "Sporty", text: "Ich bin sportlich!", emoji: null },
        { id: "Imaginative", text: "Ich habe viel Fantasie!", emoji: null },
        { id: "helpsOthers", text: "Ich helfe anderen Menschen gerne!", emoji: null },
        { id: "Music", text: "Ich liebe Musik und/oder musiziere!", emoji: null },
        { id: "Creative", text: "Ich bin kreativ!", emoji: null },
        { id: "Polite", text: "Ich bin höflich!", emoji: null },
        { id: "HelpsAtHome", text: "Ich helfe zu Hause viel mit!", emoji: null },
        { id: "GoodMemory", text: "Ich habe ein gutes Gedächtnis!", emoji: null },
        { id: "SelfCare", text: "Ich achte auf meine Bedürfnisse!", emoji: null },
        { id: "LogicalThinking", text: "Ich bin gut im logischen Denken!", emoji: null },
        { id: "AdaptsToNewSituations", text: "Ich kann gut mit neuen Situationen umgehen!", emoji: null },
        { id: "AttentiveInClass", text: "Im Unterricht bin ich sehr aufmerksam!", emoji: null },
    ];

    const [sentences, setSentences] = useState(
        formik.values.loves?.length > 0
            ? initialSentences.map((sentence) => {
                const matched = formik.values.loves.find((item) => item.sentence === sentence.id);
                return matched ? { ...sentence, emoji: { symbol: matched.symbol, id: matched.mood } } : sentence;
            })
            : initialSentences
    );

    useEffect(() => {
        const formattedSentences = sentences
            .filter((sentence) => sentence.emoji !== null) // Only include sentences with emojis
            .map((sentence) => ({
                sentence: sentence.id, // Use the `id` as the sentence identifier
                symbol: sentence.emoji.symbol, // Emoji symbol
                mood: sentence.emoji.id, // Emoji id (smile, upset, sad)
            }));

        formik.setFieldValue("loves", formattedSentences);
    }, [sentences]);

    const emojis = [
        { id: "smile", symbol: "😊" },
        { id: "upset", symbol: "😐" },
        { id: "sad", symbol: "😟" },
    ];

    const handleDragStart = (e, emoji) => {
        e.dataTransfer.setData("emoji", JSON.stringify(emoji));
    };

    const handleDrop = (e, sentenceId) => {
        const emoji = JSON.parse(e.dataTransfer.getData("emoji"));
        setSentences((prev) =>
            prev.map((sentence) =>
                sentence.id === sentenceId ? { ...sentence, emoji } : sentence
            )
        );
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    return (
        <div className="p-2 items-center -mt-8">
            <h2 className="text-[14px] font-[500] text-white mb-2 text-center">
                Wähle das <span className="text-yellow-500">😊</span>, wenn die Aussage ganz auf Dich zutrifft, <br />
                wähle das <span className="text-gray-500">😐</span> wenn die Aussage teilweise zutrifft und <br />
                und wähle das <span className="text-red-500">😟</span> wenn die Aussage gar nicht auf Dich zutrifft!
            </h2>

            <div className="flex items-center justify-center gap-4 mb-2">
                {emojis.map((emoji) => (
                    <div
                        key={emoji.id}
                        draggable
                        onDragStart={(e) => handleDragStart(e, emoji)}
                        className="text-[24px] cursor-pointer"
                    >
                        {emoji.symbol}
                    </div>
                ))}
            </div>

            <div className="max-h-[50vh] overflow-auto pr-3 no-scrollbar">
                {sentences.map((sentence) => (
                    <div
                        key={sentence.id}
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, sentence.id)}
                        className="flex justify-between w-full py-1"
                    >
                        <span className="text-[12px] text-white">{sentence.text}</span>
                        <div
                            className={`w-2 h-2 flex justify-center items-center border rounded-lg ${sentence.emoji ? "bg-transparent" : "bg-transparent"
                                }`}
                        >
                            {sentence.emoji ? sentence.emoji.symbol : "➕"}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EmojiDragDrop;
