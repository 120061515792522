import React from 'react';
import { inputQuestion } from '../constant/data';




const ProfileFormSecondhalf = ({ formik }) => {
  return (
    <div className="grid grid-cols-2 gap-3 mt-2">
      {inputQuestion?.slice(5)?.map((question, index) => (
        <div key={`${question.keyName}-${index}`} className="w-full mb-2 text-left">
          <label htmlFor={question.keyName} className={`font-normal text-[14px] text-white italic text-left my-2`}>
            {question.question}
          </label>
          <input
            id={question.keyName}
            type="text"
            className="w-full simurg_input border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring focus:ring-indigo-500 focus:border-indigo-500"
            value={formik.values[question.keyName]}
            onChange={(e) => formik.setFieldValue(question.keyName, e.target.value)}
            onBlur={() => formik.setFieldTouched(question.keyName)}
          />
          {formik.touched[question.keyName] && formik.errors[question.keyName] && (
            <p className="mt-1 text-sm text-red-500">{formik.errors[question.keyName]}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProfileFormSecondhalf;
