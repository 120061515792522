import React from 'react'
import mainBG from '../../../assets/EinführungMainBG.png'
import ellipseBG from '../../../assets/EllipseBG.png'
import { useFormik } from 'formik';

const validate = values => {
    const errors = {};
    if (!values.fatherPlaceOfBirth) {
        errors.fatherPlaceOfBirth = `Father's place of birth is required`;
    }
    if (!values.motherPlaceOfBirth) {
        errors.motherPlaceOfBirth = `Mother's place of birth is required`;
    }
    if (!values.placeOfBirth) {
        errors.placeOfBirth = 'Place Of Birth is required';
    }
    return errors;
};

const InputForm = ({ nextClick, backClick, formik }) => {

    return (
        <div style={{ background: `url(${mainBG})` }} className='h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between'>
            <div></div>
            <div style={{ background: `url(${ellipseBG})` }} className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'>
                <div className='relative h-full mx-auto w-full max-w-[36rem] text-center pb-4'>
                    <div className='h-full'>
                        <div className='flex flex-col justify-between h-full pt-20 items-center'>
                            <div className='w-[70%] mx-auto'>
                                <div className='flex flex-col items-start mb-6'>
                                    <label className='font-semibold text-base text-white mb-3'>Geburtsland Deines Vaters</label>
                                    <input type='text' name="fatherPlaceOfBirth" className='simurg_input' value={formik.values.fatherPlaceOfBirth} onChange={formik.handleChange} onBlur={formik.onBlur} />
                                    {formik.touched.fatherPlaceOfBirth && formik.errors.fatherPlaceOfBirth && (
                                        <div className="text-red-500 text-sm mt-1 text-left">{formik.errors.fatherPlaceOfBirth}</div>
                                    )}
                                </div>
                                <div className='flex flex-col items-start mb-6'>
                                    <label className='font-semibold text-base text-white mb-3'>Geburtsland Deiner Mutter</label>
                                    <input type='text' name='motherPlaceOfBirth' className='simurg_input' value={formik.values.motherPlaceOfBirth} onChange={formik.handleChange} onBlur={formik.onBlur} />
                                    {formik.touched.motherPlaceOfBirth && formik.errors.motherPlaceOfBirth && (
                                        <div className="text-red-500 text-sm mt-1 text-left">{formik.errors.motherPlaceOfBirth}</div>
                                    )}
                                </div>
                                <div className='flex flex-col items-start mb-6'>
                                    <label className='font-semibold text-base text-white mb-3'>Dein Geburtsland</label>
                                    <input type='text' name='placeOfBirth' className='simurg_input' value={formik.values.placeOfBirth} onChange={formik.handleChange} onBlur={formik.onBlur} />
                                    {formik.touched.placeOfBirth && formik.errors.placeOfBirth && (
                                        <div className="text-red-500 text-sm mt-1 text-left">{formik.errors.placeOfBirth}</div>
                                    )}
                                </div>
                            </div>
                            <div className='pt-8 flex gap-6 w-full'>
                                <button className='w-full button_primary' onClick={backClick} >Zurück</button>
                                <button className='w-full button_secodry' type="submit" onClick={nextClick} >Weiter gehts</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InputForm