import React from 'react'
import mainBG from '../../../assets/EinführungMainBG.png'
import ellipseBG from '../../../assets/EllipseBG.png'

const SimrugGuide = ({ nextClick, backClick }) => {
    return (
        <>
            <div style={{ background: `url(${mainBG})` }} className='h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between'>
                <div className='font-bold text-base text-[#0F32C0E5] self-center italic py-8 text-center max-w-[380px]'>{`Herzlich Willkommen zum Online-Programm „My Simurg Guide“`}</div>
                <div style={{ background: `url(${ellipseBG})` }} className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'>
                    <div className='relative py-32 w-full max-w-[36rem] flex flex-col justify-between h-full justify-self-center text-center pb-4'>
                        <div>
                            <div class="bordered-text">
                                Discover the power in you!
                            </div>
                            <p className='text-center font-semibold text-white text-lg leading-9'>{`Bitte fülle das folgende Formular aus, damit wir wissen, ob “My Simurg Guide” für Dich geeignet ist.`}</p>
                        </div>

                        <div className='pt-4 flex gap-6'>
                            <button className='w-full button_primary' onClick={backClick} >Zurück</button>
                            <button className='w-full button_secodry' onClick={nextClick} >Weiter gehts</button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default SimrugGuide