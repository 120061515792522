import React from 'react';
import { useFormik } from 'formik';
import mainBG from '../../../assets/EinführungMainBG.png';
import ellipseBG from '../../../assets/EllipseBG.png';
import { MultiOptions } from '../../../constants/data';

const MultiOption = ({ nextClick, backClick, formik }) => {
  const isSmallScreen = window.innerHeight <= 600;

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const { MultiOptions } = formik.values;
    console.log('data in multi', MultiOptions, value);
    if (checked) {
      formik.setFieldValue('MultiOptions', [...MultiOptions, value]);
    } else {
      formik.setFieldValue(
        'MultiOptions',
        MultiOptions.filter((option) => option !== value)
      );
    }
  };
  return (
    <div
      style={{ background: `url(${mainBG})` }}
      className='h-screen overflow-auto !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between'
    >
      <div></div>
      <div
        style={{ background: `url(${ellipseBG})` }}
        className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'
      >
        <div className='relative h-full mx-auto w-full max-w-[36rem] text-center pb-4'>
          <div className='h-full'>
            <div className='flex flex-col justify-between h-full pt-20 items-center'>
              <div className='flex flex-col items-start max-w-[80%] mx-auto'>
                <p
                  className={`font-semibold ${
                    isSmallScreen ? 'text-base' : 'text-xl'
                  } text-white text-left`}
                >
                  {`Wenn ja, welche? Mehrere Antworten sind möglich.`}
                </p>
                <div className={`${isSmallScreen ? 'my-0' : 'mt-4'}`}>
                  {MultiOptions.map((option, index) => (
                    <div className='flex items-center gap-4' key={index}>
                      <input
                        type='checkbox'
                        id={option.title}
                        name='MultiOptions'
                        value={option.value}
                        className='radio-circle'
                        onChange={handleCheckboxChange}
                        checked={formik?.values?.MultiOptions?.includes(
                          option.value
                        )}
                      />
                      <label
                        htmlFor={option.title}
                        className={`font-semibold ${
                          isSmallScreen ? 'text-base' : 'text-xl'
                        } text-white`}
                      >
                        {option.title}
                      </label>
                      {option.value === 'Other' && (
                        <input
                          type='text'
                          className='simurg_input !w-auto !text-white !h-auto !shadow-none !border-b-2 !bg-transparent !rounded-none !p-0'
                          name='andereText'
                          value={formik.values.andereText}
                          onChange={formik.handleChange}
                          placeholder='Bitte spezifizieren'
                        />
                      )}
                    </div>
                  ))}
                </div>
                {formik.errors.MultiOptions && (
                  <div className='text-red-500 text-sm mt-2'>
                    {formik.errors.MultiOptions}
                  </div>
                )}
                {formik.errors.andereText && (
                  <div className='text-red-500 text-sm mt-2'>
                    {formik.errors.andereText}
                  </div>
                )}
              </div>

              <div
                className={`${
                  isSmallScreen ? 'pt-2' : 'pt-6'
                } flex gap-6 w-full`}
              >
                <button
                  type='button'
                  className='w-full button_primary'
                  onClick={backClick}
                >
                  Zurück
                </button>
                <button
                  type='submit'
                  className='w-full button_secodry'
                  onClick={nextClick}
                >
                  Weiter gehts
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiOption;
