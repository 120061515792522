import React, { useState, useEffect } from 'react';
import balloonGreen from "../../../assets/baloonGreen.png";
import balloonBlue from "../../../assets/baloonBlue.png";
import balloonOrange from "../../../assets/baloonOrange.png";
import balloonPurple from "../../../assets/baloonPurple.png";
import balloonRed from "../../../assets/baloonRed.png";
import balloonYellow from "../../../assets/baloonYellow.png";

const balloonsData = [
    { id: 1, color: 'green', img: balloonGreen, top: '0', left: '35%', isVisible: true, text: '' },
    { id: 2, color: 'blue', img: balloonBlue, top: '5%', right: '22%', isVisible: true, text: '' },
    { id: 3, color: 'orange', img: balloonOrange, top: '20%', left: '20%', isVisible: true, text: '' },
    { id: 4, color: 'purple', img: balloonPurple, top: '34%', right: '33%', isVisible: true, text: '' },
    { id: 5, color: 'red', img: balloonRed, top: '40%', left: '6%', isVisible: true, text: '' },
    { id: 6, color: 'yellow', img: balloonYellow, bottom: '0%', right: '7%', isVisible: true, text: '' },
];

const BallonScreen = ({ formik }) => {
    const [balloons, setBalloons] = useState(balloonsData);
    const [popup, setPopup] = useState(null);
    const [isDeleting, setIsDeleting] = useState(null);

    const handleInputChange = (id, value) => {
        setBalloons((prev) =>
            prev.map((b) =>
                b.id == id ? { ...b, text: value } : b
            )
        );

        // Clear any previous timeout
        if (window.balloonsTimeouts && window.balloonsTimeouts[id]) {
            clearTimeout(window.balloonsTimeouts[id]);
        }

        // Set a timeout to delete the balloon after 2 seconds of inactivity
        if (!window.balloonsTimeouts) window.balloonsTimeouts = {};
        if (value) {
            window.balloonsTimeouts[id] = setTimeout(() => {
                handleDelete(id);
            }, 2000);
        }
    };

    const handleDelete = (id) => {
        setIsDeleting(id);
        setTimeout(() => {
            setBalloons((prev) =>
                prev.map((b) =>
                    b.id == id ? { ...b, isVisible: false, text: '' } : b
                )
            );
            setIsDeleting(null);
        }, 500);
    };

    const handleView = (text) => {
        setPopup(text);
    };

    const closePopup = () => {
        setPopup(null);
    };

    const handleSubmit = () => {
        console.log("Final Balloon Data:", balloons);
    };

    useEffect(() => {
        formik.setFieldValue(
            'balloonsText',
            balloons.map(item => {
                return {
                    id: item.id,
                    text: item.text,
                    visible: item.isVisible,
                };
            }),
        );
    }, [balloons]);

    return (
        <>
            <div>
                <h1 className="font-bold text-white text-[13px] italic">
                    {`Hier siehst Du mehrere bunte Ballons, in die Du Probleme und Sorgen hineinschreiben kannst, die Dich belasten – dann kannst Du jeden Ballon öfters platzen lassen – mit dieser Methode ist es möglich, sich von Problemen zu distanzieren.`}
                </h1>
                <h1 className="font-bold text-white text-[13px] italic mt-4">
                    {`Probleme in den Ballon schreiben, dann Ballon verschwinden lassen!`}
                </h1>

                <div className='mt-4 bg-white w-[75%] mx-auto overflow-hidden'>
                    <div className="relative h-64">
                        {balloons.map((balloon) => (
                            balloon.isVisible && (
                                <div
                                    key={balloon.id}
                                    className={`absolute transition-all duration-500 ${isDeleting === balloon.id ? 'opacity-0 scale-0' : ''
                                        }`}
                                    style={{
                                        top: balloon.top,
                                        left: balloon.left,
                                        right: balloon.right,
                                        bottom: balloon.bottom,
                                    }}
                                >
                                    <img
                                        src={balloon.img}
                                        className="w-[80px] h-[180px]"
                                        alt={`${balloon.color} Balloon`}
                                    />

                                    <div className="relativex">
                                        <input
                                            type="text"
                                            className="absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[60px] bg-transparent border border-gray-300 text-center text-sm text-black rounded"
                                            maxLength={50}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    balloon.id,
                                                    e.target.value
                                                )
                                            }
                                            value={balloon.text || ''}
                                        />

                                        {/* {balloon.text && (
                                            <>
                                                <button
                                                    onClick={() =>
                                                        handleDelete(
                                                            balloon.id
                                                        )
                                                    }
                                                    className="absolute top-0 left-0 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs"
                                                >
                                                    ✖
                                                </button>

                                                <button
                                                    onClick={() =>
                                                        handleView(
                                                            balloon.text
                                                        )
                                                    }
                                                    className="absolute top-0 right-0 bg-blue-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs"
                                                >
                                                    👁
                                                </button>
                                            </>
                                        )} */}
                                    </div>
                                </div>
                            )
                        ))}
                    </div>
                </div>
            </div>

            {popup && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-4 rounded shadow-lg text-center">
                        <p className="text-black">{popup || 'No text'}</p>
                        <button
                            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                            onClick={closePopup}
                        >
                            Schließen
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default BallonScreen;
