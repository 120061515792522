import * as Yup from 'yup';
import { sliderConfig } from '../../common/forms/ScalarForm';
import { firstSevenQuestions, lastSevenQuestions, firstFiveInputQuestions, lastFiveInputQuestions } from '../../common/constant/data';


export const questions_options = [
  { label: 'immer', value: 'always' },
  { label: 'manchmal', value: 'sometimes' },
  { label: 'nie', value: 'NO' },
];

export const questions = [
  {
    question: 'Ich habe mich wohl gefühlt',

    keyName: 'comfortable',
  },
  {
    question: 'Ich habe mich entspannt gefühlt',

    keyName: 'relaxed',
  },
  {
    question: 'Ich habe ausreichend schlafen  können',

    keyName: 'sleepEnough',
  },
  {
    question: 'Ich habe ausreichend trinken  und essen können',

    keyName: 'enoughToDrinkAndEat',
  },
  {
    question: 'Ich habe mich gut konzentrieren können',

    keyName: 'concentrateWell',
  },
  {
    question: 'Ich habe ausreichend lernen können',

    keyName: 'learnEnough',
  },
  {
    question: 'Ich   habe auf Auszeiten und Pausen achten können',

    keyName: 'tookBreak',
  },
  {
    question: 'Ich war ruhig und gelassen',

    keyName: 'calmAndComposed',
  },
  {
    question: 'Ich   hatte Freude meinen Hobbies nachzugehen',

    keyName: 'enjoyedHobbies',
  },
  {
    question: ' Ich konnte rechtzeitig spüren, wenn mir etwas zu viel wurde',

    keyName: 'sensedOverload',
  },
  {
    question: 'Ich habe nicht mehr als sonst gegrübelt',

    keyName: 'didntBrood',
  },
  {
    question: 'Mir hat vieles Spaß gemacht',

    keyName: 'enjoyedLife',
  },
  {
    question:
      'Ich   habe es genossen, etwas mit   Freunden/Verwandten zu unternehmen',

    keyName: 'enjoyedSocializing',
  },
  {
    question:
      'Ich war zuversichtlich, dass ich meine Vorhaben gut schaffen kann',

    keyName: 'confidentAboutProject',
  },
];

export const inputQuestion = [
  {
    question:
      'Welche positiven Verhaltensweisen zeichnen Dich aus (z.B. Sport, gesunde Ernährung etc.)?',
    keyName: 'positiveBehaviors',
  },
  {
    question:
      'Welche schlechten Gewohnheiten hast Du (z.B. Rauchen, Schlafmangel etc.)?',
    keyName: 'badHabits',
  },
  {
    question: 'Was sind Deine Stärken?',
    keyName: 'strengths',
  },
  {
    question: 'Was sind Deine Schwächen?',
    keyName: 'weaknesses',
  },
  {
    question: 'Was sind Deine Hobbys?',
    keyName: 'hobbies',
  },

  {
    question: 'Worauf bist Du besonders stolz?',
    keyName: 'enjoyBeingWith',
  },
  {
    question: `Mit welchen Menschen bist Du gerne
    zusammen?`,
    keyName: 'proudOf',
  },
  {
    question: `Was gibt Dir Kraft?`,
    keyName: 'strength',
  },
  {
    question: `Was macht Dich glücklich?`,
    keyName: 'happiness',
  },
  {
    question: `Was möchtest Du in 5 Jahren erreicht
    haben?`,
    keyName: 'futureGoals',
  },
];

export const levelOneMcqs_options = [{ value: 'Ja', label: 'Ja' }];
export const levelOneMcqs = [
  {
    question: 'Familie',
    keyName: 'family',
  },
  {
    question: 'Sportliche Aktivitäten',
    keyName: 'sportsActivities',
  },
  {
    question: 'Freunde',
    keyName: 'friends',
  },
  {
    question: 'Haustiere',
    keyName: 'pets',
  },
  {
    question: 'Religion',
    keyName: 'religion',
  },
  {
    question: 'Anderes',
    keyName: 'other',
  },
];

export const DATA_Playlist = [
  {
    title: 'Willst Du eine Playlist hochladen?',
    data: [
      {
        question: '· Mein Lied, wenn ich traurig bin!',
        keyName: 'sadSong',
      },
      {
        question: '· Mein Lied, wenn ich verärgert bin!',
        keyName: 'upsetSong',
      },
      {
        question: '· Mein Lied, wenn ich ängstlich bin!',
        keyName: 'anxiousSong',
      },
      {
        question: '· Mein Lied, wenn ich glücklich bin!',
        keyName: 'happySong',
      },
    ],
  },
];

export const getValidationSchema = renderForm => {
  if (renderForm === 0) {
    return Yup.object(
      sliderConfig.reduce((schema, slider) => {
        schema[slider.name] = Yup.number()
          .required('Dieses Feld ist erforderlich.')
          .min(slider.minValue, `Der Mindestwert beträgt ${slider.minValue}.`)
          .max(slider.maxValue, `Der Höchstwert beträgt ${slider.maxValue}.`);
        return schema;
      }, {}),
    );
  } else if (renderForm === 1) {
    return Yup.object(
      firstSevenQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          'Bitte wähle eine Option.',
        );
        return schema;
      }, {}),
    );
  } else if (renderForm === 2) {
    return Yup.object(
      lastSevenQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          'Bitte wähle eine Option.',
        );
        return schema;
      }, {}),
    );
  } else if (renderForm === 3) {
    return Yup.object(
      firstFiveInputQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string();
        // .required(
        //   'Dieses Feld ist erforderlich.',
        // );
        return schema;
      }, {}),
    );
  } else if (renderForm === 4) {
    return Yup.object(
      lastFiveInputQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string();
        // .required(
        //   'Dieses Feld ist erforderlich.',
        // );
        return schema;
      }, {}),
    );
  } else if (renderForm === 5) {
    return Yup.object().shape({
      answers: Yup.array()
      // .of(
      //   Yup.object().shape({
      //     checked: Yup.boolean(),
      //     details: Yup.string().test(
      //       'is-checked-input-required',
      //       'Bitte fülle dieses Feld aus.', // This is what should be returned if invalid
      //       function (value) {
      //         const { checked } = this.parent;
      //         return !checked || (value && value.trim() !== '');
      //       },
      //     ),
      //   }),
      // )
      // .test(
      //   'at-least-one-checked',
      //   'Bitte wähle mindestens eine Option aus.', // Message if no checkbox is checked
      //   function (answers) {
      //     // Check if at least one object in the array has `checked: true`
      //     return answers && answers.some(answer => answer.checked);
      //   },
      // ),
    });
  } else if (renderForm === 6) {
    return Yup.object(
      DATA_Playlist[0].data.reduce((schema, item) => {
        schema[item.keyName] = Yup.string();
        // .required(
        //   'Dieses Feld ist erforderlich.',
        // );
        return schema;
      }, {}),
    );
  }

  // Add other cases as needed
  return Yup.object(); // Default empty schema
};


export const transformData = formikValues => {
  return {
    stressFeel: formikValues.stressFeel,
    stressTolerance: formikValues.stressTolerance,
    comfortable: formikValues.comfortable,
    relaxed: formikValues.relaxed,
    sleepEnough: formikValues.sleepEnough,
    enoughToDrinkAndEat: formikValues.enoughToDrinkAndEat,
    concentrateWell: formikValues.concentrateWell,
    learnEnough: formikValues.learnEnough,
    tookBreak: formikValues.tookBreak,
    calmAndComposed: formikValues.calmAndComposed,
    enjoyedHobbies: formikValues.enjoyedHobbies,
    sensedOverload: formikValues.sensedOverload,
    didntBrood: formikValues.didntBrood,
    enjoyedLife: formikValues.enjoyedLife,
    enjoyedSocializing: formikValues.enjoyedSocializing,
    confidentAboutProject: formikValues.confidentAboutProject,
    positiveBehaviors: formikValues.positiveBehaviors,
    badHabits: formikValues.badHabits,
    strengths: formikValues.strengths,
    weaknesses: formikValues.weaknesses,
    hobbies: formikValues.hobbies,
    enjoyBeingWith: formikValues.enjoyBeingWith,
    proudOf: formikValues.proudOf,
    sourceOfStrength: formikValues.happiness || '', // Adjusted for missing key
    sourceOfHappiness: formikValues.happiness || '',
    futureGoals: formikValues.futureGoals,
    supportOptions: {
      family: formikValues.answers?.[0],
      sportsActivities: formikValues.answers?.[1],
      friends: formikValues.answers?.[2],
      pets: formikValues.answers?.[3],
      religion: formikValues.answers?.[4],
      other: formikValues.answers?.[5],
    },
    musicPlaylist: {
      sadSong: formikValues.sadSong,
      upsetSong: formikValues.upsetSong,
      anxiousSong: formikValues.anxiousSong,
      happySong: formikValues.happySong,
    },
    balloonsText: formikValues.balloonsText,
  };
};
