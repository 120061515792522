import React from 'react';
import mainBG from '../../../assets/EinführungMainBG.png';
import ellipseBG from '../../../assets/EllipseBG.png';

const NeinAccept = ({ setRenderForm, index }) => {
  return (
    <>
      <div
        style={{ background: `url(${mainBG})` }}
        className='h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between'
      >
        <div></div>
        <div
          style={{ background: `url(${ellipseBG})` }}
          className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'
        >
          <div className='relative pt-20 w-full max-w-[36rem] flex flex-col justify-between h-full justify-self-center text-center pb-4'>
            <div>
              <h1 className='font-semibold text-white text-base'>{`Leider ist`}</h1>
              <h1 className='font-semibold text-white text-base'>{`My Simurg Guide“`}</h1>
              <h1 className='font-semibold text-white text-base'>{`für Dich nicht optimal geeignet!`}</h1>
              <h1 className='font-semibold text-white text-base mt-4'>{`Wenn es Dir momentan sehr schlecht geht, wende Dich bitte an die Krisennummern oder an die für Dich zuständige Klinik für Kinder- und Jugendpsychiatrie!`}</h1>
              <h1 className='font-semibold text-white text-base mt-4'>
                {`Hier findest Du die Kontaktdaten von `}
                <br />
                <a
                  target='_blank'
                  className='underline'
                  href='https://drive.google.com/file/d/1UE84Nl8reXnBONZLX5IrmQ8raL3iMk27/view'
                >{`Krisennummern!`}</a>
              </h1>
              <h1 className='font-semibold text-white text-base mt-4'>
                {`Hier findest Du die Kontaktdaten von `}
                <br />
                <a
                  target='_blank'
                  className='underline'
                  href='https://drive.google.com/file/d/1rdy2vCJ05FMjcMlSNS3Q6NvzL63bEPc7/view'
                >{`Kliniken!`}</a>
              </h1>
            </div>

            <div className='pt-10 flex gap-6'>
              <button
                type='button'
                className='w-full button_primary'
                onClick={() => setRenderForm(index)}
              >
                Zurück
              </button>
              <button type='submit' className='w-full button_secodry' disabled>
                Weiter gehts
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NeinAccept;
