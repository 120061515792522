import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/store';
import MainStack from './navigation';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import mainBG from './assets/EinführungMainBG.png'

function App() {

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={''} persistor={persistor}>
          <div style={{ background: `url(${mainBG})` }} className='h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between'>
            <MainStack />
          </div>
          <ToastContainer />
        </PersistGate>
      </Provider>

    </>
  );
}

export default App;
