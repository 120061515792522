import React, { useEffect, useRef, useState } from 'react';
import ellipseBG from '../../assets/EllipseBG.png'
import BatteryPer from '../../assets/batterypercentage.png'
import Battery from '../../assets/battery.png'
import Tree from '../../assets/tree.png'
import TreeTypes from '../../assets/treeTypes.png'
import Intial from './forms/intial'
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { getValidationSchema } from './constant';
import { useSelector, useDispatch } from 'react-redux';
import ScalarForm, { sliderConfig } from '../common/forms/ScalarForm';
import LevelRadioSelectionlastSeven from '../common/forms/LevelRadioSelectionlastSeven';
import { firstSevenQuestions, lastSevenQuestions } from '../common/constant/data';
import LevelRadioSelection from '../common/forms/LevelRadioSelection';
import techVideo from '../../assets/tech.mov'
import MoodSelector from './forms/MoodSelector';
import EmojiDragDrop from './forms/EmojiDragDrop';
import { setUserLevelFiveInfo } from '../../redux/Started';
import TreeWithBranches from './forms/TreeWithBranches';
import { handleAddedPersonalInfoAction } from '../../redux/Started/apisActions';
import Spiner from '../common/constant/spiner';

const LevelFive = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [renderForm, setRenderForm] = useState(0);
    const [validationSchema, setValidationSchema] = useState(
        getValidationSchema(renderForm),
    );

    useEffect(() => {
        setValidationSchema(getValidationSchema(renderForm - 1)); // Update schema when renderForm changes
    }, [renderForm]);

    const { levelOneForm, loading } = useSelector(state => state.initialUserInfo);
    const { userID } = useSelector(state => state.user);
    const formik = useFormik({
        initialValues: {
            ...sliderConfig.reduce(
                (values, slider) => ({
                    ...values,
                    [slider.name]: 0,
                }),
                {},
            ),
            ...firstSevenQuestions.reduce(
                (values, question) => ({
                    ...values,
                    [question.keyName]: '', // Default value for each question
                }),
                {},
            ),
            ...lastSevenQuestions.reduce(
                (values, question) => ({
                    ...values,
                    [question.keyName]: '', // Default value for each question
                }),
                {},
            ),
            strengths: [
                { branch: 'Zweig1', value: '' },
                { branch: 'Zweig2', value: '' },
                { branch: 'Zweig3', value: '' },
                { branch: 'Zweig4', value: '' },
                { branch: 'Zweig5', value: '' },
            ],
            moodCard: '',
            drawing: null,
            expressFeelings: ['', '', ''],
            loves: ['Animals'],
            Other: 'Hello',
        },
        validationSchema,
        onSubmit: values => {
            const data = {
                userId: userID || '',
                LevelFive: JSON.stringify(values),
            };
            if (renderForm === 10) {
                // dispatch(setUserLevelOneInfo(values));
                console.log({ data });
                dispatch(
                    handleAddedPersonalInfoAction({
                        data,
                        callback: () => navigate('/level-six'),
                    }),
                );
                // navigate('/level-six')
            } else {
                formik.setTouched({});
                setRenderForm(renderForm + 1);
            }
            console.log('Form Values:', values);
        },
    });

    console.log("content in render form", renderForm)

    return (
        <>
            <div className='self-center pt-4 text-center '>
                {renderForm === 2 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >
                            Befindlichkeits-Einschätzung
                        </h1>
                        <h1 className='text-[14px] text-mainBlue font-bold italic ' >
                            Wie ist es Dir in den letzten 7 Tagen gegangen?

                        </h1>
                        <h1 className='text-[14px] text-mainBlue font-semibold' >   Bitte kreuze in jedem Kästchen an, was für Dich zutrifft - achte dabei, dass Du alle Zeilen genau durchliest.</h1>
                    </>
                )}
                {renderForm === 0 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >Level 5
                        </h1>
                        <h1 className='text-[16px] text-mainBlue font-bold italic ' >
                            Eigene Lebensquellen erkennen! </h1>
                        <h1 className='text-[16px] text-mainBlue font-semibold' >Willkommen im Level 5!</h1>
                    </>
                )}
                {renderForm === 4 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Deine Energiequellen  </h1>
                    </>
                )}
                {renderForm === 5 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Der Lebensbaum - Deine Stärken </h1>
                    </>
                )}
                {renderForm === 7 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Stimmungskarten  </h1>
                    </>
                )}
                {renderForm === 6 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Tannenbaum der Gefühle   </h1>
                    </>
                )}
                {renderForm === 8 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Deine Energiequellen    </h1>
                    </>
                )}
                {renderForm === 9 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Bewegungsspiel    </h1>
                    </>
                )}
                {renderForm === 10 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Die Person über den Wolken    </h1>
                    </>
                )}

            </div>
            <div style={{ background: `url(${ellipseBG})` }} className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'>
                <div className='relative pt-20 w-full max-w-[36rem] flex flex-col justify-between h-full justify-self-center text-center py-2'>
                    {/* <Intial /> */}
                    <div className="max-w-[32rem] mx-auto">
                        {renderForm === 0 && <Intial />}
                        {renderForm === 1 && <ScalarForm formik={formik} />}
                    </div>


                    {renderForm === 2 && <LevelRadioSelection formik={formik} />}
                    {renderForm === 3 && <LevelRadioSelectionlastSeven formik={formik} />}

                    {renderForm === 4 && (
                        <div className="flex flex-col gap-5 items-start">
                            <p className="text-[13px] text-white  font-[500]">
                                Jeder Mensch braucht Kraft und Energie für seine eigene „innere
                                Batterie“, mit Stress und Problemen des Alltags umzugehen. Manchmal
                                entleert sich die „innere Batterie“ – dies kann sich z.B. als
                                Müdigkeit oder Nervosität äußern.
                            </p>
                            <p className="text-[13px] text-white italic font-[400]">
                                Wie schaut Dein Ladezustand Deiner „inneren Batterie“ momentan aus?
                                Bitte markiere den Ladezustand auf der folgenden Skala zwischen 0
                                und 100%.
                            </p>
                            <div className="relative max-w-[360px] mx-auto px-4 ">
                                <img
                                    src={BatteryPer}
                                    alt="Battery Percentage"
                                    className="w-full"
                                />
                                <input
                                    type="range"
                                    min="0"
                                    max="100"
                                    step="1"
                                    value={formik.values.battery}
                                    onChange={(e) => formik.setFieldValue("battery", Number(e.target.value))}
                                    onBlur={() => formik.setFieldTouched("battery", true)}
                                    className="absolute z-10 !w-[85%] left-[1%] !top-[10%] translate-x-[-50%] !translate-y-[-50%] bg-transparent appearance-none custom-range-slider"
                                />
                                <img
                                    src={Battery}
                                    alt="Battery Percentage"
                                    className="w-full"
                                />
                            </div>
                        </div>
                    )}

                    {/* Form 4 and 5: Lebensbaum */}
                    {(renderForm === 5) && (
                        <div className="flex flex-col gap-5 items-center">
                            <p className="text-[15px] text-white  font-[500]">
                                Was kannst Du besonders gut, und was machst Du gerne? Schreibe bitte
                                all Deine Stärken auf die Äste Deines Lebensbaums!
                            </p>
                            <TreeWithBranches formik={formik} />
                            {/* <img
                                src={Tree}
                                alt="Tree"
                                className="w-[185px] mx-auto object-contain"
                            /> */}
                        </div>
                    )}

                    {/* Form 6: Tannenbaum der Gefühle */}
                    {renderForm === 7 && (
                        <div className="flex flex-col gap-5 items-center">
                            <p className="text-lg font-medium font-poppins text-white">
                                Mit Hilfe der Methode „Tannenbaum der Gefühle“ kannst Du Deine
                                Gefühle kreativ ausdrücken!
                            </p>
                            <p className="text-base font-regular font-poppins text-white">
                                Übung: Bitte beschreibe den Tannenbaum der Gefühle:
                            </p>
                            <div className="grid grid-cols-2 gap-5 max-w-[300px]">
                                <div className="relative">
                                    <img

                                        src={TreeTypes}
                                        alt="Tree Types"
                                        className="w-full object-contain"
                                    />

                                    <a href="https://drive.google.com/file/d/15oi8vEXLjaXp7H3EffsiecTadziWVJWd/view" target='_blank' className='underline absolute right-0 top-2 text-[10px]' > Baumspitze</a>
                                    <a href="https://drive.google.com/file/d/1qDGWsqc9Vd4TFbzEUGJFJ-FeNGwii5Ek/view" target='_blank' className='underline absolute right-0 top-[65%] text-[10px]' > Baumstamm</a>
                                    <a href="https://drive.google.com/file/d/13xofZCRa2zChiBtL8msQkchxoiDn_4Ei/view" target='_blank' className='underline absolute right-0 top-[85%] text-white text-[10px]' > Baumwurzel </a>
                                </div>

                                <div className="flex flex-col gap-3 w-full max-w-md">
                                    {formik.values.expressFeelings.map((value, index) => (
                                        <div key={index} className="mb-1">
                                            {/* Input field */}
                                            <textarea
                                                className={`w-full h-[64px] p-2 border rounded ${formik.touched.expressFeelings?.[index] &&
                                                    formik.errors.expressFeelings?.[index]
                                                    ? 'border-red-500'
                                                    : 'border-gray-300'
                                                    }`}
                                                value={value}
                                                // placeholder="Gefühle eingeben"
                                                onChange={(e) =>
                                                    formik.setFieldValue(`expressFeelings[${index}]`, e.target.value)
                                                }
                                                onBlur={() =>
                                                    formik.setFieldTouched(`expressFeelings[${index}]`, true)
                                                }
                                            ></textarea>

                                            {/* Error Message */}
                                            {formik.touched.expressFeelings?.[index] &&
                                                formik.errors.expressFeelings?.[index] && (
                                                    <p className="text-red-500 text-[10px] text-left mt-1">
                                                        {formik.errors.expressFeelings[index]}
                                                    </p>
                                                )}
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                    )}
                    {renderForm === 6 && (
                        <MoodSelector formik={formik} />
                    )}
                    {renderForm === 8 && (
                        <EmojiDragDrop formik={formik} />
                    )}
                    {renderForm === 9 && (
                        <div className="flex flex-col gap-3">
                            <p className="text-[14px] text-white mb-2 font-[500]">
                                Auflockerung und Bewegung zur Stärkung des psychischen Wohlbefindens.
                            </p>
                            <p className="text-[14px] text-white mb-2 font-[500]">
                                Diese Methode dient der spielerischen Umsetzung von Bewegungsübungen und kann entweder als kurze Pause oder längere Übung eingesetzt werden.
                            </p>
                            <p className="text-[14px] text-white mb-2 font-[500]">
                                · Wähle eine Zahl zwischen 1 und 6! <br />
                                · Welcher Tag ist heute? <br />
                                · Nun mache die entsprechende Übung vom Bewegungssspiel!
                            </p>
                            <a
                                target='_blank'
                                className="text-[14px] text-white italic font-bold underline cursor-pointer"
                                href={'https://drive.google.com/file/d/11n4VLU8iEfbesOKbxw5ILMtAz72Ho84Z/view'}
                            >
                                Hier findest Du den Link der Übungen vom Bewegungsspiel!
                            </a>
                        </div>
                    )}
                    {renderForm === 10 && (
                        <div className="flex flex-col gap-3">
                            <p className="text-[14px] text-white mb-2 font-[500]">
                                Manchmal ist es ganz gut, schwierige Situationen mit Abstand aus der Ferne zu betrachten. Dies kann helfen, diese schwierigen Situationen besser zu verkraften oder zu überstehen.
                            </p>

                            <div className='mt-2 max-w-[270px] mx-auto'>
                                <video
                                    className='w-full h-auto '
                                    controls
                                >
                                    <source src={techVideo} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div className="text-center">
                                <p className="text-[13px] text-white italic font-bold">Gratuliere!</p>
                                <p className="text-[13px] text-white italic font-bold">
                                    Du bist mit Level 5 fertig!
                                </p>
                                <p className="text-[13px] text-white italic font-bold">
                                    Du kannst jetzt mit Level 6 fortsetzen!
                                </p>
                            </div>
                        </div>
                    )}

                    <div className='pt-4 flex gap-2 mb-2'>
                        <button className='w-full button_primary' onClick={() => {
                            if (renderForm === 0) {
                                navigate('/level-four');
                            } else {
                                setRenderForm(renderForm - 1);
                            }
                            // navigation.goBack();
                        }} >Zurück</button>
                        <button className='w-full button_secodry relative' onClick={() => {
                            formik.handleSubmit();
                        }}
                            disabled={loading}
                        >

                            {loading && (
                                <Spiner />
                            )}
                            <span className={loading ? "opacity-0" : ""}>Weiter gehts</span>

                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LevelFive
