import React, { useEffect, useRef, useState } from 'react';
import ellipseBG from '../../assets/EllipseBG.png'
import Trophy from '../../assets/trophy.png'
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import ScalarForm, { sliderConfig } from '../common/forms/ScalarForm';
import LevelRadioSelection from '../common/forms/LevelRadioSelection';
import LevelRadioSelectionlastSeven from '../common/forms/LevelRadioSelectionlastSeven';
import { firstSevenQuestions, lastSevenQuestions } from '../common/constant/data';
import { List, getValidationSchema } from './constant';
import { firstSevenResources, secondSevenResources, thirdSevenResources } from './constant';
import { useSelector, useDispatch } from 'react-redux';
import techVideo from '../../assets/tech.mov'
import CommonRadioForm from './forms/CommonRadioForm'
import Intial from './forms/intial';
import ResourcesFormOne from './forms/ResourcesFormOne';
import { setUserLevelFourInfo } from '../../redux/Started';
import { handleAddedPersonalInfoAction } from '../../redux/Started/apisActions';
import Spiner from '../common/constant/spiner';


const LevelFour = () => {
    const dispatch = useDispatch()
    const scrollViewRef = useRef(null);
    const navigate = useNavigate();
    const [renderForm, setRenderForm] = useState(0);
    const [validationSchema, setValidationSchema] = useState(
        getValidationSchema(renderForm),
    );


    useEffect(() => {
        setValidationSchema(getValidationSchema(renderForm - 1)); // Update schema when renderForm changes
    }, [renderForm]);

    const { levelOneForm, loading } = useSelector(state => state.initialUserInfo);
    const { userID } = useSelector(state => state.user);
    const formik = useFormik({
        initialValues: {
            ...sliderConfig.reduce(
                (values, slider) => ({
                    ...values,
                    [slider.name]: 0,
                }),
                {},
            ),
            ...firstSevenQuestions.reduce(
                (values, question) => ({
                    ...values,
                    [question.keyName]: '', // Default value for each question
                }),
                {},
            ),
            ...lastSevenQuestions.reduce(
                (values, question) => ({
                    ...values,
                    [question.keyName]: '', // Default value for each question
                }),
                {},
            ),
            ...firstSevenResources.reduce(
                (values, question) => ({
                    ...values,
                    [question.keyName]: '', // Default value for each question
                }),
                {},
            ),
            ...secondSevenResources.reduce(
                (values, question) => ({
                    ...values,
                    [question.keyName]: '', // Default value for each question
                }),
                {},
            ),
            ...thirdSevenResources.reduce(
                (values, question) => ({
                    ...values,
                    [question.keyName]: '', // Default value for each question
                }),
                {},
            ),
            trophyText: '',
        },
        validationSchema,
        onSubmit: values => {
            const data = {
                userId: userID || '',
                LevelFour: JSON.stringify(values),
            };
            if (renderForm === 12) {
                dispatch(setUserLevelFourInfo(values));
                dispatch(
                    handleAddedPersonalInfoAction({
                        data,
                        callback: () => navigate('/level-five'),
                    }),
                );
                // navigate('/level-five');
            } else {
                formik.setTouched({});
                setRenderForm(renderForm + 1);
            }
            // console.log('Form Values:', values);
        },
    });



    return (
        <>
            <div className='self-center pt-4 text-center '>
                {renderForm === 2 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >
                            Befindlichkeits-Einschätzung
                        </h1>
                        <h1 className='text-[14px] text-mainBlue font-bold italic ' >
                            Wie ist es Dir in den letzten 7 Tagen gegangen?

                        </h1>
                        <h1 className='text-[14px] text-mainBlue font-semibold' >   Bitte kreuze in jedem Kästchen an, was für Dich zutrifft - achte dabei, dass Du alle Zeilen genau durchliest.</h1>
                    </>
                )}
                {renderForm === 0 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >Level 4
                        </h1>
                        <h1 className='text-[16px] text-mainBlue font-bold italic ' >
                            Eigene Stärken erkennen! </h1>
                        <h1 className='text-[16px] text-mainBlue font-semibold' >Willkommen im Level 4!</h1>
                    </>
                )}
                {renderForm === 4 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Interaktive Beratung </h1>
                    </>
                )}
                {renderForm === 5 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Deine Ressourcen des Lebens </h1>
                    </>
                )}
                {renderForm === 6 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Deine Ressourcen des Lebens  </h1>
                    </>
                )}
                {renderForm === 7 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Deine Ressourcen des Lebens   </h1>
                    </>
                )}
                {renderForm === 8 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Fantasiereisen   </h1>
                    </>
                )}
                {renderForm === 9 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Fantasiereisen   </h1>
                    </>
                )}
                {renderForm === 10 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Genusstraining - Schokolade   </h1>
                    </>
                )}
                {renderForm === 11 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Emotional Freedom Technique (EFT) oder <br /> kurz „Klopf-Technik”   </h1>
                    </>
                )}
                {renderForm === 12 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Stärkenpokal   </h1>
                    </>
                )}

            </div>
            <div style={{ background: `url(${ellipseBG})` }} className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'>
                <div className='relative pt-20 w-full max-w-[44rem] flex flex-col justify-between h-full justify-self-center text-center py-2'>
                    {/* <Intial /> */}
                    <div className="max-w-[33rem] mx-auto">
                        {renderForm === 0 && <Intial />}
                        {renderForm === 1 && <ScalarForm formik={formik} />}
                    </div>
                    <div>
                        <div className="max-w-[36rem] mx-auto">
                            {renderForm === 2 && <LevelRadioSelection formik={formik} />}
                            {renderForm === 3 && <LevelRadioSelectionlastSeven formik={formik} />}

                            {renderForm === 4 && (
                                <div className="flex flex-col gap-5 items-start md:px-5 px-2 ">
                                    <p className="text-[16px] font-bold italic text-white">
                                        Das Ziel der folgenden Übung ist es, Dir bewusst zu machen, wie gut
                                        Du Probleme bewältigen kannst, weil Du viele Stärken besitzt, die Du
                                        auch in zukünftigen Stresssituationen einsetzen kannst.
                                    </p>
                                    <ul>
                                        {List.map((item, index) => (
                                            <li key={index} className="flex text-left gap-2">
                                                <span className="text-[16px] font-bold text-white mt-2">{index + 1}.</span>
                                                <p className="text-[16px] font-bold text-white mt-2">
                                                    {item.title}{' '}
                                                    <a
                                                        target='_blank'
                                                        className="underline  cursor-pointer"
                                                        href={item.url}
                                                    >
                                                        {item.link}
                                                    </a>
                                                </p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            {renderForm === 8 && (
                                <div className="">
                                    <p className="text-[13px] text-white italic font-bold">
                                        Bei Fantasiereisen kannst Du durch geführte Vorstellungen und
                                        Geschichten eine Art innere Reise erleben. Fantasiereisen können
                                        effektiv Stress und Angst reduzieren. Durch die{' '}
                                        <a
                                            target='_blank'
                                            className="underline cursor-pointer"
                                            href={'https://www.ajoure.de/lifestyle/erfolg-motivation/konzentration-steigern/'
                                            }
                                        >
                                            Konzentration
                                        </a>{' '}
                                        auf angenehme Bilder und Szenarien wird das Gehirn von belastenden
                                        Gedanken abgelenkt, dies führt zur Verringerung von Stresshormonen.
                                        Außerdem können Fantasiereisen die Stimmung verbessern, weil sie
                                        einen sicheren Raum, um mit Emotionen umzugehen und innere
                                        Konflikte zu bearbeiten, bieten.
                                    </p>
                                    <div className='mt-2 max-w-[270px] mx-auto'>
                                        <video
                                            className='w-full h-auto '

                                            controls
                                        >
                                            <source src={techVideo} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            )}

                            {renderForm === 9 && (
                                <div className="flex flex-col gap-3 items-center">
                                    <p className="text-[13px] text-white italic font-bold">
                                        Sag “Tschüss” zu Deinen negativen Gedanken
                                    </p>
                                    <div className='mt-2 max-w-[200px] mx-auto'>
                                        <video
                                            className='w-full h-auto '

                                            controls
                                        >
                                            <source src={techVideo} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <p className="text-[13px] text-white italic font-bold">
                                        Lass Dich in die Zukunft beamen!
                                    </p>
                                    <div className='mt-2 max-w-[200px] mx-auto'>
                                        <video
                                            className='w-full h-auto '

                                            controls
                                        >
                                            <source src={techVideo} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            )}

                            {renderForm === 10 && (
                                <div className="flex flex-col gap-3">
                                    <div>
                                        <p className="text-[13px] text-white italic font-bold">
                                            Genuss ist eine positive Sinnesempfindung, die sehr vielfältig ist
                                            und sich nicht nur auf Essen und Trinken beschränkt. Auch einen
                                            schönen Tag/Abend mit Freund:innen, ein tolles Konzert, ein
                                            spannendes Buch etc., kann man genießen.
                                        </p>
                                        <p className="text-[13px] text-white italic font-bold">
                                            Genießen ist sehr wichtig, weil jeder Genuss der Seele guttut und
                                            positive Gefühle wie Glück, Ruhe und Wohlbefinden in uns auslöst.
                                        </p>
                                        <p className="text-[13px] text-white italic font-bold">
                                            Die Grundvoraussetzung für Genuss ist die Fähigkeit und
                                            Bereitschaft zu „bewusstem Erleben mit allen Sinnen". Daher
                                            braucht Genuss Zeit!
                                        </p>
                                    </div>
                                    <div className='mt-2 max-w-[270px] mx-auto'>
                                        <video
                                            className='w-full h-auto '

                                            controls
                                        >
                                            <source src={techVideo} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            )}

                            {renderForm === 11 && (
                                <div className="flex flex-col gap-3">
                                    <p className="text-[13px] text-white italic font-bold">
                                        Die EFT ist eine wissenschaftlich anerkannte Methode. Das Klopfen
                                        ist eine sanfte Art der Akupressur, welches Dir dabei behilflich
                                        sein soll, verschiedene belastende Gefühle wie Angst, Unsicherheit,
                                        Wut u.a. zu beeinflussen und in angenehmere Gefühle umzuwandeln.
                                        Das Klopfen kannst Du auch immer wieder selbst anwenden.
                                    </p>
                                    <a
                                        target='_blank'
                                        className="text-[13px] text-white italic font-bold underline cursor-pointer"
                                        href={'https://drive.google.com/file/d/15-UiTwNGPFHBvBpbiLL2JisqZOXQ21AD/view'}
                                    >
                                        Bevor wir beginnen, drucke Dir folgendes Bild mit den
                                        „Klopfpunkten“ aus.
                                    </a>
                                    <div className='mt-2 max-w-[270px] mx-auto'>
                                        <video
                                            className='w-full h-auto '

                                            controls
                                        >
                                            <source src={techVideo} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            )}

                            {renderForm === 12 && (
                                <div className="flex flex-col gap-3 -mt-10">
                                    <p className="text-xl font-medium text-white text-center">
                                        Du hast so viele Stärken!
                                    </p>
                                    <p className="text-lg font-medium text-white text-center italic">
                                        Bitte schreibe Deine Stärken unter dem Pokal!
                                    </p>
                                    <div className="">
                                        <img
                                            src={Trophy}
                                            alt="Trophy"
                                            className="animate-slideInUp mx-auto w-[200px]"
                                        />
                                        <div className="mt-2 w-[225px] px-3 mx-auto " >
                                            <input
                                                type="text"
                                                className=" simurg_input h-10 border-b border-black text-black outline-none"
                                                placeholder=""

                                                value={formik.values.trophyText}
                                                onChange={(e) => formik.setFieldValue('trophyText', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <p className="text-[13px] text-white italic font-bold">Gratuliere!</p>
                                        <p className="text-[13px] text-white italic font-bold">
                                            Du bist mit Level 4 fertig!
                                        </p>
                                        <p className="text-[13px] text-white italic font-bold">
                                            Du kannst jetzt mit Level 5 fortsetzen!
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>


                        {(renderForm === 5 || renderForm === 6 || renderForm === 7) && (
                            <ResourcesFormOne formik={formik} renderForm={renderForm} />
                        )}


                    </div>
                    <div className="max-w-[36rem] mx-auto mb-2">
                        <div className='pt-4 flex gap-2 w-[300px] md:w-[36rem]'>
                            <button className='w-full button_primary' onClick={() => {
                                if (renderForm === 0) {
                                    navigate('/level-three');
                                } else {
                                    setRenderForm(renderForm - 1);
                                }
                                // navigation.goBack();
                            }} >Zurück</button>
                            <button className='w-full button_secodry relative' onClick={() => {
                                formik.handleSubmit();
                            }}
                                disabled={loading}
                            >

                                {loading && (
                                    <Spiner />
                                )}
                                <span className={loading ? "opacity-0" : ""}>Weiter gehts</span>

                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default LevelFour
