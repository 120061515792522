import React, { useEffect, useRef, useState } from 'react';
import ellipseBG from '../../assets/EllipseBG.png'
import StartScreen from './StartScreen'
import { useFormik } from 'formik';
import SliderScreen from './SliderScreen';
import RadioSelectionScreen from './RadioSelectionScreen';
import RadioSelectionLast from './RadioSelectionLast';
import TextScreen from './TextScreen';
import TextScreenLast from './TextScreenLast';
import StressScreen from './StressScreen';
import PlaylistScreen from './PlaylistScreen';
import BalloonScreen from './BalloonScreen';
import VideoScreen from './VideoScreen';
import { useDispatch, useSelector } from 'react-redux';
import Intial from './forms/intial';
import { useNavigate } from 'react-router-dom';
import { DATA_Playlist, getValidationSchema, transformData } from './constant/data';
import ScalarForm, { sliderConfig } from '../common/forms/ScalarForm';
import LevelRadioSelection from '../common/forms/LevelRadioSelection';
import LevelRadioSelectionlastSeven from '../common/forms/LevelRadioSelectionlastSeven';
import techVideo from '../../assets/tech.mov'
import { levelOneMcqs } from './constants/data';
import { firstFiveInputQuestions, firstSevenQuestions, lastFiveInputQuestions, lastSevenQuestions } from '../common/constant/data';
import { handleAddedPersonalInfoAction } from '../../redux/Started/apisActions';
import { setUserLevelOneInfo } from '../../redux/Started';
import ProfileFormOne from '../common/forms/ProfileFormOne';
import ProfileFormSecondhalf from '../common/forms/ProfileFormSecondhalf';
import StressForm from './forms/StressForm';
import PlayList from './forms/PlayList';
import Spiner from '../common/constant/spiner';

const LevelOne = () => {
    const navigate = useNavigate();
    const { levelOneForm, loading } = useSelector(state => state.initialUserInfo);
    const { userID } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [renderForm, setRenderForm] = useState(0);
    const [validationSchema, setValidationSchema] = useState(
        getValidationSchema(renderForm),
    );

    useEffect(() => {
        setValidationSchema(getValidationSchema(renderForm - 1)); // Update schema when renderForm changes
    }, [renderForm]);

    const formik = useFormik({
        initialValues: {
            ...sliderConfig.reduce(
                (values, slider) => ({
                    ...values,
                    [slider.name]: 0,
                }),
                {},
            ),
            ...firstSevenQuestions.reduce(
                (values, question) => ({
                    ...values,
                    [question.keyName]: '', // Default value for each question
                }),
                {},
            ),
            ...lastSevenQuestions.reduce(
                (values, question) => ({
                    ...values,
                    [question.keyName]: '', // Default value for each question
                }),
                {},
            ),
            ...firstFiveInputQuestions.reduce(
                (values, question) => ({
                    ...values,
                    [question.keyName]: '', // Initial empty value for each question
                }),
                {},
            ),
            ...lastFiveInputQuestions.reduce(
                (values, question) => ({
                    ...values,
                    [question.keyName]: '', // Initial empty value for each question
                }),
                {},
            ),
            answers: levelOneMcqs.map(() => ({
                checked: false, // Initially, none of the checkboxes are checked
                details: '', // Initially, the input is an empty string
            })),
            ...DATA_Playlist[0].data.reduce((values, item) => {
                values[item.keyName] = ''; // Initialize all fields with empty strings
                return values;
            }, {}),
            balloonsText: [],
        },
        validationSchema,
        onSubmit: values => {
            const apiPayload = transformData(values);
            console.log(apiPayload);

            const data = {
                userId: userID,
                LevelOne: JSON.stringify(apiPayload),
            };
            if (renderForm === 9) {
                console.log({ data });
                dispatch(
                    handleAddedPersonalInfoAction({
                        data,
                        callback: res => {
                            navigate('/level-two');
                        },
                    }),
                );

            } else {
                if (renderForm === 8) {
                    // console.log({dataFormat});
                    dispatch(setUserLevelOneInfo(values));
                }
                // if (scrollViewRef.current) {
                //     scrollViewRef.current.scrollTo({ y: 0, animated: true });
                // }
                formik.setTouched({});
                setRenderForm(renderForm + 1);
            }
            // console.log('Form Values:', values);
        },
    });



    return (
        <>
            <div className='self-center pt-4 text-center '>
                {renderForm === 2 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >
                            Befindlichkeits-Einschätzung
                        </h1>
                        <h1 className='text-[14px] text-mainBlue font-bold italic ' >
                            Wie ist es Dir in den letzten 7 Tagen gegangen?

                        </h1>
                        <h1 className='text-[14px] text-mainBlue font-semibold' >   Bitte kreuze in jedem Kästchen an, was für Dich zutrifft - achte dabei, dass Du alle Zeilen genau durchliest.</h1>
                    </>
                )}
                {renderForm === 6 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >
                            Stressmanagement
                        </h1>

                        <h1 className='text-[14px] text-mainBlue font-semibold' >   Im Leben können immer wieder Probleme auftreten. Es ist sehr wichtig, wie wir mit Problemen umgehen, ob wir über Probleme sprechen, oder uns (fachliche) Unterstützung holen.</h1>
                        <h1 className='text-[14px] text-mainBlue font-semibold' >   Bitte kreuze an, wer bzw. was Dir bisher geholfen hat, Deine Probleme zu bewältigen:</h1>
                    </>
                )}
                {renderForm === 0 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' >Level 1</h1>
                        <h1 className='text-[16px] text-mainBlue font-bold italic ' >
                            Gedanken über das Leben </h1>
                        <h1 className='text-[16px] text-mainBlue font-semibold' >Willkommen im Level 1!</h1>
                    </>
                )}




                {renderForm === 8 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Ballon-Crash    </h1>
                    </>
                )}
                {renderForm === 9 && (
                    <>
                        <h1 className='text-[16px] text-mainBlue font-bold italic' > Skillskoffer     </h1>
                    </>
                )}

            </div>
            <div style={{ background: `url(${ellipseBG})` }} className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'>
                <div className='relative pt-20 w-full max-w-[44rem] flex flex-col justify-between h-full justify-self-center text-center py-2'>
                    {/* <Intial /> */}
                    <div className="max-w-[32rem] mx-auto">
                        {renderForm === 0 && <Intial />}
                        {renderForm === 1 && <ScalarForm formik={formik} />}
                    </div>

                    <div className="max-w-[36rem] mx-auto">
                        {renderForm === 2 && <LevelRadioSelection formik={formik} />}
                        {renderForm === 3 && <LevelRadioSelectionlastSeven formik={formik} />}
                        {renderForm === 6 && <StressForm formik={formik} />}
                        {renderForm === 7 && <PlayList formik={formik} />}
                        {renderForm === 8 && <BalloonScreen formik={formik} />}
                        {renderForm === 9 && <VideoScreen />}
                    </div>

                    {renderForm === 4 && <ProfileFormOne formik={formik} />}
                    {renderForm === 5 && <ProfileFormSecondhalf formik={formik} />}





                    <div className="">
                        <div className='pt-4 mb-2 flex gap-2 max-w-[36rem] mx-auto'>
                            <button className='w-full button_primary' onClick={() => {
                                if (renderForm === 0) {
                                    navigate('/');
                                } else {
                                    setRenderForm(renderForm - 1);
                                }
                                // navigation.goBack();
                            }} >Zurück</button>
                            <button className='w-full button_secodry relative' onClick={() => {
                                formik.handleSubmit();
                            }}
                                disabled={loading}
                            >

                                {loading && (
                                    <Spiner />
                                )}
                                <span className={loading ? "opacity-0" : ""}>Weiter gehts</span>

                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default LevelOne
