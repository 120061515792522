
import React from 'react'

const Intial = () => {
    return (
        <div>
            <h1 className='font-medium text-[17px] text-[#fff] leading-[26px]'>
                {`In diesem Level kannst Du lernen, Deine eigenen Stärken besser zu erkennen. Jeder Mensch hat ganz persönliche Stärken. Als Stärken werden persönliche Fähigkeiten und Talente bezeichnet, die aus Gedanken, Fertigkeiten und Verhaltensweisen bestehen. 

`}
            </h1>
            <h1 className='font-medium text-[17px] text-[#fff] mt-2 leading-[26px]'>
                {`Darunter finden sich persönliche Eigenschaften, die besonders stark ausgeprägt sind, ebenso wie Fähigkeiten, die Dir einfach liegen und in denen Du gut bist. `}
            </h1>

        </div>
    )
}

export default Intial
