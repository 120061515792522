import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import mainBG from '../../../assets/EinführungMainBG.png';
import ellipseBG from '../../../assets/EllipseBG.png';

const TextScreen = ({ setRenderForm }) => {
    const isSmallScreen = window.innerHeight <= 600;

    const validationSchema = Yup.object({
        positiveBehaviors: Yup.string().required('Bitte fülle dieses Feld aus.'),
        badHabits: Yup.string().required('Bitte fülle dieses Feld aus.'),
        strengths: Yup.string().required('Bitte fülle dieses Feld aus.'),
        weaknesses: Yup.string().required('Bitte fülle dieses Feld aus.'),
        hobbies: Yup.string().required('Bitte fülle dieses Feld aus.'),
    });

    const formik = useFormik({
        initialValues: {
            positiveBehaviors: '',
            badHabits: '',
            strengths: '',
            weaknesses: '',
            hobbies: '',
        },
        // validationSchema,
        onSubmit: (values) => {
            console.log('Form Submitted:', values);
            setRenderForm(5);
        },
    });

    return (
        <div style={{ background: `url(${mainBG})` }} className='h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between'>
            <div></div>
            <div style={{ background: `url(${ellipseBG})` }} className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'>
                <div className={`relative pt-20 w-full ${isSmallScreen ? 'max-w-[32rem]' : 'max-w-[40rem]'} flex flex-col justify-between h-full justify-self-center text-center pb-4`}>
                    <div>
                        <h1 className={`text-center text-white font-semibold ${isSmallScreen ? 'text-xs' : 'text-sm'}`}>
                            {`Bevor Du mit dem Programm weitermachst, möchten wir Dich ein bisschen näher kennenlernen und bitten Dich deshalb, folgende Fragen (Steckbrief) zu beantworten!`}
                        </h1>
                        <form onSubmit={formik.handleSubmit}>
                            <div className='grid grid-cols-2 gap-8 mt-2'>
                                <div>
                                    <div className='flex flex-col items-start mb-4'>
                                        <h1 className={`font-normal ${isSmallScreen ? 'text-xs' : 'text-sm'} text-white italic text-left my-2`}>{`Mein Steckbrief:`}</h1>
                                        <label className={`font-semibold ${isSmallScreen ? 'text-xs' : 'text-sm'} text-white text-left mb-2`}>
                                            {`Welche positiven Verhaltensweisen zeichnen Dich aus (z.B. Sport, gesunde Ernährung etc.)?`}
                                        </label>
                                        <input
                                            type='text'
                                            className='simurg_input'
                                            name="positiveBehaviors"
                                            value={formik.values.positiveBehaviors}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.positiveBehaviors && formik.errors.positiveBehaviors && (
                                            <p className="text-[11px] text-red-500">{formik.errors.positiveBehaviors}</p>
                                        )}
                                    </div>
                                    <div className='flex flex-col items-start mb-4'>
                                        <label className={`font-semibold ${isSmallScreen ? 'text-xs' : 'text-sm'} text-white text-left mb-2`}>
                                            {`Welche schlechten Gewohnheiten hast Du (z.B. Rauchen, Schlafmangel etc.)?`}
                                        </label>
                                        <input
                                            type='text'
                                            className='simurg_input'
                                            name="badHabits"
                                            value={formik.values.badHabits}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.badHabits && formik.errors.badHabits && (
                                            <p className="text-[11px] text-red-500">{formik.errors.badHabits}</p>
                                        )}
                                    </div>
                                </div>

                                <div>
                                    <div className='flex flex-col items-start mb-4'>
                                        <label className={`font-semibold ${isSmallScreen ? 'text-xs' : 'text-sm'} text-white text-left mb-2`}>{`Was sind Deine Stärken?`}</label>
                                        <input
                                            type='text'
                                            className='simurg_input'
                                            name="strengths"
                                            value={formik.values.strengths}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.strengths && formik.errors.strengths && (
                                            <p className="text-[11px] text-red-500">{formik.errors.strengths}</p>
                                        )}
                                    </div>
                                    <div className='flex flex-col items-start mb-4'>
                                        <label className={`font-semibold ${isSmallScreen ? 'text-xs' : 'text-sm'} text-white text-left mb-2`}>{`Was sind Deine Schwächen?`}</label>
                                        <input
                                            type='text'
                                            className='simurg_input'
                                            name="weaknesses"
                                            value={formik.values.weaknesses}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.weaknesses && formik.errors.weaknesses && (
                                            <p className="text-[11px] text-red-500">{formik.errors.weaknesses}</p>
                                        )}
                                    </div>
                                    <div className='flex flex-col items-start mb-4'>
                                        <label className={`font-semibold ${isSmallScreen ? 'text-xs' : 'text-sm'} text-white text-left mb-2`}>{`Was sind Deine Hobbys?`}</label>
                                        <input
                                            type='text'
                                            className='simurg_input'
                                            name="hobbies"
                                            value={formik.values.hobbies}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.hobbies && formik.errors.hobbies && (
                                            <p className="text-[11px] text-red-500">{formik.errors.hobbies}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className={`${isSmallScreen ? 'pt-2' : 'pt-4'} flex gap-6 justify-center`}>
                        <button
                            type="button"
                            className='w-1/3 button_primary'
                            onClick={() => setRenderForm(3)}
                        >
                            Zurück
                        </button>
                        <button
                            type="button"
                            className='w-1/3 button_secodry'
                            onClick={formik.handleSubmit}
                        >
                            Weiter gehts
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TextScreen;
