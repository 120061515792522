import * as Yup from 'yup';
import { sliderConfig } from '../../common/forms/ScalarForm';
import { firstSevenQuestions, lastSevenQuestions } from '../../common/constant/data';

export const stressOptions = [
  {
    question: `Fotos von Freunden/Angehörigen`,
    value: 'PhotosOfFriendsRelatives',
  },
  {
    question: `Fotos vom Urlaub, Geburtstagsparty etc.`,
    value: 'PhotosFromVacationsParties',
  },
  {
    question: `Fotos vom Haustier`,
    value: 'PhotosOfPets',
  },
  {
    question: `Gebete/Zitate `,
    value: 'PrayersQuotes',
  },
  {
    question: `Lieblings CD, Video`,
    value: 'FavoriteCDVideo',
  },
  {
    question: `Lieblingsbuch/Lieblingsroman`,
    value: 'FavoriteBookNovel',
  },
  {
    question: `Selbst gemalte Bilder`,
    value: 'SelfPaintedPictures',
  },
  {
    question: `Kopien von guten Zeugnissen`,
    value: 'CopiesOfGoodReferences',
  },
  {
    question: `Pokale oder Auszeichnungen`,
    value: 'TrophiesAwards',
  },
  {
    question: `andere Gründe:`,
    value: 'Other',
  },
];


export const List = [
  {
    title: `etwas Nettes für Deine Freunde/Familie/Bekannte tun`,
    url: 'https://drive.google.com/file/d/1PwzYItg2cBnFlQcD0cgAV7gi7pLKKDy1/view',
  },
  {
    title: `Dich motivieren, aktiver zu werden`,
    url: 'https://drive.google.com/file/d/1of8tJg7iCUmlDoSmnYygwqZPNzW_DArm/view',
  },
  {
    title: `versuchen, Deine Gefühle zu verändern`,
    url: 'https://drive.google.com/file/d/1Xnzac5Sr0QkLFOrE48Loab1Zels1xBrK/view',
  },
  {
    title: `versuchen, Deine Gedanken zu ersetzen`,
    url: 'https://drive.google.com/file/d/1-hrcqG3miObDYmrSg3r_Z--A8FKxN6Gh/view',
  },
  {
    title: `versuchen, die schwierige Situation in Deinen Gedanken zu verlassen`,
    url: 'https://drive.google.com/file/d/1uxPXgovWGIM6zKDl1rUOqjgODoIPs38n/view',
  },
];

export const questionsList = [
  { question: 'Ich bin eigentlich jemand, der kämpft.', value: 'isFighter' },
  {
    question:
      'Ich glaube, dass ich lernen kann, mit meinen Problemen umzugehen.',
    value: 'canLearnToCope',
  },
  {
    question:
      ' Ich glaube, dass ich für meine Probleme passende Lösungen finden kann, wenn ich sie suche.',
    value: 'canFindSolutions',
  },
  {
    question: 'Es gibt für alles mindestens eine Lösung.',
    value: 'everythingHasASolution',
  },
  {
    question: 'Auf und Abs“ gehören zum Leben.',
    value: 'upsAndDownsArePartOfLife',
  },
  {
    question: 'Auch Stresssituationen gehen vorüber!',
    value: 'stressfulSituationsPass',
  },
  {
    question: 'Mir hat noch nichts davon geholfen',
    value: 'Mir hat noch nichts davon geholfen',
  },
  { question: 'Andere Gründe', value: 'Other' },
];

export const getValidationSchema = renderForm => {
  if (renderForm === 0) {
    return Yup.object(
      sliderConfig.reduce((schema, slider) => {
        schema[slider.name] = Yup.number()
          .required('Dieses Feld ist erforderlich.')
          .min(slider.minValue, `Der Mindestwert beträgt ${slider.minValue}.`)
          .max(slider.maxValue, `Der Höchstwert beträgt ${slider.maxValue}.`);
        return schema;
      }, {}),
    );
  } else if (renderForm === 1) {
    return Yup.object(
      firstSevenQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          'Bitte wähle eine Option.',
        );
        return schema;
      }, {}),
    );
  } else if (renderForm === 2) {
    return Yup.object(
      lastSevenQuestions.reduce((schema, question) => {
        schema[question.keyName] = Yup.string().required(
          'Bitte wähle eine Option.',
        );
        return schema;
      }, {}),
    );
  }
  else if (renderForm === 4) {
    return Yup.object().shape({
      sociogram: Yup.array()
        .of(Yup.string().notRequired())
        .test(
          'at-least-one',
          'Bitte wähle mindestens eine Option aus.',
          (array) => array && array.some((value) => value)
        ),
    });
  }
  else if (renderForm === 5) {
    return Yup.object().shape({
      sentences: Yup.array().min(1, 'Bitte wähle mindestens eine Option aus.'), // Validate at least one sentence is selected
      otherSentencesReason: Yup.string().test(
        'is-other-reason-required',
        'Bitte gib einen Grund an.',
        function (value) {
          const { sentences } = this.parent;
          // Check if "Andere Gründe" is selected and if the 'otherReason' is provided
          if (sentences.includes('Other') && (!value || value.trim() === '')) {
            return false; // Fail validation if no value is provided
          }
          return true; // Pass validation if otherReason is either not required or is provided
        },
      ),
    });
  } else if (renderForm === 7) {
    return Yup.object().shape({
      sourcesOfStrength: Yup.array()
        .min(1, 'Bitte wähle mindestens eine Option aus.') // Validate at least one checkbox
        .test(
          'include-other-reason',
          'Bitte gib einen Grund an.',
          function (sourcesOfStrength) {
            const { otherReason } = this.parent;
            if (sourcesOfStrength.includes('Other')) {
              return otherReason && otherReason.trim() !== ''; // Validate otherReason if 'andere Gründe:' is selected
            }
            return true;
          },
        ),
      otherSourcesOfStrength: Yup.string().test(
        'is-required-if-other',
        'Bitte gib einen Grund an.',
        function (value) {
          const { sourcesOfStrength } = this.parent;
          return (
            !sourcesOfStrength.includes('Other') ||
            (value && value.trim() !== '')
          );
        },
      ),
    });
  }

  // Add other cases as needed
  return Yup.object(); // Default empty schema
};

