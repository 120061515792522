import React, { useState } from 'react';
import mainBG from '../../../assets/EinführungMainBG.png';
import ellipseBG from '../../../assets/EllipseBG.png';
import { levelOneMcqs, levelOneMcqs_options } from '../constants/data';

const StressScreen = ({ setRenderForm }) => {
    const isSmallScreen = window.innerHeight <= 600;

    const [formState, setFormState] = useState({});
    const [error, setError] = useState('');

    const handleSelection = (questionIndex, optionValue) => {
        setFormState((prev) => ({
            ...prev,
            [questionIndex]: { ...prev[questionIndex], option: optionValue },
        }));
    };

    const handleInputChange = (questionIndex, value) => {
        setFormState((prev) => ({
            ...prev,
            [questionIndex]: { ...prev[questionIndex], input: value },
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const isValid = Object.values(formState).some(
            (entry) => entry.option && entry.input && entry.input.trim() !== ''
        );

        if (isValid) {
            setError('');
            setRenderForm(7);
        } else {
            setError('Bitte wähle mindestens eine Option aus und fülle das zugehörige Feld aus.');
        }
    };

    return (
        <div
            style={{ background: `url(${mainBG})` }}
            className="h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between"
        >
            <div
                className={`font-bold ${isSmallScreen ? 'text-[10px]' : 'text-sm'} text-[#0F32C0E5] self-center italic ${isSmallScreen ? 'pt-3' : 'pt-5'
                    } text-center max-w-[40rem]`}
            >
                <h1>
                    {`Stressmanagement`}
                    <br />
                    {`Im Leben können immer wieder Probleme auftreten. Es ist sehr wichtig, wie wir mit Problemen umgehen, ob wir über Probleme sprechen, oder uns (fachliche) Unterstützung holen.`}
                </h1>
                <h1 className={`${isSmallScreen ? 'mt-2' : 'mt-4'} font-semibold`}>
                    {`Bitte kreuze an, wer bzw. was Dir bisher geholfen hat, Deine Probleme zu bewältigen:`}
                </h1>
            </div>
            <div
                style={{ background: `url(${ellipseBG})` }}
                className="min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative"
            >
                <div className="relative h-full mx-auto w-full max-w-[35%] text-center pb-4">
                    <div className="h-[80%] overflow-auto hide-scrollbar mt-24">
                        <form className='flex flex-col justify-between h-full items-center' onSubmit={handleSubmit}>
                            <div className="mt-2 w-full">
                                {levelOneMcqs.map((question, questionIndex) => (
                                    <div key={questionIndex}>
                                        <h1
                                            className={`font-bold ${isSmallScreen ? 'text-[10px]' : 'text-[15px]'} text-white text-left`}
                                        >
                                            {question.question}
                                        </h1>
                                        {levelOneMcqs_options.map((option, optionIndex) => {
                                            const inputId = `option-${questionIndex}-${optionIndex}`;
                                            return (
                                                <div
                                                    className="flex items-center justify-between gap-2"
                                                    key={optionIndex}
                                                >
                                                    <div className="flex items-center gap-3 pl-5">
                                                        <input
                                                            type="radio"
                                                            id={inputId}
                                                            value={option.value}
                                                            className="custom-radio my-3"
                                                            name={`question-${questionIndex}`}
                                                            onChange={() =>
                                                                handleSelection(questionIndex, option.value)
                                                            }
                                                        />
                                                        <label
                                                            htmlFor={inputId}
                                                            className={`font-normal ${isSmallScreen ? 'text-[10px]' : 'text-base'
                                                                } text-[#f4c6c2]`}
                                                        >
                                                            {option.label}
                                                        </label>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        placeholder="Wie?"
                                                        className={`simurg_input ${isSmallScreen ? '!h-[25px]' : '!h-[34px]'
                                                            } ${isSmallScreen ? '!w-[70px]' : 'w-full'}`}
                                                        style={{ width: '30%' }}
                                                        onChange={(e) =>
                                                            handleInputChange(questionIndex, e.target.value)
                                                        }
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                ))}
                            </div>
                            {error && <p className="text-red-500 mt-4">{error}</p>}
                            <div className="pt-4 flex gap-6 w-full">
                                <button
                                    type="button"
                                    className="w-full button_primary"
                                    onClick={() => setRenderForm(5)}
                                >
                                    Zurück
                                </button>
                                <button type="submit" className="w-full button_secodry">
                                    Weiter gehts
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StressScreen;
