import React from 'react';
import { useFormik } from 'formik';
import mainBG from '../../../assets/EinführungMainBG.png';
import ellipseBG from '../../../assets/EllipseBG.png';

export const questionsDetailsSecond = [
    {
        id: 'psychotherapy',
        text: 'Bist Du derzeit in psychotherapeutischer Behandlung?',
    },
    {
        id: 'psychiatricDiagnosis',
        text: 'Hast Du eine psychiatrische Diagnose?',
    },
];

const RadioFormSecond = ({ nextClick, backClick, formik }) => {



    return (
        <div
            style={{ background: `url(${mainBG})` }}
            className="h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between"
        >
            <div></div>
            <div
                style={{ background: `url(${ellipseBG})` }}
                className="min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative"
            >
                <div className="relative w-full h-full mx-auto max-w-[36rem] text-center pb-4">
                    <div className='h-full'>
                        <form className='pt-20 flex flex-col justify-between h-full' onSubmit={formik.handleSubmit}>


                            <div className="w-[70%] mx-auto">
                                {questionsDetailsSecond.map((question) => (
                                    <div key={question.id} className="flex flex-col items-start mb-4">
                                        <p className="font-semibold text-base text-white text-left">
                                            {question.text}
                                        </p>
                                        <div className="flex gap-8">
                                            <div className="flex items-center gap-2">
                                                <input
                                                    type="radio"
                                                    className="custom-radio my-3"
                                                    id={`${question.id}-ja`}
                                                    value="Ja"
                                                    name={question.id}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    checked={formik.values[question.id] === "Ja"}
                                                />
                                                <label
                                                    htmlFor={`${question.id}-ja`}
                                                    className="font-normal text-base text-white"
                                                >
                                                    Ja
                                                </label>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <input
                                                    type="radio"
                                                    className="custom-radio my-3"
                                                    id={`${question.id}-nein`}
                                                    value="Nein"
                                                    name={question.id}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    checked={formik.values[question.id] === "Nein"}
                                                />
                                                <label
                                                    htmlFor={`${question.id}-nein`}
                                                    className="font-normal text-base text-white"
                                                >
                                                    Nein
                                                </label>
                                            </div>
                                        </div>
                                        {formik.touched[question.id] && formik.errors[question.id] && (
                                            <div className="text-red-500 text-sm text-left">
                                                {formik.errors[question.id]}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>


                            <div className="pt-10 flex gap-6">
                                <button
                                    type="button"
                                    className="w-full button_primary"
                                    onClick={backClick}
                                >
                                    Zurück
                                </button>
                                <button type="submit" className="w-full button_secodry" onClick={nextClick}>
                                    Weiter gehts
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RadioFormSecond;