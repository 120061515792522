
import React from 'react'

const Intial = () => {
    return (
        <div>
            <h1 className='font-medium text-[20px] text-[#fff]'>
                {`
            In diesem Level können wir Dir Methoden beibringen, mit denen Du belastende 
            Gedanken entfernen kannst, um einen Entspannungszustand für die Regeneration 
            Deines gesamten Körpers zu erreichen.`}
            </h1>
            <h1 className='font-medium text-[20px] text-[#fff] mt-2'>
                {`Wir empfehlen Dir, dass Du jeden Tag ein Video dieses Levels ansiehst. `}
            </h1>

        </div>
    )
}

export default Intial
