import React from 'react'
import mainBG from '../../../assets/EinführungMainBG.png'
import ellipseBG from '../../../assets/EllipseBG.png'
import { useNavigate } from 'react-router-dom';

const StartScreen = ({ setRenderForm }) => {
    const isSmallScreen = window.innerHeight <= 600;
    const navigate = useNavigate();

    return (
        <>
            <div style={{ background: `url(${mainBG})` }} className='h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between'>
                <div className={`font-bold ${isSmallScreen ? 'text-sm' : 'text-base'} text-[#0F32C0E5] self-center italic py-5 text-center`}>
                    <h1>{`Level 1`}<br />{`Gedanken über das Leben`}</h1>
                    <h1 className='mt-4 font-semibold'>{`Willkommen im Level 1!`}</h1>
                </div>
                <div style={{ background: `url(${ellipseBG})` }} className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'>
                    <div className={`relative ${isSmallScreen ? 'pt-12' : 'pt-20'}  w-full max-w-[40%] flex flex-col justify-between h-full justify-self-center text-center pb-4`}>
                        <div>
                            <h1 className={`font-semibold text-white ${isSmallScreen ? 'text-sm' : 'text-lg'}`}>{`Wir begrüßen Dich zum sechswöchigen Programm`}<br /> {`„My Simurg Guide“ – eine Reise, um Deine`}<br />{`Stärken zu erkennen!`}</h1>
                            <p className={`font-bold italic ${isSmallScreen ? 'text-sm' : 'text-base'} mt-4 text-white`}>{`Jeder von uns durchlebt von Zeit zu Zeit Stresssituationen, die`}<br /> {`sehr belastend sein können.`}<br />{`Diese Woche werden wir uns genauer mit Deiner Fähigkeit`}<br />{`befassen, um den Stress besser zu bewältigen!`}</p>
                            <p className={`font-bold italic ${isSmallScreen ? 'text-sm' : 'text-base'} mt-4 text-white`}>{`Das Ziel des ersten Levels ist es, dass Du durch die Anwendung`}<br />{`von verschiedenen Skills, besser mit Stresssituationen`}<br />{`umgehen kannst.`}</p>
                        </div>

                        <div className={`${isSmallScreen ? 'pt-2' : 'pt-8'} flex gap-6`}>
                            <button className='w-full button_primary' onClick={() => navigate("/")}>Zurück</button>
                            <button className='w-full button_secodry' onClick={() => setRenderForm(1)}>Weiter gehts</button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default StartScreen