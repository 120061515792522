import { appImages } from '../../../assets';

export const exercise_list = [
  {
    title: `· Rufe einmal ganz laut in Dein Kissen hinein! `,
    img: appImages.exeOne,
  },
  {
    title: `· Klopfe mit Deinen Fäusten auf das Kissen!`,
    img: appImages.exeTwo,
  },
  {
    title: `· Schüttle Dich! Strecke Dich und mache Dich ganz lang! `,
    img: appImages.exeThree,
  },
  {
    title: `
        · Hüpfe zuerst auf beiden Beinen, danach nur auf dem linken und dann nur auf dem rechten Bein! `,
    img: appImages.exeFour,
  },
  {
    title: `· Zerreiße oder zerschneide altes Zeitungspapier! `,
    img: appImages.exeFive,
  },
];

export const exercise_reduce_list = [
  {
    title: ` · Handübung`,
    link: 'https://drive.google.com/file/d/1w4JjXymZPrRG5uWuuqZpYKGRRe91NUQZ/view',
    img: appImages.reduceOne,
  },
  {
    title: `· Bauchübung`,
    link: 'https://drive.google.com/file/d/1k4HruqJmJwya-LvU1UHFGbzziFuAgGhJ/view',
    img: appImages.reduceTwo,
  },
  {
    title: `· Oberkörperübung`,
    link: 'https://drive.google.com/file/d/1-JHMxETPlgPdxPGl3CnXaGPtBoqkZ3zl/view',
    img: appImages.reduceThree,
  },
  {
    title: `· Arm- und Beinübung  `,
    link: 'https://drive.google.com/file/d/1QfQvgFoR_5iDdFnvY4djWfPHNGzXffJz/view',

    img: appImages.reduceFour,
  },
  {
    title: `· Spiegelübung `,
    link: 'https://drive.google.com/file/d/1S9_nKmxoGS7kxY9vcmSltdaVZLl1K9BE/view',

    img: appImages.reduceFive,
  },
];

export const exercise_save_list = [
  {
    title: `· Zu Beginn suche Dir ein altes Glas mit einem Verschluss, z.B. ein Gurkenglas, aus.`,
  },
  {
    title: `· Nimm ein Etikett, beschrifte dieses mit „Mein Save der Gefühle“ und beklebe es.`,
  },
  {
    title: `· Lege Dir Stifte und Notizzettel bereit, um am Abend Deine Gefühle aufzuschreiben.`,
  },
  {
    title: `· Schreibe nun Deine angenehmen und unangenehmen Gefühle auf, die Du an diesem Tag hattest.`,
  },
];
