import React from 'react'
import mainBG from '../../../assets/EinführungMainBG.png'
import ellipseBG from '../../../assets/EllipseBG.png'

const JaAccept = ({ nextClick, backClick, formik }) => {

    return (
        <>
            <div style={{ background: `url(${mainBG})` }} className='h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between'>
                <div></div>
                <div style={{ background: `url(${ellipseBG})` }} className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'>
                    <div className='relative pt-24 w-full max-w-[36rem] flex flex-col justify-between h-full justify-self-center text-center pb-4'>
                        <div>
                            <h1 className='font-bold text-white text-xl'>{`Das Online-Programm „My Simurg Guide“ ist für Dich geeignet!`}</h1>
                            <p className='font-normal italic text-white text-base mt-12'>{`Bevor Du mit dem Online-Programm beginnen kannst, lies bitte die`} <a target='_blank' className='underline' href='https://drive.google.com/file/d/1Pt-9hi1qao7RxTsy9NrpVXHaRimfObub/view'>Teilnehmerinformation</a> {`durch und akzeptieren diese indem du auf “Teilnahmebedingungen akzeptieren” drückst`}</p>
                            <div className=" max-w-[31rem] mx-auto">
                                <div className="flex items-start gap-2 py-2">
                                    <input
                                        type="checkbox"
                                        id="policy"
                                        name="termsAndCondition"
                                        checked={formik.values.termsAndCondition}
                                        onChange={formik.handleChange}
                                        className="radio-circle !w-full !max-w-[20px]"
                                    />
                                    <label

                                        className="font-semibold text-base text-white text-left"
                                    >
                                        Teilnahmebedinungen akzeptieren
                                    </label>
                                </div>

                                {formik.errors.termsAndCondition && (
                                    <div className="text-red-500 text-sm text-left">{formik.errors.termsAndCondition}</div>
                                )}
                            </div>

                        </div>

                        <div className='pt-6 flex gap-6'>
                            <button
                                type="button"
                                className="w-full button_primary"
                                onClick={backClick}
                            >
                                Zurück
                            </button>
                            <button type="submit" className="w-full button_secodry" onClick={nextClick} >
                                Weiter gehts
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default JaAccept