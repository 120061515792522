import React from "react";
import { questionsList } from "../../LevelTwo/constant";

const StressForm = ({ formik }) => {


    const handleCheckBoxPress = (sentence) => {
        const isSelected = formik.values.sentences.includes(sentence);
        if (isSelected) {
            formik.setFieldValue(
                "sentences",
                formik.values.sentences.filter((item) => item !== sentence)
            );
        } else {
            formik.setFieldValue("sentences", [...formik.values.sentences, sentence]);
        }
    };

    return (
        <div className="flex flex-col gap-2 -mt-3">
            <p className="font-bold italic text-white text-[14px]">
                Gründe, in Stresssituationen den Mut nicht zu verlieren!
            </p>
            <p className="font-bold  text-white text-[14px]">
                In einer Stresssituation kann es schwierig sein, zuversichtlich
                (optimistisch) zu sein oder zu bleiben. Oft kann man überfordert sein.
            </p>
            <p className="font-bold  text-white text-[14px]">
                Im Folgenden siehst Du Einstellungen, die anderen in Stresssituationen
                geholfen haben: Markiere diejenigen Sätze, die Dir bisher geholfen
                haben:
            </p>

            {questionsList.map((question, index) => (
                <div
                    key={question.value}
                    className="flex flex-col w-full  pb-2"
                >
                    <label className="flex items-center space-x-3">
                        <div className="w-4 h-4">
                            <input
                                type="checkbox"
                                checked={formik.values.sentences.includes(question.value)}
                                onChange={() => handleCheckBoxPress(question.value)}
                                className="w-4 h-4 text-green-600 radio-circle border-gray-300 rounded focus:ring-green-500"
                            />
                        </div>

                        <span
                            className={`text-[16px] font-normal text-left ${formik.values.sentences.includes(question.value)
                                ? "text-green-600"
                                : "text-orange-600"
                                }`}
                        >
                            {question.question}
                        </span>
                    </label>

                    {question.value === "Other" &&
                        formik.values.sentences.includes("Other") && (
                            <div className="w-full">
                                <input
                                    type="text"
                                    className={`w-full border-b ${formik.values.sentences.includes("Other")
                                        ? "border-green-600"
                                        : "border-orange-600"
                                        } bg-transparent text-white outline-none focus:border-green-500 py-1`}
                                    placeholder="Geben Sie Ihren Grund an"
                                    value={formik.values.otherSentencesReason}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            "otherSentencesReason",
                                            e.target.value
                                        )
                                    }
                                    onBlur={() =>
                                        formik.setFieldTouched("otherSentencesReason", true)
                                    }
                                />
                                {formik.errors.otherSentencesReason &&
                                    formik.touched.otherSentencesReason && (
                                        <p className="text-red-500 text-[10px] mt-1 text-left">
                                            {formik.errors.otherSentencesReason}
                                        </p>
                                    )}
                            </div>
                        )}
                </div>
            ))}

            {formik.errors.sentences && formik.touched.sentences && (
                <p className="text-red-500 text-[10px] mt-1 text-left">{formik.errors.sentences}</p>
            )}
        </div>
    );
};

export default StressForm;
