
import React from 'react'

const Intial = () => {
    const isSmallScreen = window.innerHeight <= 600;

    return (
        <div className='max-w-[480px] mx-auto' >
            <h1 className={`font-bold text-[17px] mb-3 text-[#fff]`}>
                {`Eine Stresssituation wird zumeist durch äußere belastende Ereignisse und/oder Lebensumstände ausgelöst.`}
            </h1>
            <h1 className={`font-bold text-[17px] mb-3 text-[#fff] mt-2`}>
                {`Wir alle stehen vor Herausforderungen und manchmal wissen wir nicht, was wir tun sollen. `}
            </h1>
            <h1 className={`font-bold text-[17px] mb-3 text-[#fff] mt-2`}>{` Diese Woche werden wir daran arbeiten, was Du tun kannst, wenn Du mit einer Situation überfordert bzw. gestresst bist.`}</h1>

        </div>
    )
}

export default Intial
