import React from 'react';
import { resources_options } from '../constant';

const CommonRadioForm = ({ list, formik }) => {
  return (
    <div className='grid md:grid-cols-2 grid-cols-1 gap-2' >
      {list.map((question, index) => (
        <div key={index} className=" mb-2">
          {/* Question Text */}
          <p className="text-[14px] text-left font-medium text-white mb-1">
            {question.question}
          </p>

          {/* Radio Options */}
          <div className="flex flex-wrap gap-4">
            {resources_options.map((option, i) => (
              <label
                key={`${index}-${i}`}
                className="flex items-center gap-2 cursor-pointer"
              >
                <input
                  type="radio"
                  name={question.keyName}
                  value={option.value}
                  checked={formik.values[question.keyName] === option.value}
                  onChange={() =>
                    formik.setFieldValue(question.keyName, option.value)
                  }
                  className="w-3 h-3 custom-radio"
                />
                <span className="text-[10px] font-normal text-[#F9DCD4]">{option.label}</span>
              </label>
            ))}
          </div>

          {/* Error Message */}
          {formik.touched[question.keyName] && formik.errors[question.keyName] && (
            <p className="text-[10px] text-left text-red-500 mt-2">
              {formik.errors[question.keyName]}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default CommonRadioForm;
