import React, { useState } from 'react'
import ScalarForm, { sliderConfig } from '../../common/forms/ScalarForm'
import { getValidationSchema } from '../../LevelThree/constant';
import mainBG from '../../../assets/EinführungMainBG.png'
import ellipseBG from '../../../assets/EllipseBG.png'
import { useFormik } from 'formik';

const SliderScreen = ({ setRenderForm }) => {
    const isSmallScreen = window.innerHeight <= 600;
    
    const formik = useFormik({
        initialValues: {
            ...sliderConfig.reduce(
                (values, slider) => ({
                    ...values,
                    [slider.name]: 0,
                }),
                {},
            ),
        },
    });

    return (
        <div style={{ background: `url(${mainBG})` }} className='h-screen !bg-cover !bg-no-repeat !bg-center flex flex-col justify-between'>
            <div></div>
            <div style={{ background: `url(${ellipseBG})` }} className='min-h-[75vh] !bg-cover !bg-no-repeat !bg-top relative'>
                <div className={`relative ${isSmallScreen ? 'pt-16' : 'pt-20'}  w-full max-w-[35%] flex flex-col justify-between h-full justify-self-center text-center pb-4`}>
                    <ScalarForm formik={formik} />

                    <div className={`${isSmallScreen ? 'pt-2' : 'pt-6'} flex gap-6`}>
                        <button className='w-full button_primary' onClick={() => setRenderForm(0)}>Zurück</button>
                        <button className='w-full button_secodry' onClick={() => setRenderForm(2)}>Weiter gehts</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SliderScreen