
import React from 'react'

const Intial = () => {
    return (
        <div>
            <h1 className='font-bold text-[20px] text-[#fff]'>
                {`Bevor Du Deine sechswöchige „Reise mit uns“ beendest, möchten wir Dich möglichst gut auf die Zukunft vorbereiten!`}
            </h1>
            <h1 className='font-medium text-[16px] text-[#fff] mt-2'>
                {`Ziel dieses Levels ist eine Zusammenfassung des bisher Gelernten, als auch ergänzende  Methoden, die Dir auch zukünftig in Stresssituationen helfen können.`}
            </h1>

        </div>
    )
}

export default Intial
