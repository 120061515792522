import { createAsyncThunk } from '@reduxjs/toolkit';
import { post } from '../../utils/axios';
import { toast, Bounce } from 'react-toastify';

export const handleAddedPersonalInfoAction = createAsyncThunk(
  'initialUserInfo/handleAddedPersonalInfo',
  async ({ data, callback, popup }, { dispatch }) => {
    try {
      const response = await post(`/user`, data, {});
      console.log({ response });
      if (popup != true) (
        toast.success(`Benutzerdaten erfolgreich hinzugefügt`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        })
      )
      // Pass the response to the callback
      callback(response.data);

      console.log("data in form", response);
      return response.data;
    } catch (err) {
      console.log(err.response?.data?.message);
      toast.error(err.response?.data?.message || "An error occurred", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });

      // Return or throw an error if needed
      throw err;
    }
  }
);
