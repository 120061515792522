import React from 'react'

const Spiner = () => {
    return (
        <div className="absolute inset-0 flex items-center justify-center">
            <svg
                className="w-5 h-5 animate-spin text-[#2C2954]"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
                <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                ></circle>
                <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v4a4 4 0 000 8v4a8 8 0 01-8-8z"
                ></path>
            </svg>
        </div>
    )
}

export default Spiner
