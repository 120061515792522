import React from 'react';
import { firstSevenQuestions } from '../../common/constant/data';

const LevelRadioSelection = ({ formik }) => {
  return (
    <div className="grid grid-cols-2 gap-5 items-start">
      {firstSevenQuestions.map((question, index) => (
        <div key={index} className="w-full">
          <p className="text-[13px] font-medium text-white text-left mb-3">
            {index + 1}. {question.question}
          </p>
          <div className="flex gap-3">
            {question.option.map((option, i) => (
              <label
                key={`${index}-${i}`}
                className="flex items-center space-x-2 cursor-pointer pl-2"
              >
                <input
                  type="radio"
                  name={question.keyName}
                  value={option.value}
                  checked={formik.values[question.keyName] === option.value}
                  onChange={formik.handleChange}
                  className="w-4 h-4 custom-radio"
                />
                <span className="text-[12px] font-normal text-[#F9DCD4]">
                  {option.label}
                </span>
              </label>
            ))}
          </div>
          {formik.touched[question.keyName] && formik.errors[question.keyName] && (
            <p className="text-[11px] text-left text-red-500 mt-1">
              {formik.errors[question.keyName]}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default LevelRadioSelection;
